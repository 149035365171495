import React, { PureComponent } from "react";
import classes from "./RecommendedCard.module.css";
import LocationOn from "@material-ui/icons/LocationOn";
import CropDin from "@material-ui/icons/CropDin";
import Brightness1 from "@material-ui/icons/Brightness1";
import { Card, CardMedia, CardContent, Icon } from "@material-ui/core";

class RecommendedCard extends PureComponent {
  state = {
    itemsInCart: 0
  };

  onAddToCart = () => {
    const { item, onAdd } = this.props;
    console.log("Adding item to cart", item);
    const ppId = item.store.pickupPoints.edges[0].node.id;
    const pickupPoint = { id: ppId, store: item.store };
    onAdd && onAdd({ node: { ...item, stock: item } }, pickupPoint);
  };

  render() {
    const { item } = this.props;
    console.log("item", item);
    return (
      <Card onClick={this.onAddToCart} className={classes.main_div}>
        <div className={classes.store_logo_div}>
          <img alt="" src={item.store.image} />
        </div>
        <div className={classes.item_contains_div}>
          <CropDin
            style={{
              position: "relative",
              fontSize: "24",
              color:
                item.sku.product.contains === "NONVEG"
                  ? "red"
                  : item.sku.product.contains === "VEG"
                  ? "green"
                  : "#FFEB3B"
            }}
          />
          <Brightness1
            style={{
              position: "absolute",
              top: 6,
              left: 6,
              fontSize: 12,
              color:
                item.sku.product.contains === "NONVEG"
                  ? "red"
                  : item.sku.product.contains === "VEG"
                  ? "green"
                  : "#FFEB3B "
            }}
          />
        </div>
        <CardMedia
          component={"img"}
          className={classes.card_image}
          src={item.sku.image}
        />
        <CardContent className={classes.content_div}>
          <div className={classes.pp_div}>
            <div className={classes.location_div}>
              <Icon style={{ color: "grey", fontSize: 16 }}>
                <LocationOn style={{ fontSize: 16 }} />
              </Icon>
              <p>{item.store.pickupPoints.edges[0].node.name}</p>
            </div>
            <div className={classes.price_div}>
              <p>{`₹ ${item.salePrice}`}</p>
            </div>
          </div>
          <div className={classes.item_detail_div}>
            <div className={classes.item_name_div}>
              <p>
                {`${item.sku.name} \n ${
                  item.sku.quantity ? item.sku.quantity.toLowerCase() : ""
                }`}
              </p>
              <p>
                {item.stockSla && Math.round(item.stockSla / 60) > 0
                  ? `${Math.round(item.stockSla / 60)} Min`
                  : ""}
              </p>
            </div>
            <div className={classes.item_desc}>
              <p>{item.sku.description || ""}</p>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default RecommendedCard;

import React, { Component } from "react";
import Lottie from "react-lottie";
import TickAnimation from "../../animationData/success.json";
import CheckoutRender from "../../components/CheckoutRender/CheckoutRender";
import "./TokenScreen.css";
import Typography from "@material-ui/core/Typography";
import CartManager from "../../singletons/CartManager/index.js";
import { paymentStatus } from "../../utils/config.js";
import PaymentErrorScreen from "../../components/PaymentErrorHandling/PaymentErrorHandling.js";
import FeedBack from "../../components/FeedBack/FeedBack.js";
const restartButton = require("../../assets/restart.png");

class TokenScreen extends Component {
  _isMounted = false;
  constructor() {
    super();
    const cartManager = new CartManager();
    this.state = {
      SGST: 0,
      CGST: 0,
      checkout: cartManager.getCheckOut(),
      mode: cartManager.getUserDetail().mode
    };
  }
  componentDidMount() {
    this._isMounted = true;
    const cartManager = new CartManager();
    let comments = cartManager.getCheckoutComment();
    this.setState({
      checkoutComment: comments
    });
    this.timer = setTimeout(() => {
      this.timer = null;
      clearTimeout(this.timer);
      if (this._isMounted) {
        cartManager.clearAllCarts();
        console.log("This props of location", this.props.location);
        this.props.history.push("/home");
      }
    }, 120000);
  }
  _retryPayment = () => {
    this.timer = null;
    clearTimeout(this.timer);
    this.props.history.push("/paymentScreen");
  };

  _onrestartClick = () => {
    console.log("timer", this.timer);
    let that = this;
    that.timer = null;
    clearTimeout(that.timer);
    const cartManager = new CartManager();
    cartManager.clearAllCarts();
    that.props.history.push("/home");
  };

  componentWillUnmount() {
    this._isMounted = false;
    this.timer = null;
    clearTimeout(this.timer);
  }

  render() {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: TickAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const { checkout, mode, checkoutComment } = this.state;
    const { data } = this.props.location;
    console.log("Token - Orders", this.props.location);
    if (data.payment.status !== paymentStatus.failed) {
      return (
        <div className="TokenScreenMainContainer">
          <div className="TokenScreenTickContainer">
            <Lottie options={defaultOptions} height={100} width={100} />
            <Typography
              style={{
                color: "#7ED321",
                fontSize: "32px",
                fontFamily: "Open Sans"
              }}
            >
              Payment Successful
            </Typography>
          </div>
          <FeedBack checkout={checkout} />
          <div className={"checkout_container_token_screen"}>
            <CheckoutRender
              checkout={checkout}
              deliveryLocation={checkoutComment}
              orderMode={mode}
              isTokenScreen={true}
            />
          </div>
          <div
            style={{
              position: "fixed",
              bottom: 0,
              padding: 32,
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div onClick={this._onrestartClick} className="RestartButton">
              <img
                src={restartButton}
                alt=""
                width={"120px"}
                style={{ objectFit: "contain" }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <PaymentErrorScreen
        onRestartPayment={this._retryPayment}
        onRestartOrder={this._onrestartClick}
      />
    );
  }

  getCGST = data => {
    let CGST = 0;
    console.log("getCGST", data);
    for (let i = 0; i < data.orders.length; i++) {
      const extras = JSON.parse(data.orders[i].cart.extra);
      for (let j = 0; j < extras.charge.length; j++) {
        if (extras.charge[j].type === "TAXES") {
          if (extras.charge[j].label.includes("CGST")) {
            CGST = CGST + extras.charge[j].amount;
          }
        }
      }
    }
    return CGST.toFixed(2);
  };
}
export default TokenScreen;

import React, { PureComponent } from "react";
import LocationOn from "@material-ui/icons/LocationOn";
import "./StoreCard.css";
import { Typography, Icon } from "@material-ui/core";

class StoreCard extends PureComponent {
  render() {
    const { store } = this.props;
    console.log("Store", store)
    return (
      <>
        <div style={{ height: "100%", width: "100%", position: "relative", display:"flex", flexDirection:"column", justifyContent:"center", textAlign:"center" }}>
        <img
          src={store.store.image}
          alt={store.store.name}
          height={"100%"}
          width={"100%"}
          style={{
            objectFit: "cover",
            zIndex: 4
          }}
        />
        
      </div>
      {store.name &&       <div style={{display:"flex", justifyContent:"center", marginTop:"8px"}}>
      <Icon style={{ color: "grey", fontSize: 16 }}>
                      <LocationOn style={{ fontSize: 16 }} />
                    </Icon>
      <Typography  style={{color:"black", textAlign:'center', letterSpacing:"1px", fontFamily:"Open Sans"}}>{store.name}</Typography>
      </div>}
      </>
    );
  }
}

export default StoreCard;

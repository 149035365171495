import { gql } from "apollo-boost";
import { ORDER_MODES } from "../utils/config";

/**
 *
 * @param {String} mode - order mode for which to fetch the stores
 */
export const GET_STORES_FOR_REGION = (mode = null) => {
  if (mode === ORDER_MODES.dineIn) {
    return gql`
      query pickupPointsForRegion($regionId: ID!) {
        pickupPointsForRegion(forRegion: $regionId, dinein: true) {
          pickuppoint {
            id
            name
            store {
              id
              maxCartItems
              isOpen
              image
              cardImage
              availabilityText
              name
              displayOrder
              delivery
              takeaway
              dinein
              address
              maxDepth
              merchant {
                image
              }
            }
          }
        }
      }
    `;
  } else if (mode === ORDER_MODES.delivery) {
    return gql`
      query pickupPointsForRegion($regionId: ID!) {
        pickupPointsForRegion(forRegion: $regionId, delivery: true) {
          pickuppoint {
            id
            name
            store {
              id
              maxCartItems
              isOpen
              image
              cardImage
              availabilityText
              name
              displayOrder
              delivery
              takeaway
              dinein
              address
              maxDepth
              merchant {
                image
              }
            }
          }
        }
      }
    `;
  } else {
    return gql`
      query pickupPointsForRegion($regionId: ID!) {
        pickupPointsForRegion(forRegion: $regionId, takeaway: true) {
          pickuppoint {
            id
            name
            store {
              id
              maxCartItems
              isOpen
              image
              cardImage
              availabilityText
              name
              displayOrder
              delivery
              takeaway
              dinein
              address
              maxDepth
              merchant {
                image
              }
            }
          }
        }
      }
    `;
  }
};

export const GET_PRODUCT_SKU_FOR_CATEGORY = (mode) => {
  if (mode === ORDER_MODES.dineIn) {
    return gql`
      query productCategorySkuStock($storeId: ID) {
        productCategorySkuStock(
          forStore: $storeId
          inStock: true
          dinein: true
        ) {
          edges {
            node {
              skuName
              skuId
              store
              productName
              productImage
              stockSla
              skuImage
              categoryImage
              inStock
              stockSalePrice
              quantity
              category
              categoryDisplayOrder
              productDescription
              productDisplayOrder
              categoryName
              categoryImage
              categoryStatus
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
            }
          }
        }
      }
    `;
  } else if (mode === "DELIVERY") {
    return gql`
      query productCategorySkuStock($storeId: ID) {
        productCategorySkuStock(
          forStore: $storeId
          inStock: true
          delivery: true
        ) {
          edges {
            node {
              skuName
              skuId
              productName
              productImage
              stockSla
              store
              skuImage
              categoryImage
              inStock
              stockSalePrice
              quantity
              category
              categoryDisplayOrder
              categoryName
              categoryImage
              categoryStatus
              productDescription
              productDisplayOrder
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
            }
          }
        }
      }
    `;
  } else {
    return gql`
      query productCategorySkuStock($storeId: ID) {
        productCategorySkuStock(
          forStore: $storeId
          inStock: true
          takeaway: true
        ) {
          edges {
            node {
              skuName
              skuId
              productName
              productImage
              stockSla
              store
              skuImage
              categoryImage
              inStock
              stockSalePrice
              quantity
              category
              categoryDisplayOrder
              categoryName
              categoryImage
              categoryStatus
              productDescription
              productDisplayOrder
              contains
              product
              stock
              takeaway
              dinein
              delivery
              isCustomizable
            }
          }
        }
      }
    `;
  }
};

export const GET_CUSTOMIZATIONS_FOR_STOCK = () => {
  return gql`
    query stock($stockId: ID!) {
      stock(id: $stockId) {
        referenceName
        trending
        id
        inStock
        stockSla
        stockText
        salePrice
        customizations(level: 0, sortBy: "display_order") {
          edges {
            node {
              description
              id
              defaultSelected
              name
              maxSelectable
              minSelectable
              displayOrder
              salePrice
              depth
              children {
                edges {
                  node {
                    id
                    defaultSelected
                    maxSelectable
                    salePrice
                    depth
                    name
                    contains
                    minSelectable
                  }
                }
              }
              contains
            }
          }
        }
        sku {
          id
          name
          image
          product {
            id
            contains
            name
          }
          quantity
          maximumRetailPrice
          maximumRetailPriceCurrency
          description
        }
        store {
          id
          name
          pickupPoints {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  `;
};

export const GET_PRODUCT_FOR_ID = () => {
  return gql`
    query product($productId: ID!, $storeId: ID!) {
      product(id: $productId) {
        name
        id
        image
        description
        contains
        category {
          id
          name
        }
        skus(sortBy: "maximum_retail_price") {
          edges {
            node {
              name
              groupLabel
              defaultSelected
              quantity
              description
              id
              stocks(inStock: true, store: $storeId) {
                edges {
                  node {
                    referenceName
                    trending
                    id
                    store {
                      id
                      name
                      pickupPoints {
                        edges {
                          node {
                            id
                            name
                          }
                        }
                      }
                    }
                    inStock
                    stockSla
                    sku {
                      name
                      id
                      image
                      quantity
                      description
                    }
                    stockText
                    salePrice
                    customizations(level: 0, sortBy: "display_order") {
                      edges {
                        node {
                          description
                          id
                          defaultSelected
                          name
                          maxSelectable
                          minSelectable
                          displayOrder
                          salePrice
                          depth
                          children {
                            edges {
                              node {
                                id
                                defaultSelected
                                maxSelectable
                                salePrice
                                depth
                                name
                                contains
                                minSelectable
                              }
                            }
                          }
                          contains
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;
};

export const GET_POPULAR_FOOD = (mode) => {
  if (mode === "DINEIN") {
    return gql`
      query stocks {
        stocks(first: 24, sortBy: "trending", inStock: true, dinein: true) {
          edges {
            node {
              referenceName
              trending
              stockSla
              referenceCategory
              categoryDisplayOrder
              id
              salePrice
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains
                  }
                }
              }
              sku {
                id
                name
                image
                quantity
                product {
                  id
                  contains
                  name
                  image
                }
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
              }

              store {
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                      store {
                        maxCartItems
                        maxDepth
                      }
                      sla {
                        id
                        preparationTime
                      }
                    }
                  }
                }
                merchant {
                  image
                }
              }
            }
          }
        }
      }
    `;
  } else if (mode === "TAKEAWAY") {
    return gql`
      query stocks {
        stocks(first: 24, sortBy: "trending", inStock: true, takeaway: true) {
          edges {
            node {
              referenceName
              trending
              stockSla
              referenceCategory
              categoryDisplayOrder
              id
              salePrice
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains
                  }
                }
              }
              sku {
                id
                name
                image
                quantity
                product {
                  id
                  contains
                  name
                  image
                }
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
              }

              store {
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                      store {
                        maxCartItems
                        maxDepth
                      }
                      sla {
                        id
                        preparationTime
                      }
                    }
                  }
                }
                merchant {
                  image
                }
              }
            }
          }
        }
      }
    `;
  } else {
    return gql`
      query stocks {
        stocks(first: 24, sortBy: "trending", inStock: true, delivery: true) {
          edges {
            node {
              referenceName
              trending
              stockSla
              referenceCategory
              categoryDisplayOrder
              id
              salePrice
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains
                  }
                }
              }
              sku {
                id
                name
                image
                quantity
                product {
                  id
                  contains
                  name
                  image
                }
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
              }

              store {
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                      store {
                        maxCartItems
                        maxDepth
                      }
                      sla {
                        id
                        preparationTime
                      }
                    }
                  }
                }
                merchant {
                  image
                }
              }
            }
          }
        }
      }
    `;
  }
};

export const GET_REGION_CONFIG = () => {
  return gql`
    query region($regionId: ID!) {
      region(id: $regionId) {
        id
        name
        delivery
        takeaway
        dinein
        kioskOfferImage
        deliveryCaption
        city
        kioskShouldShowHeader
        hideKioskScreenSaver
      }
    }
  `;
};

export const GET_RECOMMENDATIONS_FOR_CHECKOUT = () => {
  return gql`
    query checkoutRecommendation($userId: ID) {
      checkoutRecommendation(userId: $userId) {
        id
        stock {
          id
          inStock
          referenceName
          trending
          stockSla
          inStock
          referenceCategory
          categoryDisplayOrder
          delivery
          takeaway
          dinein
          salePrice
          customizations(level: 0) {
            edges {
              node {
                description
                id
                defaultSelected
                name
                maxSelectable
                minSelectable
                salePrice
                depth
                children {
                  edges {
                    node {
                      id
                      defaultSelected
                      maxSelectable
                      salePrice
                      depth
                      name
                      contains
                      minSelectable
                    }
                  }
                }
                contains
              }
            }
          }
          sku {
            id
            name
            image
            quantity
            maximumRetailPrice
            maximumRetailPriceCurrency
            description
            noBgUrl
            fontColor
            selectedColor
            mask
            secondaryUrl
            product {
              id
              contains
            }
          }
          store {
            id
            name
            image
            isOpen
            pickupPoints {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  `;
};

import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";
import "./BottomPanel.css";
import Modal from "@material-ui/core/Modal";
import CartManager from "../../singletons/CartManager";
import CartDetailCard from "../CartDetailCard/CartDetailCard";
import { REGION_ID_LOCAL } from "../../utils/config";
import client from "../../api/client";
import { GET_STORES_FOR_REGION } from "../../api/queries";
import LiveCart from "../LiveCart/LiveCart";
import StoreSelector from "../StoreSelector/StoreSelector";

const trendingCard = {
  store: {
    name: "Trending",
    image: require("../../assets/trending-icon.png"),
    isOpen: true
  },
  id: ""
};

class BottomPanel extends PureComponent {
  constructor() {
    super();
    this.state = {
      storesAround: [],
      checkout: null,
      currentActiveStore: "",
      showModal: false,
      currentMode: null,
      regionId: localStorage.getItem(REGION_ID_LOCAL)
    };
  }

  _goToPayMentScreen = () => {
    this.props.showStores && this.props.history.replace("/home");
    this.props.showStores && this.props.goToPaymentScreen();
  };

  onRestartClick = () => {
    const cartManager = new CartManager();
    cartManager.setCheckAPI(false);
    cartManager.clearAllCarts();
    if (this.props.paymentCancel) {
      this.props.paymentCancel();
    }
    // console.log('onRestartClick-called',cartManager.getCheckAPI())

    (this.props.location.pathname === "/userDetailScreen" ||
      this.props.location.pathname === "/tokenScreen" ||
      this.props.location.pathname === "/orderReviewScreen" ||
      this.props.location.pathname === "/paymentScreen") &&
      this.props.history.push("/home");
    if (this.props.location.pathname.includes("/home")) {
      this._OnStoreCardClick(trendingCard);
      this.props.onRestart();
    }
  };

  componentDidMount() {
    if (this.props.location.pathname.includes("/home")) {
      const cartManager = new CartManager();
      const userDetail = cartManager.getUserDetail();
      const mode = userDetail.mode;
      client
        .query({
          query: GET_STORES_FOR_REGION(mode),
          variables: {
            regionId: this.state.regionId
          },
          fetchPolicy: "network-only"
        })
        .then(res => {
          let data =
            res.data.pickupPointsForRegion.length > 0
              ? res.data.pickupPointsForRegion
                  .filter(store => {
                    return (
                      store.pickuppoint &&
                      store.pickuppoint.store &&
                      store.pickuppoint.store.isOpen
                    );
                  })
                  .sort(
                    (a, b) =>
                      b.pickuppoint.store.displayOrder -
                      a.pickuppoint.store.displayOrder
                  )
              : [];
          if (data.length < 1) {
            this.props.onEmptyStoreList();
          } else {
            data = data.map(item => {
              return {
                store: {
                  name: item.pickuppoint.store.name,
                  image: item.pickuppoint.store.image,
                  isOpen: item.pickuppoint.store.isOpen,
                  id: item.pickuppoint.store.id
                },
                id: item.pickuppoint.id,
                name: item.pickuppoint.name
              };
            });
            data.splice(2, 0, trendingCard);
            this.setState({ storesAround: data });
          }
        });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.currentMode !== prevProps.currentMode &&
      this.props.currentMode
    ) {
      const cartManager = new CartManager();
      const userDetail = cartManager.getUserDetail();
      const mode = userDetail.mode;
      client
        .query({
          query: GET_STORES_FOR_REGION(mode),
          variables: {
            regionId: this.state.regionId
          },
          fetchPolicy: "network-only"
        })
        .then(res => {
          let data =
            res.data.pickupPointsForRegion.length > 0
              ? res.data.pickupPointsForRegion
                  .filter(store => {
                    return (
                      store.pickuppoint &&
                      store.pickuppoint.store &&
                      store.pickuppoint.store.isOpen
                    );
                  })
                  .sort(
                    (a, b) =>
                      b.pickuppoint.store.displayOrder -
                      a.pickuppoint.store.displayOrder
                  )
              : [];
          if (data.length < 1) {
            this.props.onEmptyStoreList();
          } else {
            data = data.map(item => {
              return {
                store: {
                  name: item.pickuppoint.store.name,
                  image: item.pickuppoint.store.image,
                  isOpen: item.pickuppoint.store.isOpen,
                  id: item.pickuppoint.store.id
                },
                id: item.pickuppoint.id,
                name: item.pickuppoint.name
              };
            });
            data.splice(2, 0, trendingCard);
            this.setState({ storesAround: data });
          }
        });
    }
  }

  _OnStoreCardClick = store => {
    if (store.store.isOpen) {
      const id = store.id;
      this.setState({ currentActiveStore: store.id });
      this.props.history.push({
        pathname: `/home/${id}`,
        data: store
      });
    }
  };

  _closeCartDetailModal = () => {
    this.setState({ showModal: false });
  };

  _showCartInfoModal = () => {
    this.setState({ showModal: true });
  };

  onBackButtonClick = () => {
    const cartManager = new CartManager();
    cartManager.setCheckAPI(false);
    const user = JSON.parse(localStorage.getItem("gng-userProfile"));
    console.log("User", user);
    if (this.props.paymentCancel) {
      this.props.paymentCancel();
    }
    if (this.props.onPressBack) {
      this.props.onPressBack();
    }
    if (this.props.location.pathname === "/userDetailScreen") {
      console.log("hello");
      // this.props.onPressBack();
      this.props.history.push("/home");
    } else {
      this.props.history.goBack();
    }
    // console.log('onBackButtonClick-called',cartManager.getCheckAPI())
  };
  render() {
    console.log("Bottom Panel Props", this.props);
    const { checkout, location, showStores, itemsIncart } = this.props;
    const { storesAround, currentActiveStore } = this.state;
    return (
      <div className="BottomPanelMainDiv">
        {showStores && (
          <StoreSelector
            OnStoreCardClick={this._OnStoreCardClick}
            storesAround={storesAround}
            currentActiveStore={currentActiveStore}
          />
        )}
        <LiveCart
          location={location}
          checkout={checkout}
          showStores={showStores}
          itemsIncart={itemsIncart}
          onShowCartInfoModal={this._showCartInfoModal}
          onPayButtonClick={this._goToPayMentScreen}
          onRestartClick={this.onRestartClick}
          updateCheckOut={this.props.updateCheckOut}
          onBackButtonClick={this.onBackButtonClick}
        />
        <Modal disableAutoFocus open={this.state.showModal}>
          <div
            style={{
              position: "absolute",
              top: "20%",
              right: "10%",
              height: "60%",
              left: "10%",
              backgroundColor: "#FFFFFF"
            }}
          >
            <CartDetailCard
              onClose={this._closeCartDetailModal}
              Cart={checkout}
            />
          </div>
        </Modal>
      </div>
    );
  }
}

export default withRouter(BottomPanel);

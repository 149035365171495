import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Image } from "cloudinary-react";
import Card from "@material-ui/core/Card";
import Modal from "@material-ui/core/Modal";
// import LocationOn from "@material-ui/icons/LocationOn";
import CropDin from "@material-ui/icons/CropDin";
import Brightness1 from "@material-ui/icons/Brightness1";
import CartManager from "../../singletons/CartManager";
import Customization from "../Customization/Customization";
// import Icon from "@material-ui/core/Icon";
import Button from "@material-ui/core/Button";
import client from "../../api/client";
import { GET_PRODUCT_FOR_ID } from "../../api/queries";
import { Transformation } from "cloudinary-core";
const widthscreen = window.screen.width;
const styles = theme => ({
  card: {
    width: widthscreen > 1000 ? 240 : 220,
    position: "relative",
    boxShadow: "0 2px 20px 0 rgba(0,0,0,0.6)",
    borderRadius: "10px",
    border: "1px solid rgba(0,0,0,0.2);"
  },
  cardPressed: {
    boxShadow: "none"
  },
  media: {
    objectFit: "contain",
    width: "100%",
    marginBottom: 0,
    position: "relative",
    backgroundColor: "white"
  },
  actions: {
    background: "rgba(255,255,255,0.4)",
    padding: "4px"
  },
  itemPrice: {
    color: "#333333",
    fontSize: 18,
    fontFamily: "Segoe UI",
    fontWeight: "600",
    flex: 3,
    textAlign: "right"
  },
  productDetailDiv: {
    padding: "4px",
    backgroundColor: "#D8D8D8;",
    position: "absolute",
    bottom: "0",
    left: "0",
    flex: 1,
    width: "100%",
    boxSizing: "border-box",
    minHeight: "20px",
    opacity: 0.8,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }
});

class ProductCard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      cart: null,
      showCustomization: false,
      showSkuPicker: false,
      selectedStock: null,
      customizationConfiguration: null,
      showCard: true,
      item: this.props.item,
      stocks: null,
      customizationData: null,
      allowClick: false
    };
  }

  componentDidMount() {
    const { item, store } = this.props;
    if (this.props.item.isCustomizable) {
      client
        .query({
          query: GET_PRODUCT_FOR_ID(),
          variables: { productId: item.id, storeId: store.store.id }
        })
        .then(res => {
          let stocks = {};
          console.log("Product data ----->", res.data.product);
          const product = res.data.product;
          product.skus.edges.forEach(sku => {
            sku.node.stocks.edges.forEach(stock => {
              const stockId = stock.node.id;
              stocks[stockId] = stock.node;
            });
          });
          this.setState({
            stocks: stocks,
            allowClick: true
          });
        });
    } else {
      this.setState({
        allowClick: true
      });
    }
  }

  _filterProductData = () => {};

  generateInitialCustomizationConfiguration = stock => {
    let customizationConfiguration = {};
    if (stock.customizations.edges.length > 0) {
      stock.customizations.edges.forEach(customization => {
        customizationConfiguration[
          customization.node.id
        ] = customization.node.children.edges
          .filter(cust => {
            if (cust.node.defaultSelected === 1) {
              return true;
            }
            return false;
          })
          .map(defaultValue => defaultValue.node.id);
      });
    }
    return customizationConfiguration;
  };

  _addCustomizedProduct = (customizations, stock, e = null) => {
    e && e.stopPropagation();
    let cartManager = new CartManager();
    // const product = stock.sku.product;
    const pickupPoint = this.props.store;
    let customizationValues = [];
    Object.keys(customizations).forEach(customizationId => {
      customizationValues = customizationValues.concat(
        customizations[customizationId]
      );
    });
    cartManager.addStockToCartForPickupPoint(
      stock,
      pickupPoint,
      99,
      customizationValues
    );
    this._closeCustomization();
    const { left, top } = this.image.getBoundingClientRect();
    this.props.updateCheckOut(this.props.item.image, {
      x: left,
      y: top
    });
  };

  _handleProductClick = () => {
    if (this.state.allowClick) {
      if (this.props.item.stocks.length > 1) {
        this.setState({
          showSkuPicker: true
        });
      } else {
        const stock = this.props.item.stocks[0];
        this._addSelectedStockToCart(stock);
      }
    }
  };
  _closeSkuPicker = e => {
    e && e.stopPropagation();
    this.setState({
      showSkuPicker: false,
      expanded: false
    });
  };
  _addStockToCart = async () => {
    console.log("The card is clicked");
    let cartManager = new CartManager();
    const stock = this.props.item.stocks[0].stock;
    const pickupPoint = this.props.store;
    if (
      cartManager.getTotalNumberOfItemsInCheckoutForPickupPoint(pickupPoint) >=
      99
    ) {
      alert(
        "Max items reached",
        "only" + 99 + " item(s) can be added to your cart for this store."
      );
    } else {
      if (stock.customizations.edges.length > 0) {
        this.setState({
          showCustomization: true
        });
      } else {
        cartManager.addStockToCartForPickupPoint(stock, pickupPoint, 99);
        const { left, top } = this.image.getBoundingClientRect();
        this.props.updateCheckOut(this.props.item.image, {
          x: left,
          y: top
        });
      }
    }
  };

  _addSelectedStockToCart = async (stock, e) => {
    this._closeSkuPicker(e);
    let cartManager = new CartManager();
    const pickupPoint = this.props.store;
    if (
      cartManager.getTotalNumberOfItemsInCheckoutForPickupPoint(pickupPoint) >=
      99
    ) {
      alert(
        "Max items reached",
        "only" + 99 + " item(s) can be added to your cart for this store."
      );
    } else {
      if (this.props.item.isCustomizable) {
        const { stocks } = this.state;
        const stockData = stocks[stock.id];
        console.log("Response for stock", stockData);
        let customizationConfiguration = this.generateInitialCustomizationConfiguration(
          stockData
        );
        const selectables = stockData.customizations.edges.find(
          ele =>
            !(
              ele.node.minSelectable === ele.node.maxSelectable &&
              ele.node.children.edges.length === ele.node.maxSelectable
            )
        );
        if (!selectables || selectables.length < 1) {
          this._addCustomizedProduct(customizationConfiguration, stockData);
        } else {
          this.setState({
            expanded: true,
            selectedStock: stockData,
            customizationConfiguration
          });
        }
      } else {
        cartManager.addStockToCartForPickupPoint(stock, pickupPoint, 99);
        const { left, top } = this.image.getBoundingClientRect();
        this.props.updateCheckOut(this.props.item.image, {
          x: left,
          y: top
        });
      }
    }
  };

  _closeCustomization = (e = null) => {
    e && e.stopPropagation();
    console.log("Closing the modal");
    this.setState({ expanded: false, showSkuPicker: false }, () => {
      console.log("Closing the modal", this.state.showCustomization);
    });
  };

  render() {
    const { classes } = this.props;
    const { item } = this.state;
    console.log("Product Item =======>", item);
    const publicId = item.image.replace(
      "https://res.cloudinary.com/grabbngo/image/upload/",
      ""
    );
    return (
      <div ref={img => (this.image = img)}>
        <Button
          onClick={this._handleProductClick}
          style={{ textAlign: "left" }}
        >
          <Card className={classes.card} raised>
            <div style={{ position: "absolute", top: 4, left: 4, zIndex: 4 }}>
              <div style={{ position: "relative" }}>
                <CropDin
                  style={{
                    position: "relative",
                    fontSize: "24",
                    color:
                      item.contains === "NONVEG"
                        ? "red"
                        : item.contains === "VEG"
                        ? "green"
                        : "#FFEB3B"
                  }}
                />
                <Brightness1
                  style={{
                    position: "absolute",
                    top: 6,
                    left: 6,
                    fontSize: 12,
                    color:
                      item.contains === "NONVEG"
                        ? "red"
                        : item.contains === "VEG"
                        ? "green"
                        : "#FFEB3B "
                  }}
                />
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <Image
                cloudName="grabbngo"
                width={widthscreen > 1000 ? 240 : 220}
                quality={"auto"}
                crop={"fill"}
                fetchFormat={"auto"}
                dpr={"auto"}
                publicId={publicId}
                className={classes.media}
                src={item.image}
                alt={`${item.name}`}
              >
                <Transformation effect="sharpen" flags={"progressive"} />
              </Image>
              <div className={classes.productDetailDiv}>
                <p className={classes.itemPrice}>{`₹ ${
                  item.stocks[0].salePrice
                } ${this.props.item.stocks.length > 1 ? "+" : ""}`}</p>
              </div>
            </div>
            <div className={classes.actions}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <p
                  style={{
                    fontSize: 18,
                    fontWeight: "600",
                    fontFamily: "Open Sans",
                    color: "#333333"
                  }}
                >
                  {item.name}
                </p>
                <p
                  style={{
                    fontSize: 12,
                    fontWeight: "400",
                    fontFamily: "Open Sans",
                    color: "#333333"
                  }}
                >
                  {Math.round(item.stockSla / 60) > 0
                    ? `${Math.round(item.stockSla / 60)} Min`
                    : ""}
                </p>
              </div>
              {item.description && (
                <p style={{ fontSize: 14, letterSpacing: 0 }}>
                  {item.description}
                </p>
              )}
            </div>

            {this.state.expanded && (
              <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.state.expanded}
                onClose={this._closeCustomization}
                disableAutoFocus
              >
                <Customization
                  item={this.state.selectedStock}
                  onAddToCart={this._addCustomizedProduct}
                  handleClose={this._closeCustomization}
                  open={this.state.expanded}
                  customizationConfiguration={
                    this.state.customizationConfiguration
                  }
                  // sandy showSkuPicker disable model for sku customization
                  showSkuPicker={this.setState({ showSkuPicker: false })}
                />
              </Modal>
            )}
            {this.state.showSkuPicker && (
              <Modal
                open={this.state.showSkuPicker}
                onClose={this._closeSkuPicker}
                disableAutoFocus
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: "350px",
                    backgroundColor: "transparent",
                    width: "100%",
                    paddingLeft: "10%",
                    textAlign: "left",
                    paddingRight: "10%",
                    minHeight: "400px",
                    boxSizing: "border-box",
                    padding: 16
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white"
                    }}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      width={"500px"}
                      style={{
                        objectFit: "contain",
                        marginTop: 16,
                        // add border radius
                        border: 1,
                        borderRadius: 12
                      }}
                    />
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <p
                        style={{
                          color: "#34495E",
                          fontSize: 40,
                          fontWeight: "600",
                          fontFamily: "Open Sans"
                        }}
                      >
                        {item.name}
                      </p>
                    </div>
                  </div>
                  <div style={{ width: "100%", backgroundColor: "white" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#FFFFFF",
                        flexDirection: "row"
                      }}
                    >
                      {this.props.item.stocks &&
                        this.props.item.stocks.map(stock => (
                          <div
                            key={stock.id}
                            style={{
                              width: "240px",
                              textAlign: "center",
                              marginRight: "16px",
                              display: "flex",
                              height: "240px",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "space-evenly"
                            }}
                            onClick={e =>
                              this._addSelectedStockToCart(stock, e)
                            }
                          >
                            <div
                              style={{
                                height: widthscreen > 1000 ? "240px" : "220px",
                                width: "100%",
                                borderRadius: 4,
                                position: "relative",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                              }}
                            >
                              <img
                                src={stock.image}
                                style={{
                                  objectFit: "contain",
                                  position: "absolute",
                                  bottom: 0,
                                  // add border radius
                                  border: 1,
                                  borderRadius: 12,
                                  maxHeight: 240,
                                  maxWidth: 240
                                }}
                                alt={stock.quantity}
                              />
                            </div>
                            <p
                              style={{
                                color: "#34495E",
                                fontSize: 16,
                                fontWeight: "600"
                              }}
                            >
                              {stock.quantity}
                            </p>
                            <p
                              style={{
                                color: "#000000",
                                fontSize: 20,
                                fontWeight: "600"
                              }}
                            >{`₹ ${stock.salePrice}`}</p>
                          </div>
                        ))}
                    </div>
                    <Button
                      variant="contained"
                      onClick={this._closeSkuPicker}
                      style={{
                        width: "14%",
                        padding: 4,
                        color: "white",
                        backgroundColor: "red",
                        marginLeft: "43%",
                        marginRight: "43%",
                        marginTop: 16,
                        marginBottom: 16
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </Modal>
            )}
          </Card>
        </Button>
      </div>
    );
  }
}

ProductCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ProductCard);

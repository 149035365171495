import React from "react";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import "./QunatityPicker.css";

const QunatityPicker = ({
  quantityButtonColor = "rgba(0,0,0,0.7)",
  quantityTextBgColor = "#FFFFFF",
  onAdd = () => {},
  onRemove = () => {},
  item,
  store
}) => {
  const _onRemove = () => {
    console.log("Removing item", item, store);
    onRemove(item, store);
  };
  const _onAdd = () => {
    onAdd(item, store);
  };
  return (
    <div className="QunatityPickerMainDiv">
      <div
        className="QunatityPickerButtonLeftDiv"
        onClick={_onRemove}
        style={{ backgroundColor: quantityButtonColor }}
      >
        <RemoveIcon style={{ color: "white" }} />
      </div>
      <div style={{ backgroundColor: quantityTextBgColor }}>
        {item.node.quantity}
      </div>
      <div
        onClick={_onAdd}
        className="QunatityPickerButtonRightDiv"
        style={{ backgroundColor: quantityButtonColor }}
      >
        <AddIcon style={{ color: "white" }} />
      </div>
    </div>
  );
};

export default QunatityPicker;

import React, { PureComponent } from "react";
// import Keyboard from "react-simple-keyboard";
import swal from "sweetalert";
import "react-simple-keyboard/build/css/index.css";
import "./TextInput.css";
import { Modal } from "@material-ui/core";

class TextInput extends PureComponent {
  state = {
    layoutName: "default",
    input: ""
  };

  keyboard = React.createRef();

  onChange = input => {
    this.setState({
      input: input
    });
    console.log("Input changed", input);
  };

  onKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
    if (button === "{enter}") {
      const { input } = this.state;
      if (input.length < 1) {
        swal("", "Please enter a valid boarding gate number.", {
          icon: "warning"
        });
      } else {
        swal({
          text: `Your food will be delivered at Boarding Gate ${input}. \n Are you Sure?`,
          icon: "info",
          buttons: ["No", "Yes"]
        }).then(res => {
          console.log("value for the boarding gate confirmation", res);
          if (res === true) {
            this.props.onSubmit && this.props.onSubmit(input);
          }
        });
      }
    }
    if (button === "{clear}") {
      this.setState(
        {
          input: ""
        },
        () => {
          //   this.keyboard.setInput("");
        }
      );
    }
  };

  handleShift = () => {
    let layoutName = this.state.layoutName;

    this.setState({
      layoutName: layoutName === "default" ? "shift" : "default"
    });
  };

  onChangeInput = event => {
    let input = event.target.value;
    this.setState(
      {
        input: input
      },
      () => {
        this.keyboard.setInput(input);
      }
    );
  };

  handleGateSelect = value => {
    const gateNumber = value.item;
    this.setState({
      input: gateNumber
    });
    swal({
      text: `Your food will be delivered at Boarding Gate ${gateNumber}. \n Are you Sure?`,
      icon: "info",
      buttons: ["No", "Yes"]
    }).then(res => {
      console.log("value for the boarding gate confirmation", res);
      if (res === true) {
        this.props.onSubmit && this.props.onSubmit(gateNumber);
      }
    });
  };

  getGateList = () => {
    const gates = [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30"
    ];
    return (
      <ul className="gate-list">
        {gates.map(item => (
          <li key={item} onClick={() => this.handleGateSelect({ item })}>
            {item}
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const {
      // placeholder = "Select Your Boarding Gate Number",
      open = false
    } = this.props;
    return (
      <Modal onClose={() => this.props.onClose()} disableAutoFocus open={open}>
        <div className="bg_input_modal">
          <div className={"cust_text_container"}>
            <div>
              <h2 className="title_text">
                Get food delivered at your boarding gate
              </h2>
            </div>
            <div className="gate-sel-div">{this.getGateList()}</div>
            <div className="cust_text_container">
              {/* <input
                value={this.state.input}
                placeholder={placeholder}
                onChange={e => this.onChangeInput(e)}
                className={"textinput_default"}
                readOnly
              /> */}
              {/* <Keyboard
                keyboardRef={r => (this.keyboard = r)}
                onChange={input => this.onChange(input)}
                onKeyPress={button => this.onKeyPress(button)}
                theme={"hg-theme-default hg-layout-default myTheme"}
                layoutName={this.state.layoutName}
                //   disableCaretPositioning={true}
                layout={{
                  default: [
                    "1 2 3 4 5 6 7 8 9 0",
                    "Q W E R T Y U I O P",
                    "A S D F G H J K L",
                    "Z X C V B N M {bksp}",
                    "{space} {enter}"
                  ]
                }}
                display={{
                  "{bksp}": "⌫",
                  "{enter}": "Submit",
                  "{space}": "Space"
                }}
                baseClass={"keyboard1"}
                buttonTheme={"myTheme2b"}
              /> */}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default TextInput;

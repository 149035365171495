export const checkoutData = {
          "checkout":{
             "id":"Q2hlY2tvdXQ6NDI1OTAwZmQtMWQwYy00ZjAzLWIyNTYtMWI4MTdiMzUwN2Rh",
             "token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJjaGVja291dF9pZCI6IjQyNTkwMGZkLTFkMGMtNGYwMy1iMjU2LTFiODE3YjM1MDdkYSIsImV4cCI6MTU3OTY4ODI0Mn0.viPHNzf09yAHFjXriTpEoiGfoMa7iO2kJvjnMt8Q-8s",
             "status":"OPEN",
             "total":11062.1,
             "totalCurrency":"INR",
             "subtotal":10506.76,
             "subtotalCurrency":"INR",
             "extra":"{\"offer\": [], \"charge\": [{\"label\": \"Delivery Fee\", \"amount\": 30.0, \"type\": \"CHARGES\"}], \"default\": []}",
             "code":"",
             "codeText":null,
             "codeType":null,
             "carts":{
                "edges":[
                   {
                      "node":{
                         "id":"Q2FydDo4ZWYxM2FlZC1kOTYyLTQ0MjItOTIyNi0xNDYzNDQ1NDRmOTI=",
                         "total":334.95,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 7.98}, {\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 7.98}]}",
                         "subtotal":319.0,
                         "cartSla":1080,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront AV",
                            "id":"UGlja3VwUG9pbnQ6NGQzMzIxZDktY2JhYi00OThmLTk4YzUtMTIzNzNjYWE3Yzdj",
                            "comments":null,
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Alta Vita",
                               "maxCartItems":999,
                               "id":"U3RvcmU6OGYzZGJhYTMtY2ZjMC00YmFiLWFmMTEtODU0YzdlODBhMWVm"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MTljOTlkNDMtNTc4MC00MWRiLWJlNzUtNjczMGYwZmYyZGQz",
                                     "quantity":1,
                                     "unitPrice":319.0,
                                     "unitPriceCurrency":"INR",
                                     "total":319.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6NmUwYzhiMzItM2JjOC00OWY0LWI3ODItOGU4MjYxZDhhYTg5",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Cheesy Potato Wedges",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1566457649/BANGALORE%20AIRPORT/ALTA%20VITA%20NEW/CHEESY_POTATO_WEDGES.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDowMWM2MDc5Mi0yOTQ1LTQ3MjgtYmFmNC0zYTk5ZDU0ODdlYTE=",
                         "total":1575.15,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 37.5}, {\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 37.5}]}",
                         "subtotal":1500.14,
                         "cartSla":300,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront 6e",
                            "id":"UGlja3VwUG9pbnQ6ODk4NmE0YTEtMTg3ZC00MzU3LWJlNTQtODFjMzcxYjE2ODU4",
                            "comments":null,
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Tiffin Express 6E",
                               "maxCartItems":999,
                               "id":"U3RvcmU6NjVhZGNiZGEtN2VlNi00Y2Q4LTliNDItZmNjZmRiOTBiZWE0"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NGVhZTY4MGYtZWNjMS00N2YzLWFmY2ItNWY5NDg5MzZmOTgx",
                                     "quantity":1,
                                     "unitPrice":229.0,
                                     "unitPriceCurrency":"INR",
                                     "total":229.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6ZmI2MmZlMzQtOGNjOC00NmRjLWFjZDEtMTZkMTA5NmJkZWQ3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Bise Belle Bath",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1563175049/BANGALORE%20AIRPORT/IndianFood/bisi-bela-bath-.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06Njc1NzMzNWMtYjY3Zi00ZjA5LWE0YWUtOTFhYWNhMjU2NzUx",
                                     "quantity":1,
                                     "unitPrice":249.0,
                                     "unitPriceCurrency":"INR",
                                     "total":249.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6Yjg1ZTMxZGQtNTQ3ZS00MTY1LWFmOTItZDQ4OWJlZGFmYTk0",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Chow Chow Bath",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1565076381/BANGALORE%20AIRPORT/tiffin%20centre/CHOW_CHOW_BHATH.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NGIyZmM2MmEtOThjYy00MjgxLTgyOGItNjU1YzY2MGU5OTNl",
                                     "quantity":1,
                                     "unitPrice":57.14,
                                     "unitPriceCurrency":"INR",
                                     "total":57.14,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDY5NjU5MWItY2ZiMi00NzM4LWFmYTctMDU4NWViNDhhNDFl",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Frooti",
                                              "image":"https://res.cloudinary.com/grabbngo2/image/upload/v1575713555/removed_bg/bengaluru_airport/tghphzygclz0p0nk7fcd.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MDkzZjY4ZTUtYmQ1My00ZTU1LWIyYWItNDJkZGI1NWI3NDM5",
                                     "quantity":1,
                                     "unitPrice":199.0,
                                     "unitPriceCurrency":"INR",
                                     "total":199.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MzY5Zjg3YzQtMTE1Mi00M2I4LTk4NDEtNmNmMTBiZjFkODY3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Pongal With Chutney",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1562831185/BANGALORE%20AIRPORT/IndianFood/Pongal.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NzA5ZTZiZmItYzkxMC00ZDc5LTk5NjMtNzVmMTE4OGQxYjE4",
                                     "quantity":1,
                                     "unitPrice":199.0,
                                     "unitPriceCurrency":"INR",
                                     "total":199.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6Njg0ZTZjMDMtZDYyMy00MWFhLWEyMjctZjc5Y2U5ODIwNThl",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Upma With Chutney",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1573849671/removed_bg/mumbai_domestic_departure/qdighiqazvp5zm3ump77.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06YjMzODRlMTAtZjk5MC00ODU5LThiZDMtMDlhOThiZDg0NmZl",
                                     "quantity":3,
                                     "unitPrice":189.0,
                                     "unitPriceCurrency":"INR",
                                     "total":567.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6NGZmNDFiYmUtNjlmOC00MWFlLThjNmQtYmE3ZDgxMzI2YzMz",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Vada Sambhar",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1561365066/Mumbai_airport/Lite%20bite/g-n/Medu_Vada_top_View_shutterstock_1081857251.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDo1N2FiYWI1Mi1lMDI2LTRkMGMtODQzMy0wZjJhNjQ5YjU2NWY=",
                         "total":334.95,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 7.98}, {\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 7.98}]}",
                         "subtotal":319.0,
                         "cartSla":300,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront",
                            "id":"UGlja3VwUG9pbnQ6ODFhMTJmNTEtMGM1My00NjM4LWFkZDYtN2UwYWRkZWJhYzU1",
                            "comments":"Urban Food Market Bangalore",
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Urban Food Market (East)",
                               "maxCartItems":999,
                               "id":"U3RvcmU6ODkwYzI1MTUtMGM0OS00ZTNiLTlmZjktMjdkYmI4MzczNDRj"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NWUwOGY3MDgtN2QwOS00MzNjLTkyNDUtZmFiM2Y2NjQzYTI4",
                                     "quantity":1,
                                     "unitPrice":319.0,
                                     "unitPriceCurrency":"INR",
                                     "total":319.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDNmNWM2OTEtOWJjZS00MGUzLTg5ZjktM2U4ODVlNDU1OTll",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Dal Makhani Rice",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1543491611/AIRPORTIMAGES/foodstreet/Dal_Makhani.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDoyMmUzNWIzNy1jMGFiLTQ0MmEtYTQzNi1kZjYzOGM0YjI3MmM=",
                         "total":3043.95,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 72.48}, {\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 72.48}]}",
                         "subtotal":2899.0,
                         "cartSla":300,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront west",
                            "id":"UGlja3VwUG9pbnQ6OTY5YmRjNDMtZTQzYS00M2YxLWE0MTAtNTc0ZTBhODE0MWYy",
                            "comments":null,
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Urban Food Market (West)",
                               "maxCartItems":999,
                               "id":"U3RvcmU6ZWY0ZGVlNDctNTQ2Yi00ZWM3LThkZmYtNjJlZDVlYWUwOThh"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06YzIyOWVhYzctZGU2Zi00MjM3LWFiYjYtY2JlMDE1NTJmODVj",
                                     "quantity":3,
                                     "unitPrice":199.0,
                                     "unitPriceCurrency":"INR",
                                     "total":597.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDc3MTliZjAtMDc3YS00OWFjLTk2NWMtNTE1ODAxZTQwMzQ3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"5Petal Cheesy Potato Bun",
                                              "image":"https://res.cloudinary.com/grabbngo2/image/upload/v1575710443/removed_bg/bengaluru_airport/ezmgf5kbextuwrcmjpwy.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MTk2YWEyYTEtYzRjZS00YmE1LWE1ZjMtODJjZDFkN2IwOWU1",
                                     "quantity":1,
                                     "unitPrice":259.0,
                                     "unitPriceCurrency":"INR",
                                     "total":259.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MGE2Mzg4YTQtY2Y3Yi00MTY1LWJmNDYtMzllZDFiMWM0OGNi",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Bise Belle Bath",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1563175049/BANGALORE%20AIRPORT/IndianFood/bisi-bela-bath-.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06ZjQ0MGFiYTMtMjM3Ny00MTg5LTk2ZmUtNDFjMWY2MTQ4YWQ5",
                                     "quantity":2,
                                     "unitPrice":459.0,
                                     "unitPriceCurrency":"INR",
                                     "total":918.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDUzMDYxNWUtMzdmMi00OTNkLThhZGEtYTJhYTg0YTExNjQx",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Butter Chicken With Jeera Pulav",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1573914446/removed_bg/mumbai_t1c_food_court/fnaarpo6dblzimlrcqyq.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NjcwODZhMzktYzFjYy00MzA1LTk3N2EtZWY4ODE5MGExYjYw",
                                     "quantity":1,
                                     "unitPrice":309.0,
                                     "unitPriceCurrency":"INR",
                                     "total":309.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDQ3MDFmYTQtYzY3OS00OTM3LTk1NGQtZGIwNmM3ZGE5Mzg4",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Chicken Tikka In Masala Panini Bread",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1564136466/BANGALORE%20AIRPORT/urban%20food%20market/CHICKEN_TIKKA_IN_MASALA_PANINI_BREAD.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MTk4NzVkMDQtOTFkZS00Mzk5LWE1YmEtNmM0OWU3YTk1N2I4",
                                     "quantity":2,
                                     "unitPrice":189.0,
                                     "unitPriceCurrency":"INR",
                                     "total":378.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MTgzZDJiYTEtYzFiZi00YTQ0LThhNmQtMTk3Y2VkYTlhMjk1",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Idly",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1563629162/BANGALORE%20AIRPORT/Taste%20of%20India/IDLY.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06ZWM0NmE4N2ItODIxMy00NmI1LTg2MmItYzhmN2ZiODc1ZWIw",
                                     "quantity":1,
                                     "unitPrice":219.0,
                                     "unitPriceCurrency":"INR",
                                     "total":219.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6YWMzODc3ZmYtZjczMS00YjAzLWJhNTItNGMwM2U1ODk0YWQy",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Idly & Vada",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1561365062/Mumbai_airport/Lite%20bite/g-n/idli_vada_shutterstock_1227545842.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MDY4NjY5N2QtMTVlMC00NTEyLWJiM2MtMmM5OWU0MWI4MTMy",
                                     "quantity":1,
                                     "unitPrice":219.0,
                                     "unitPriceCurrency":"INR",
                                     "total":219.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6Njk0OGMwMzMtNjU1OC00OTc0LWEyY2EtYjQzY2JlZjU1OWY3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Upma",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1562831137/BANGALORE%20AIRPORT/Mixed/Upma-008.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDoxYzBmZGQ0OS0zZmVjLTRkNjctYjMyYy01ZmNlMjFkNmEwZTY=",
                         "total":3488.1,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 83.05}, {\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 83.05}]}",
                         "subtotal":3322.0,
                         "cartSla":1080,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront TC",
                            "id":"UGlja3VwUG9pbnQ6ZDA2YmVmYTMtZTY0My00YTZmLWFhMzMtN2EzZmY3MzFlOWZi",
                            "comments":null,
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Tiffin Centre",
                               "maxCartItems":999,
                               "id":"U3RvcmU6N2E5ZGYwNDMtYzlkZC00OGY3LWI2Y2MtZWZiZDE3MTk1ZTRk"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06N2Q1ODc5OTItNDI2ZS00NTE1LThmOTktMDQ1ZjViN2ZkMjVj",
                                     "quantity":1,
                                     "unitPrice":529.0,
                                     "unitPriceCurrency":"INR",
                                     "total":529.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDM4NzkxODItMDI4MS00ODliLWE1YzItMTJhYTE2MTczMzcz",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Andhra Kodi Kura",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1565076382/BANGALORE%20AIRPORT/tiffin%20centre/ANDHRA_KODI_KURA.jpg",
                                              "description":"Chicken cooked in onion , tomato ,dry coconut, red chilli and whole spices -specialty of andhra"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NzQ4YTJlMmYtZmJmYS00NDgxLWFmMDMtYjc0ZDUyNTE0YzMy",
                                     "quantity":1,
                                     "unitPrice":65.0,
                                     "unitPriceCurrency":"INR",
                                     "total":65.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6ZGNiNGE0N2UtNmYwNS00MmNlLWI5NmUtMzc0OWEyMjgyZWEz",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Idly",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1563629162/BANGALORE%20AIRPORT/Taste%20of%20India/IDLY.jpg",
                                              "description":"1 pc served with chutney and sambar"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NTA5MDU0ZmEtZjlmMy00YzVmLWE2ZGEtOTY5ZWQyNTdhNWUz",
                                     "quantity":2,
                                     "unitPrice":199.0,
                                     "unitPriceCurrency":"INR",
                                     "total":398.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6NWRkMDc0MDYtNjMzZS00ZDExLThlMjMtNGQ4MTBjZTEwYzZj",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Idly & Vada",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1565076382/BANGALORE%20AIRPORT/tiffin%20centre/IDLY_VADA.jpg",
                                              "description":"served with chutney and sambar"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06Njg1ZDQyYzktNTg5NC00ZjBjLTg2MWItMjU3ZDQ1MzYwOWMx",
                                     "quantity":2,
                                     "unitPrice":179.0,
                                     "unitPriceCurrency":"INR",
                                     "total":358.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6YjI2NGZmNzMtMjYxYi00MzQxLWJkZDktNDU3NjZiZjQ3NWU3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Kesari Bhath",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1562831184/BANGALORE%20AIRPORT/IndianFood/Kesari_Bhath.jpg",
                                              "description":"served with chutney and sambar"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NjZkMmI0MDAtODhkYy00MDdmLTk3NTctNzY1N2FkNTlkMTA5",
                                     "quantity":1,
                                     "unitPrice":219.0,
                                     "unitPriceCurrency":"INR",
                                     "total":219.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6YmM1MGYyNjYtY2NmZS00NjgxLWIxMDAtZGVmOTU1NjcxMTUw",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Masala Dosa",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1543485607/AIRPORTIMAGES/vaango/Masala_Dosa.jpg",
                                              "description":"served with chutney and sambar"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06N2RhZTAxMjMtMTMyNi00OTJkLWE3NWEtYTFiYmJhYmQzN2Nh",
                                     "quantity":2,
                                     "unitPrice":219.0,
                                     "unitPriceCurrency":"INR",
                                     "total":438.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDQ2OGU5OGUtZTdmZC00OGE2LWI1MmUtMDIwZTI1MjlhYjI0",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Masala Dosa-Mn",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1543485607/AIRPORTIMAGES/vaango/Masala_Dosa.jpg",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MmJlNTYzNzEtMjZkOS00MjEwLTgwYWEtM2MzN2Y3OTZkNTA5",
                                     "quantity":1,
                                     "unitPrice":219.0,
                                     "unitPriceCurrency":"INR",
                                     "total":219.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6Y2MzMmQwNWQtNzZmYS00MTJkLTgyMTYtZWI5Nzc4ODc1NmIx",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Masala Uttappam",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1565077480/BANGALORE%20AIRPORT/tiffin%20centre/MASALA_UTTAPPAM.jpg",
                                              "description":"served with chutney and sambar"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MjdkN2I5YzQtOGIyMS00YTk4LWFjNjEtODM0YjZiMDZkZTU3",
                                     "quantity":2,
                                     "unitPrice":239.0,
                                     "unitPriceCurrency":"INR",
                                     "total":478.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6ZjUwZGY1YjItNjdlMC00NGEwLWFhODctODJiMzJkYTEwYmI3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Pongal",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1563629589/BANGALORE%20AIRPORT/Taste%20of%20India/PONGAL.jpg",
                                              "description":"served with chutney and sambar"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NWVlNDE1ZWItZDMzOS00NTkwLWE3OWItMzc3ZGQ4YTkzYzVk",
                                     "quantity":1,
                                     "unitPrice":319.0,
                                     "unitPriceCurrency":"INR",
                                     "total":319.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDgwNWEyZDEtNTJhOC00OGNiLTk4YTQtY2EyZGMwOTRhMTI3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Tarkari Gassi",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1566887755/BANGALORE%20AIRPORT/tiffin%20centre/TARKARI_GASSI.jpg",
                                              "description":"A silken Mangalorean coconut and byadgi chilli gravy with vegetables"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NTNhYTMyZjUtNWU3Zi00NDdlLTgxYjUtMTlkNWNjM2Q4ZDVm",
                                     "quantity":1,
                                     "unitPrice":299.0,
                                     "unitPriceCurrency":"INR",
                                     "total":299.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDhhMWJkM2MtNzg1Ny00MGIxLWI4ODktYzY3N2E2NmI3ZTdh",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Upma With Tea",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1573849671/removed_bg/mumbai_domestic_departure/qdighiqazvp5zm3ump77.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDphNTRhOWU1NS1jYmQyLTQyZmYtYWFkYS0xODgzMWQyZjllNjY=",
                         "total":520.8,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 12.4}, {\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 12.4}]}",
                         "subtotal":496.0,
                         "cartSla":300,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront",
                            "id":"UGlja3VwUG9pbnQ6OTdmMGRkZTItMWFmNS00ZjgxLWJkMzItZWEzN2E3OGQyZWMz",
                            "comments":null,
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Taste of India exp",
                               "maxCartItems":999,
                               "id":"U3RvcmU6YjlhY2I0NjYtNjk5ZS00M2M1LWEyMTktYjMyNDViODY0Mjlm"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06OWZkZDhjMjYtZDRhOC00NDdmLTlkMzYtNWEyZWU0M2U2MWM2",
                                     "quantity":2,
                                     "unitPrice":109.0,
                                     "unitPriceCurrency":"INR",
                                     "total":218.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDZkZjFmNWUtYmIwMC00MTQ0LThiYTctYTMwZmIzNmE0M2Qz",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Masala Chai",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1573849513/removed_bg/mumbai_domestic_departure/bwghf4rzlj8ln9mvs3cw.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MDMzM2FkMTAtN2EyNS00M2M5LWEzY2YtNTk1OTU4YjQ5N2Iz",
                                     "quantity":2,
                                     "unitPrice":139.0,
                                     "unitPriceCurrency":"INR",
                                     "total":278.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MGI4MzkxNjMtNTQyMy00YjU3LTg3MzYtMGZlNzEwMTY0ZWY5",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":"CHOCOLATE",
                                           "product":{
                                              "name":"Muffin",
                                              "image":"https://res.cloudinary.com/grabbngo2/image/upload/v1575710392/removed_bg/bengaluru_airport/erej3y0mpq4toyoblkka.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDpiY2QwYmVkZC04ZTEwLTRkZjctOTUxZi04MmFkODgwNTEyNGU=",
                         "total":260.0,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 6.19}, {\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 6.19}]}",
                         "subtotal":247.62,
                         "cartSla":60,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront KK",
                            "id":"UGlja3VwUG9pbnQ6ODM4YTQ3YmEtYjY5Ni00MDVkLWJkOWYtMjk3ZWViYmUzZmVh",
                            "comments":null,
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Krispy Kreme",
                               "maxCartItems":999,
                               "id":"U3RvcmU6OTNlODMwNjYtZGFhMS00ZGZhLWI0NTQtZjgzNTVkNjcwZDgy"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06NjEzMjM2ZWEtYWE2OC00YTIzLTg4YzMtNWVmMGRlMzFkNDlm",
                                     "quantity":2,
                                     "unitPrice":123.81,
                                     "unitPriceCurrency":"INR",
                                     "total":247.62,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MGNmODdjOTQtZDNjMC00ZDRlLWI0YzYtN2ExODg0ZWIyNmUw",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Powdered Strawberry Filled",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1564558457/BANGALORE%20AIRPORT/Krispy%20Kreme/POWDERED_STRAWBERRY_FILLED_dounts.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDpkZDdkODZlYS1hOTEwLTRlYjctYTZiZC04ZDM1MzVjNTJkZTU=",
                         "total":450.45,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 10.73}, {\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 10.73}]}",
                         "subtotal":429.0,
                         "cartSla":900,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront noodle",
                            "id":"UGlja3VwUG9pbnQ6ZGM0YmU5YTQtNmU5ZS00MjM2LThhODgtYTNiNDA4MzM3MGU4",
                            "comments":"Noodle Domestic Departure Bangalore  Airport",
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Noodle",
                               "maxCartItems":999,
                               "id":"U3RvcmU6NWNkYjYxNDQtOGIwMi00MjcyLTljOTMtMTNmN2UxMjg3NjJk"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06ODk4N2M4MTQtYzhkNS00NGJlLWE2ZGYtZWVjNzFkZGFiNGUw",
                                     "quantity":1,
                                     "unitPrice":429.0,
                                     "unitPriceCurrency":"INR",
                                     "total":429.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDE4ZDM0YjItNjYzMi00NTQ5LTgxMDItZTU1Y2U3YzY5OGQ3",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Prawn Dimsum",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1544084555/AIRPORTIMAGES/yochina/yo%20china%20gng/Chicken_Dimsum.jpg",
                                              "description":"6 pcs"
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   },
                   {
                      "node":{
                         "id":"Q2FydDo5ZDcyZWE4ZC1iNzY5LTRlOTYtYmJkNy05MzQyMmVjMzFlNzA=",
                         "total":1023.75,
                         "totalCurrency":"INR",
                         "extra":"{\"charge\": [{\"type\": \"TAXES\", \"label\": \"2.5% CGST\", \"amount\": 24.38}, {\"type\": \"TAXES\", \"label\": \"2.5% SGST\", \"amount\": 24.38}]}",
                         "subtotal":975.0,
                         "cartSla":300,
                         "subtotalCurrency":"INR",
                         "specialInstructions":null,
                         "pickupPoint":{
                            "name":"Storefront",
                            "id":"UGlja3VwUG9pbnQ6YTlhYmJmMTEtZmQ0MS00MjRiLTg2NjAtYTcwOTk2OGIxZDhj",
                            "comments":"Puro Gusto Domestic Departure Bangalore  Airport",
                            "sla":{
                               "pushType":"TIME",
                               "preparationTime":900
                            },
                            "store":{
                               "name":"Puro Gusto",
                               "maxCartItems":999,
                               "id":"U3RvcmU6YjZkYTlkZDctMjZhMi00NzVjLWExYjYtMGJjN2UzMWUzMzM1"
                            }
                         },
                         "cartItems":{
                            "edges":[
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06MzRhYTAyMjEtYzYzZi00OWI4LWIxZWYtOGRiZGVjZjBlNzEw",
                                     "quantity":2,
                                     "unitPrice":189.0,
                                     "unitPriceCurrency":"INR",
                                     "total":378.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDQ3ODIwMWMtMzIxZS00Zjg5LTkyNDEtYmFkNDVkODllY2Ji",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":null,
                                           "product":{
                                              "name":"Aromatized American Coffee Vanila",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1564041853/BANGALORE%20AIRPORT/puro%20gusto/AROMATIZED_AMERICAN_COFFEE_VANILA.jpg",
                                              "description":"American black long coffee with 100 arabica beans served with vanilla flavour"
                                           }
                                        }
                                     }
                                  }
                               },
                               {
                                  "node":{
                                     "id":"Q2FydEl0ZW06YWZjMDkzZWMtOTAyMC00ZmE1LWI4YzctMzVhNDE5MjJiM2Iz",
                                     "quantity":3,
                                     "unitPrice":199.0,
                                     "unitPriceCurrency":"INR",
                                     "total":597.0,
                                     "totalCurrency":"INR",
                                     "customizations":{
                                        "edges":[
 
                                        ]
                                     },
                                     "stock":{
                                        "id":"U3RvY2s6MDU4ODU0NTAtZmIxMy00MzVmLWEyZDUtOTBiYjRiYmZiNWJi",
                                        "stockSla":"-1",
                                        "sku":{
                                           "quantity":"CHOCOLATE",
                                           "product":{
                                              "name":"Muffin",
                                              "image":"https://res.cloudinary.com/grabbngo/image/upload/v1568622146/Mumbai_airport/Cafe%20Ritazza/Muffin_400.png",
                                              "description":""
                                           }
                                        }
                                     }
                                  }
                               }
                            ]
                         }
                      }
                   }
                ]
             }
          }
       }


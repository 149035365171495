import React, { Component } from "react";
import CartManager from "../../singletons/CartManager";
import BottomPanel from "../../components/BottomPanel/BottomPanel";
import TextInput from "../../components/TextInput/TextInput";
import { Button } from "@material-ui/core";
import { getCheckOutFromServer } from "../../utils/helpers";
import classes from "./OrderReviewScreen.module.css";
import CheckoutRender from "../../components/CheckoutRender/CheckoutRender";
import Recommendation from "../../components/Recommendation/Recommendation";
import ApplyCode from '../../components/ApplyCode';

class OrderReviewScreen extends Component {
  state = {
    checkout: null,
    cartItemsNo: 0,
    updatingCart: false,
    deliveryLocation: "",
    showDeliveryLocationModal: false,
    mode: "",
    code:""
  };

  componentDidMount() {
    const carManager = new CartManager();
    const checkout = carManager.getCheckOut();
    const cartM = carManager.getUserDetail();

    console.log("checkout", JSON.stringify(checkout));
    const deliveryLocation = carManager.getCheckoutComment();
    let items = 0;
    if (checkout.carts && checkout.carts.edges) {
      for (let cart of checkout.carts.edges) {
        console.log("Cart Cart  ---> ", cart);
        for (let item of cart.node.cartItems.edges) {
          items = items + item.node.quantity;
        }
      }
    }
    this.setState({
      checkout,
      deliveryLocation,
      cartItemsNo: items,
      mode: cartM.mode
    });
  }

  _showDeliveryModal = () => {
    this.setState({
      showDeliveryLocationModal: true
    });
  };

  _hideDeliveryModal = () => {
    this.setState({
      showDeliveryLocationModal: false
    });
  };

  _onSubmit = text => {
    this.setState(
      {
        showDeliveryLocationModal: false,
        deliveryLocation: text
      },
      () => {
        const carManager = new CartManager();
        carManager.setCheckoutComment(text);
      }
    );
  };
  setStateValue = (vcode) =>{
    console.log('setStateValue', vcode)
    this.setState({
      code: vcode,
    });
    /*console.log('setStateValue-2', vcode)
    console.log('setStateValue-3', this.state.code)
    */
  }
  onApplyCouponCode = (ncode) =>{
    this.setStateValue(ncode);
    /*console.log('onApplyCouponCode', this.state.code)*/
    this._updateCheckout(ncode);
  }
  _onPlaceOrder = () => {
    this.props.history.push("/paymentScreen");
  };

  _updateCheckout = (ncode) => {
    let cartManager = new CartManager();
    const carts = cartManager.getCarts();
    this.setState({ updatingCart: true });
    this.setStateValue(ncode);
    const {
      checkedAep,
      code,
      phone,
      mode: orderMode
    } = cartManager.getUserDetail();
    console.log('inside update Checkout - Code', ncode)
    getCheckOutFromServer(carts, orderMode, phone, checkedAep, ncode)
      .then(res => {
        this.setState({
          checkout: res.updateCheckout.checkout
        });
        // This Part is calculating the no of cart items
        let items = 0;
        if (
          res.updateCheckout.checkout.carts &&
          res.updateCheckout.checkout.carts.edges
        ) {
          for (let cart of res.updateCheckout.checkout.carts.edges) {
            for (let item of cart.node.cartItems.edges) {
              items = items + item.node.quantity;
            }
          }
          if (items > 0) {
            // TO-DO: Convert above part into a stand alone function
            this.setState({
              cartItemsNo: items,
              updatingCart: false,
              checkout: res.updateCheckout.checkout
            });
          } else {
            this.props.history.push("/home");
          }
        }
        cartManager.updateCheckOutFromServer(res.updateCheckout.checkout);
      })
      .catch(err => {
        this.setState({
          updatingCart: false
        });
      });
  };

  

  _addCustomizedProduct = (item, pickupPoint) => {
    let cartManager = new CartManager();
    const stock = item.node.stock;
    const customizations = item.node.customizations.edges;
    let customizationValues = [];
    customizations.forEach(element => {
      customizationValues = customizationValues.concat(element.node.id);
    });
    cartManager.addStockToCartForPickupPoint(
      stock,
      pickupPoint,
      pickupPoint.store.maxCartItems,
      customizationValues
    );
    this._updateCheckout();
  };

  _addItem = (item, pickupPoint) => {
    console.log("items for addition", item);
    console.log("items for addition ------>", pickupPoint);
    let cartManager = new CartManager();
    const stock = item.node.stock;
    if (item.node.customizations.edges.length > 0) {
      this._addCustomizedProduct(item, pickupPoint);
    } else {
      console.log("Adding products to the cart");
      cartManager.addStockToCartForPickupPoint(
        stock,
        pickupPoint,
        pickupPoint.store.maxCartItems
      );
      this._updateCheckout();
    }
  };

  _removeItem = (item, pickupPoint) => {
    let cartManager = new CartManager();
    const stock = item.node.stock;
    if (
      cartManager.canRemoveStockFromCartForPickupPointWithoutCustomizations(
        stock,
        pickupPoint
      )
    ) {
      cartManager.removeStockFromCartForPickupPoint(stock, pickupPoint);
    } else {
      alert(
        "Multiple customizations of this item exist",
        "Please use the cart to delete the specific customization of this item."
      );
    }
    this._updateCheckout();
  };

  render() {
    const {
      checkout,
      deliveryLocation,
      mode,
      showDeliveryLocationModal
    } = this.state;
    return (
      <div>
        {checkout && (
          <>
            <div className={classes.checkout_container}>
              <CheckoutRender
                checkout={checkout}
                deliveryLocation={deliveryLocation}
                allowDeliveryEdit={false}
                orderMode={mode}
                onAddItem={this._addItem}
                onRemoveItem={this._removeItem}
              />
            </div>
            <div className={classes.applycode_container}>
            <ApplyCode
                codeText='' //{checkoutFromServer.codeText}
                code={this.state.code}
                onApplyCouponCode =  {this.onApplyCouponCode} // {this.setState({setCouponCode})}
                //{setcode}
            />
            </div>
            <div className={classes.place_order_button_div}>
              <Button
                className={classes.place_order_button}
                onClick={this._onPlaceOrder}
              >
                Place Order
              </Button>
            </div>
          </>
        )}
        {checkout && (
          <div className={classes.recommender_div}>
            <Recommendation
              checkout={checkout}
              orderMode={mode}
              onAddItem={this._addItem}
              updateCheckout={this._updateCheckout}
            />
          </div>
        )}
        {showDeliveryLocationModal && (
          <TextInput
            open={showDeliveryLocationModal}
            onClose={this._hideDeliveryModal}
            onSubmit={this._onSubmit}
          />
        )}
        {checkout && (
          <div className={classes.bottom_panel_div}>
            <BottomPanel
              itemsIncart={this.state.cartItemsNo}
              checkout={checkout}
              showStores={false}
            />
          </div>
        )}
      </div>
    );
  }
}

export default OrderReviewScreen;

import React, { Component } from "react";
import "./CartItemCard.css";
import {
  Typography,
  Paper,
  Popper,
  Fade,
  IconButton,
  Chip
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import MinusIcon from "@material-ui/icons/Remove";
import CartManager from "../../singletons/CartManager";

// const cartItemStyle = {
//   arrow: {
//     position: "absolute",
//     fontSize: 7,
//     width: "3em",
//     height: "3em",
//     "&::before": {
//       content: '""',
//       margin: "auto",
//       display: "block",
//       width: 0,
//       height: 0,
//       borderStyle: "solid"
//     }
//   }
// };

class CartItemCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPopOver: false,
      anchorEl: null,
      arrowRef: null,
      checkPopup: false
    };
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node
    });
  };

  handleClick = event => {
    const { currentTarget } = event;

    this.setState(state => ({
      anchorEl: currentTarget,
      openPopOver: !state.openPopOver,
      checkPopup: true
      // openPopOver: !state.openPopOver
    }));
  };

  onAdd = () => {
    let cartManager = new CartManager();
    const item = this.props.item;
    const stock = item.node.stock;
    console.log("STock For adding", stock);
    const pickupPoint = this.props.pickupPoint;
    if (
      cartManager.getTotalNumberOfItemsInCheckoutForPickupPoint(pickupPoint) >=
      pickupPoint.store.maxCartItems
    ) {
      alert(
        "Max items reached",
        "only" +
          pickupPoint.store.maxCartItems +
          " item(s) can be added to your cart for this store."
      );
    } else {
      if (item.node.customizations.edges.length > 0) {
        this._addCustomizedProduct();
      } else {
        console.log("Adding products to the cart");
        cartManager.addStockToCartForPickupPoint(
          stock,
          pickupPoint,
          pickupPoint.store.maxCartItems
        );
        this.props.updateCheckOut();
      }
    }
  };

  _addCustomizedProduct = () => {
    let cartManager = new CartManager();
    const item = this.props.item;
    const stock = item.node.stock;
    const customizations = item.node.customizations.edges;
    const pickupPoint = this.props.pickupPoint;
    let customizationValues = [];
    customizations.forEach(element => {
      customizationValues = customizationValues.concat(element.node.id);
    });
    cartManager.addStockToCartForPickupPoint(
      stock,
      pickupPoint,
      pickupPoint.store.maxCartItems,
      customizationValues
    );
    this.props.updateCheckOut();
  };

  onRemove = () => {
    let cartManager = new CartManager();
    const item = this.props.item;
    const stock = item.node.stock;
    const pickupPoint = this.props.pickupPoint;
    if (
      cartManager.canRemoveStockFromCartForPickupPointWithoutCustomizations(
        stock,
        pickupPoint
      )
    ) {
      cartManager.removeStockFromCartForPickupPoint(stock, pickupPoint);
    } else {
      alert(
        "Multiple customizations of this item exist",
        "Please use the cart to delete the specific customization of this item."
      );
    }
    this.props.updateCheckOut();
  };

  render() {
    console.log("Cart Item", this.props.item);
    const { item, storeName, location } = this.props;
    const id = this.state.openPopOver ? "simple-popper" : null;
    return (
      <div
        className={this.state.openPopOver ? "cartItemSelected" : "cartItemCard"}
      >
        <div
          onClick={this.handleClick}
          style={
            this.state.openPopOver
              ? { borderBottom: "1px dashed black" }
              : { borderBottom: "1px dashed white" }
          }
          className={"cartItemProductDdetailsDiv"}
        >
          <Typography
            style={{
              color: this.state.openPopOver ? "#000000" : "#ffffff",
              fontSize: "20px",
              marginRight: "8px",
              fontFamily: "Open Sans",
              fontWeight: "bold"
            }}
          >{`${item.node.quantity} X`}</Typography>
          <div className="cartItemCardFirstRow">
            <Typography
              style={{
                color: this.state.openPopOver ? "#000000" : "#ffffff",
                fontSize:
                  item.node.stock.sku.product.name.length < 18
                    ? "18px"
                    : item.node.stock.sku.product.name.length < 22
                    ? "16px"
                    : "14px",
                fontFamily: "Open Sans",
                fontWeight: "bold",
                textAlign: "left"
              }}
            >
              {item.node.stock.sku.product.name}
            </Typography>
            <Typography
              style={{
                color: this.state.openPopOver ? "#000000" : "#ffffff",
                fontSize:
                  item.node.stock.sku.product.name.length < 18
                    ? "18px"
                    : item.node.stock.sku.product.name.length < 22
                    ? "16px"
                    : "14px",
                fontFamily: "Open Sans",
                textAlign: "left"
              }}
            >
              {item.node.stock.sku.quantity}
            </Typography>
            <Typography
              style={{
                color: this.state.openPopOver ? "#000000" : "#ffffff",
                fontSize: "14px",
                fontFamily: "Open Sans",
                textAlign: "left"
              }}
            >
              {storeName}
            </Typography>
          </div>
          <Typography
            style={{
              color: this.state.openPopOver ? "#000000" : "#ffffff",
              fontSize: "20px",
              marginLeft: "8px",
              fontFamily: "Open Sans",
              fontWeight: "bold"
            }}
          >
            {`₹ ${item.node.unitPrice}`}
          </Typography>
        </div>
        <div className="cartItemBottomRow">
          <Typography
            style={{
              color: this.state.openPopOver ? "#000000" : "#ffffff",
              fontSize: "14px",
              fontFamily: "Open Sans"
            }}
          >
            {Math.floor(item.node.stock.stockSla / 60) <= 0
              ? ""
              : Math.floor(item.node.stock.stockSla / 60) <= 1
              ? `${Math.floor(item.node.stock.stockSla / 60)} Min`
              : `${Math.floor(item.node.stock.stockSla / 60)} Mins`}
          </Typography>
          <Typography
            style={{
              color: this.state.openPopOver ? "#000000" : "#ffffff",
              fontSize: "14px",
              fontFamily: "Open Sans"
            }}
          >
            {location}
          </Typography>
        </div>
        <Popper
          open={this.state.openPopOver}
          style={{
            visibility: this.state.openPopOver ? "visible" : "hidden",
            zIndex: 999
          }}
          anchorEl={this.state.anchorEl}
          transition
          id={id}
          placement="top-start"
          disablePortal={false}
          modifiers={{
            preventOverflow: {
              enabled: true,
              boundariesElement: "viewport"
            }
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                style={{
                  width: 500,
                  minHeight: 300,
                  padding: 8,
                  display: "flex",
                  flexDirection: "row",
                  flex: 1,
                  margin: 24
                }}
              >
                <div style={{ flex: 1, padding: 4 }}>
                  <img
                    src={item.node.stock.sku.product.image}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain"
                    }}
                    alt={""}
                  />
                </div>
                <div
                  style={{
                    flex: 1,
                    position: "relative",
                    marginLeft: 8
                  }}
                >
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontFamily: "Open Sans",
                      color: "#34495E",
                      fontWeight: "600"
                    }}
                  >
                    {item.node.stock.sku.product.name}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontFamily: "Open Sans",
                      color: "#000000",
                      opacity: 0.8
                    }}
                  >
                    {item.node.stock.sku.quantity || ""}{" "}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontFamily: "Open Sans",
                      color: "#000000",
                      opacity: 0.8
                    }}
                  >
                    {item.node.stock.sku.product.description}
                  </Typography>
                  {item.node.customizations.edges.map(val => (
                    <Chip
                      style={{ margin: 1 }}
                      key={val.node.id}
                      label={val.node.name}
                    />
                  ))}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      position: "absolute",
                      bottom: "8px",
                      width: "60%",
                      marginLeft: "20%",
                      marginRight: "20%",
                      alignSelf: "center"
                    }}
                  >
                    <IconButton
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                        backgroundColor: "red"
                      }}
                      onClick={this.onRemove}
                    >
                      <MinusIcon style={{ color: "#FFFFFF" }} />
                    </IconButton>
                    <Typography
                      style={{
                        color: "#000000",
                        fontSize: "20px",
                        marginLeft: "8px",
                        fontFamily: "Open Sans",
                        fontWeight: "bold"
                      }}
                    >
                      {item.node.quantity}
                    </Typography>
                    <IconButton
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "10px",
                        backgroundColor: "#458001"
                      }}
                      onClick={this.onAdd}
                    >
                      <AddIcon style={{ color: "#FFFFFF" }} />
                    </IconButton>
                  </div>
                </div>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
}

export default CartItemCard;

// <Card
// style={{
//   backgroundColor: "rgba(0, 0, 0, 0.1)",
//   border: "6px solid rgba(255, 255, 255, 0.5)",
//   textAlign: "center"
// }}
// className="CartItemCardMainDiv"
// >
// <Typography
//   className="CartItemCardTitle"
//   style={{ color: "#FFFFFF", fontSize: 24 }}
// >
//   {item.node.stock.sku.product.name}
// </Typography>
// <Typography
//   style={{
//     opacity: 0.5,
//     color: "#ffffff",
//     fontFamily: "Segoe UI",
//     fontSize: 18
//   }}
//   className="CartItemCardStoreName"
// >
//   {storeName}
// </Typography>
// <Typography
//   style={{
//     opacity: 0.5,
//     color: "#ffffff",
//     fontFamily: "Segoe UI",
//     fontSize: 30
//   }}
//   className="CartItemCardQtyText"
// >
//   {`${item.node.quantity} X ${item.node.unitPrice}`}
// </Typography>
// <DeleteOutlinedIcon className="CartItemCardDelete" />
// </Card>

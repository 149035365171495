import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import classes from "./StoreSelector.module.css";
import StoreCard from "../StoreCard/StoreCard";

class StoreSelector extends PureComponent {
  _OnStoreCardClick = store => {
    const { OnStoreCardClick } = this.props;
    OnStoreCardClick && OnStoreCardClick(store);
    if (store.store.isOpen) {
      const id = store.id;
      try {
        const eleData = this[id].getBoundingClientRect();
        const scrollBy = eleData.x - 600;
        this.storePanel.scrollBy({
          left: scrollBy,
          behavior: "smooth"
        });
      } catch (error) {
        console.log("Error in scrolling store", error);
      }
    }
  };

  _leftArrowClick = () => {
    this.storePanel.scrollBy({
      left: -500,
      behavior: "smooth"
    });
  };

  _rightArrowClick = () => {
    this.storePanel.scrollBy({
      left: 500,
      behavior: "smooth"
    });
  };

  render() {
    const { storesAround, currentActiveStore } = this.props;
    return (
      <div className={classes.main_container}>
        <div onClick={this._leftArrowClick} className={classes.arrow_icon_div}>
          <KeyboardArrowLeft className={classes.arrow_icon} />
        </div>
        <div
          className={classes.main_div}
          ref={cont => (this.storePanel = cont)}
        >
          {storesAround &&
            storesAround.map(store => {
              if (store.store.isOpen) {
                return (
                  <div
                    onClick={() => this._OnStoreCardClick(store)}
                    key={store.id}
                    ref={ele => (this[store.id] = ele)}
                    className={
                      currentActiveStore === store.id
                        ? classes.active_store
                        : classes.inactive_store
                    }
                  >
                    <StoreCard store={store} />
                  </div>
                );
              }
              return null;
            })}
        </div>
        <div onClick={this._rightArrowClick} className={classes.arrow_icon_div}>
          <KeyboardArrowRight className={classes.arrow_icon} />
        </div>
      </div>
    );
  }
}

StoreSelector.propTypes = {
  OnStoreCardClick: PropTypes.func,
  storesAround: PropTypes.any,
  currentActiveStore: PropTypes.any
};

export default StoreSelector;

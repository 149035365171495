import React, { PureComponent } from "react";
import Typography from "@material-ui/core/Typography";
import "./PaymentErrorHandling.css";
import { Button } from "@material-ui/core";
const error_group = require("../../assets/error_group.png");
class PaymentErrorScreen extends PureComponent {
  render() {
    const { onRestartPayment, onRestartOrder } = this.props;
    return (
      <div className="container">
        <div className="centered">
          <img
            src={error_group}
            alt="Error"
            style={{
              width: 120,
              height: 120,
              marginBottom: 20
            }}
          />
          <Typography
            style={{
              color: "#808080",
              fontSize: "40px",
              fontFamily: "Open Sans",
              fontWeight: "500"
            }}
          >
            Sorry, Order payment failed
            <br />
            <span style={{ fontSize: 30 }}>
              {" \n Any amount deducted will be refunded within 24 hours. "}
            </span>
          </Typography>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#529406",
              borderRadius: "8px",
              color: "#FFFFFF",
              padding: 16,
              fontSize: "34px",
              fontWeight: "500",
              marginTop: "32px"
            }}
            onClick={onRestartPayment}
          >
            Retry Payment
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#000000",
              borderRadius: "8px",
              color: "#FFFFFF",
              padding: 16,
              fontSize: "34px",
              fontWeight: "500"
            }}
            onClick={onRestartOrder}
          >
            Restart
          </Button>
        </div>
      </div>
    );
  }
}

export default PaymentErrorScreen;

import React, { PureComponent, useState } from "react";
import PropTypes from "prop-types";
import { checkoutData } from "../../sample/checkoutdata";
import classes from "./CheckoutRender.module.css";
import CheckoutCart from "../CheckoutCart/CheckoutCart";
import { calculateCheckoutExtra } from "../../utils/helpers";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import GrabbnGoLogo from "../GrabbnGoLogo/GrabbnGoLogo";
import Button from "@material-ui/core/Button";
import { ORDER_MODES, REGION_CONFIG_SETTING } from "../../utils/config";
import CartManager from "../../singletons/CartManager";
import TextInput from "../../components/TextInput/TextInput";
import ApplyCode from '../../components/ApplyCode';

import {
  ExpansionPanelSummary,
  ExpansionPanel,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class CheckoutRender extends PureComponent {
  
  state = {
    phoneNumber: this.props.phoneNumber,
    kioskShouldShowHeader: true,
    code:''
  };
  

  componentDidMount() {
    const cartManager = new CartManager();
    const { phone } = cartManager.getUserDetail();
    const region = JSON.parse(localStorage.getItem(REGION_CONFIG_SETTING));
    let kioskShouldShowHeader = true;
    console.log("Region ---->", region);
    if (region.hasOwnProperty("kioskShouldShowHeader")) {
      kioskShouldShowHeader = region.kioskShouldShowHeader;
    }
    this.setState({
      phoneNumber: phone ? phone.substring(phone.length - 10) : "",
      kioskShouldShowHeader: kioskShouldShowHeader
    });
  }
  _onAddItem = (item, store) => {
    const { onAddItem } = this.props;
    onAddItem(item, store);
    console.log("item for add", item, "from store", store);
  };
  _onRemoveItem = (item, store) => {
    const { onRemoveItem } = this.props;
    onRemoveItem(item, store);
    console.log("item for Remove", item, "from store", store);
  };
  render() {
    const {
      checkout,
      deliveryLocation,
      deliveryTime,
      allowDeliveryEdit,
      orderMode,
      isTokenScreen
    } = this.props;
    const checkoutExtras = calculateCheckoutExtra(checkout);
    console.log("Checkout extras", checkoutExtras);
    const { phoneNumber, kioskShouldShowHeader } = this.state;
    return (
      <Card elevation={2} className={classes.main_div}>
        <div className={classes.token_info_section}>
          <div className={classes.gng_logo_div}>
            <GrabbnGoLogo showLogo={kioskShouldShowHeader} />
          </div>
          {orderMode === ORDER_MODES.delivery && (
            <div className={classes.info_div}>
              <p>
                Your order will be delivered at <span>{deliveryLocation}</span>{" "}
                in <span>{deliveryTime}</span>
              </p>
              {allowDeliveryEdit && (
                <Button className={classes.edit_button}>
                  Click here to edit delivery location or time
                </Button>
              )}
            </div>
          )}
          <div className={classes.token_info}>
            <div className={classes.token_text_div}>
              <p> Token Number is last 4 digits of your phone number </p>
              <div className={classes.token_image_div}>
                <div className={classes.token_first}>
                  <p>Token</p>
                </div>
                <div className={classes.token_second}>
                  <p>{phoneNumber.slice(-4)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className={classes.main_content}>
          <div className={classes.checkout_content}>
            {checkout.carts.edges.map((cart, index) => (
              <div key={cart.node.id}>
                <CheckoutCart
                  onAddItem={this._onAddItem}
                  onRemoveItem={this._onRemoveItem}
                  showQtyPicker={!isTokenScreen}
                  cart={cart.node}
                />
                {index < checkout.carts.edges.length && <Divider />}
              </div>
            ))}
          </div>
          <div className={classes.amount_details_div}>
            <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.charges_div}>
                  <p className={classes.charge_label}>Taxes and Charges</p>
                  <p className={classes.charge_amount}>
                    <span>₹</span>
                    {checkoutExtras["Taxes and Charges"].toFixed(2) || 0.0}
                  </p>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                <div className={classes.charges_container}>
                  {Object.entries(checkoutExtras).map(([key, value], index) => {
                    if (key !== "Taxes and Charges") {
                      return (
                        <div className={classes.charges_div} key={index}>
                          <p className={classes.charge_label}>{key}</p>
                          <p className={classes.charge_amount}>
                            <span>₹</span>
                            {value.toFixed(2)}
                          </p>
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
            {/*<ApplyCode
                codeText={checkoutFromServer.codeText}
                scode={code}
                onApplyCouponCode = {this.setState({code:scode})}
                //{setcode}
            />*/}
            <Divider />
            <div className={classes.charges_div}>
              <p className={classes.charge_label}>Total</p>
              <p className={classes.charge_amount}>
                <span>₹</span>
                {checkout.total.toFixed(2)}
              </p>
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

CheckoutRender.propTypes = {
  checkout: PropTypes.any,
  deliveryLocation: PropTypes.string,
  deliveryTime: PropTypes.any,
  allowDeliveryEdit: PropTypes.bool,
  orderMode: PropTypes.string,
  phoneNumber: PropTypes.string,
  isTokenScreen: PropTypes.bool,
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func
};

CheckoutRender.defaultProps = {
  checkout: checkoutData.checkout,
  deliveryLocation: "Boarding Gate 15",
  deliveryTime: "20 mins",
  allowDeliveryEdit: false,
  orderMode: ORDER_MODES.delivery,
  phoneNumber: "+918112232159",
  isTokenScreen: false,
  onAddItem: () => {},
  onRemoveItem: () => {}
};

export default CheckoutRender;

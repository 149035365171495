import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "./Customization.css";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import { Button } from "@material-ui/core";

const chekBox = require("../../assets/check.png");
// const radioBox = require("../../assets/radio.png");

class SelectCustomization extends PureComponent {
  handleSelectionChange = (cust, e) => {
    e && e.stopPropagation();
    this.props.handleChange(cust, this.props.customization);
  };

  _renderCheckBoxOutput = (customizationConfiguration, cust) => {
    return (
      customizationConfiguration &&
      customizationConfiguration.indexOf(cust.node.id) > -1
    );
  };
  render() {
    const { customization, customizationConfiguration } = this.props;
    console.log(
      "Customization config",
      customizationConfiguration,
      customization
    );

    if (this.checkCustomization(customization) === true) {
      return (
        <div
          style={custStyles.customizationOptionsSelectDiv}
          className="item_body"
        >
          {customization.node.children.edges.map(cust => (
            <div
              style={custStyles.custListItem}
              onClick={e => this.handleSelectionChange(cust.node.id, e)}
              key={cust.node.id}
            >
              <div style={custStyles.selectionBoxDiv} className="tick_img">
                {customizationConfiguration.indexOf(cust.node.id) > -1 && (
                  <img
                    src={chekBox}
                    style={{ objectFit: "contain" }}
                    alt=""
                  />
                )}
              </div>
              <div
                className="item_row"
                style={
                  customizationConfiguration.indexOf(cust.node.id) > -1
                    ? custStyles.selectedCheckBox
                    : custStyles.notSelected
                }
              >
                <Typography
                  style={
                    customizationConfiguration.indexOf(cust.node.id) > -1
                      ? custStyles.selectedCustName
                      : custStyles.custName
                  }
                >
                  {cust.node.name}
                </Typography>
              </div>
              <div
                className="item_row"
                style={
                  customizationConfiguration.indexOf(cust.node.id) > -1
                    ? custStyles.selectedPriceDivCheck
                    : custStyles.priceDiv
                }
              >
                <Typography
                  style={
                    customizationConfiguration.indexOf(cust.node.id) > -1
                      ? custStyles.selectedPriceText
                      : custStyles.priceText
                  }
                >
                  ₹
                  <span style={{ fontWeight: "600", marginLeft: "6px" }}>
                    {cust.node.salePrice.toFixed(2)}
                  </span>
                </Typography>
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }
  checkCustomization = Value => {
    console.log("checkCustomization", Value);
    let ndisplay = true;
    if (
      Value.node.maxSelectable === Value.node.minSelectable &&
      Value.node.children.edges.length === Value.node.maxSelectable
    ) {
      ndisplay = false;
    }
    console.log("checkCategory", Value);
    return ndisplay;
  };
}
function getModalStyle() {
  const top = 10;

  return {
    top: `${top}%`,
    left: "5%",
    right: "5%",
    height: "70%",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: 12,
    zIndex: 99
  };
}

const styles = theme => ({
  paper: {
    position: "absolute",
    width: theme.spacing.unit * 50,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    textAlign: "center"
  },
  formControl: {
    margin: theme.spacing.unit * 3
  },
  group: {
    margin: `${theme.spacing.unit}px 0`
  }
});

class Customization extends React.Component {
  state = {
    value: this.props.item.customizations.edges.find(
      ele =>
        !(
          ele.node.minSelectable === ele.node.maxSelectable &&
          ele.node.children.edges.length === ele.node.maxSelectable
        )
    ),
    customizationConfiguration: this.props.customizationConfiguration
  };

  handleChange = (custId, customization) => {
    let { customizationConfiguration } = this.state;
    console.log("Customizing configuration", custId, customization);
    const customizationID = customization.node.id;
    const selectableOptions = this._checkForCustomizationAccuracy(
      customization
    );
    if (
      customizationConfiguration[customizationID] &&
      customizationConfiguration[customizationID].indexOf(custId) > -1 &&
      selectableOptions.max !== selectableOptions.min &&
      customizationConfiguration[customizationID].length !==
        selectableOptions.max
    ) {
      customizationConfiguration[customizationID] = customizationConfiguration[
        customizationID
      ].filter(item => item !== custId);
      console.log("Item Rmeoved from the config", customizationConfiguration);
      this.setState({ customizationConfiguration: customizationConfiguration });
    } else {
      if (
        selectableOptions.max === selectableOptions.min &&
        customizationConfiguration[customizationID].length <=
          selectableOptions.max
      ) {
        selectableOptions.max === 1
          ? (customizationConfiguration = {
              ...this.state.customizationConfiguration,
              [customizationID]: [custId]
            })
          : (customizationConfiguration = {
              ...this.state.customizationConfiguration,
              [customizationID]: [
                ...this.state.customizationConfiguration[customizationID],
                custId
              ]
            });
        console.log("Customization configuration", customizationConfiguration);
        this.setState({ customizationConfiguration });
      } else if (!customizationConfiguration[customizationID]) {
        customizationConfiguration = {
          [customizationID]: [custId]
        };
        this.setState({ customizationConfiguration });
      } else if (selectableOptions.max === 0 && selectableOptions.min === 0) {
        this.setState({
          ...this.state,
          customizationConfiguration: {
            ...this.state.customizationConfiguration,
            [customizationID]: [
              ...this.state.customizationConfiguration[customizationID],
              custId
            ]
          }
        });
      } else if (
        customizationConfiguration[customizationID].length <
        selectableOptions.max
      ) {
        this.setState({
          ...this.state,
          customizationConfiguration: {
            ...this.state.customizationConfiguration,
            [customizationID]: [
              ...this.state.customizationConfiguration[customizationID],
              custId
            ]
          }
        });
      }
    }
  };

  /**
   * @returns Maximum and minimum selectable options for the customization
   */
  _checkForCustomizationAccuracy = customization => {
    if (
      customization.node.maxSelectable === 1 &&
      customization.node.minSelectable === 1
    ) {
      return { max: 1, min: 1 };
    } else if (
      customization.node.maxSelectable === 0 &&
      customization.node.minSelectable === 0
    ) {
      return { max: 999, min: 0 };
    } else if (
      customization.node.maxSelectable === customization.node.minSelectable
    ) {
      return {
        max: customization.node.minSelectable,
        min: customization.node.minSelectable
      };
    }
    return {
      max: customization.node.maxSelectable,
      min: customization.node.minSelectable
    };
  };

  renderMaxMinSelectableText = customization => {
    if (
      customization.node.maxSelectable === 1 &&
      customization.node.minSelectable === 1
    ) {
      return "Select 1 option";
    } else if (
      customization.node.maxSelectable === 0 &&
      customization.node.minSelectable === 0
    ) {
      return "Select any number of options";
    } else if (
      customization.node.maxSelectable === customization.node.minSelectable
    ) {
      return "Select " + customization.node.minSelectable + " options";
    }
    return (
      "Select between " +
      customization.node.minSelectable +
      " and " +
      customization.node.maxSelectable +
      " options"
    );
  };

  _renderOptionsForCustomization = customization => {
    const { customizationConfiguration } = this.state;
    console.log("_customizationConfiguration", customizationConfiguration);
    if (this.checkCategory(customization)) {
      return (
        <div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: 0,
              width: 0,
              zIndex: -2,
              filter: "blur(4px)"
            }}
          />
          <div style={{ width: "100%" }}>
            <SelectCustomization
              customizationConfiguration={
                customizationConfiguration[customization.node.id]
              }
              handleChange={this.handleChange}
              customization={customization}
            />
          </div>
        </div>
      );
    }
    return null;
  };

  _addToCart = (customizations, stock, e = null) => {
    console.log("Customization config ---> Adding to the cart", customizations);
    this.props.onAddToCart(customizations, stock, e);
  };

  renderCheckBox = customization => {
    if (
      customization.node.maxSelectable === 1 &&
      customization.node.minSelectable === 1
    ) {
      return false;
    } else if (
      customization.node.maxSelectable === 0 &&
      customization.node.minSelectable === 0
    ) {
      return true;
    } else if (
      customization.node.maxSelectable === customization.node.minSelectable
    ) {
      return true;
    }
    return true;
  };

  handleCatChange = (event, value) => {
    console.log("handleCatChange", value);
    this.setState({ value });
  };

  _handleClose = e => {
    console.log("Closing the modal", e);
    // this.props.handleClose(e);
  };

  render() {
    const { classes, item } = this.props;
    console.log("Customizations", item);
    const { value, customizationConfiguration } = this.state;
    return (
      <div>
        <div style={custStyles.blurBackgroundDiv} />
        <div style={getModalStyle()} className={classes.paper + " modal_ph"}>
          <div style={{ padding: 12, flex: 3 }} className="modal_header">
            <div
              className="header_ph"
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "24px"
              }}
            >
              <div
                style={{ flex: 1, height: "100%", float: "right" }}
                className="left_img"
              >
                <img
                  src={item.sku.image}
                  alt={item.sku.name}
                  style={custStyles.customizationItemImage}
                />
              </div>
              <div
                style={custStyles.customizationItemDetailDiv}
                className="right_content"
              >
                <Typography style={custStyles.customizationItemName}>
                  {item.sku.name}
                </Typography>
                <Typography style={custStyles.customizationItemSku}>
                  {item.sku.quantity}
                </Typography>
                <Typography style={custStyles.customizationItemDesc}>
                  {item.sku.description}
                </Typography>
              </div>
            </div>
            <Typography
              className="modal_note"
              style={{
                color: "#34495E",
                fontFamily: "Open Sans",
                fontSize: "32px",
                textAlign: "left",
                paddingLeft: "24px"
              }}
            >
              <span style={{ fontWeight: "bold" }}>Choose</span> Additional
              Ingredients
            </Typography>
          </div>
          <div style={{ flex: 6,overflowY: 'auto' }} className="modal_body">
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={this.handleCatChange}
                variant="scrollable"
                style={{ boxShadow: "none" }}
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
              >
                {item.customizations.edges
                  .filter(cust => this.checkCategory(cust))
                  .map((custom, index) => (
                    <Tab
                      style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        width: "250px"
                      }}
                      value={custom}
                      key={index}
                      label={custom.node.name}
                    />
                  ))}
              </Tabs>
            </AppBar>
            {this._renderOptionsForCustomization(value)}
          </div>
          <Divider />
          <div style={custStyles.cartActionButtonsContainer}>
            <div>
              <Button
                className="btn btn_alert"
                onClick={e => {
                  this.props.handleClose(e);
                }}
                ref={this.cancelButton}
                variant="contained"
                size="large"
                style={{
                  border: "1px solid #D43F3A",
                  backgroundColor: "#D95450",
                  ...custStyles.cartActionButtons
                }}
              >
                Cancel
              </Button>
            </div>
            <Button
              className="btn btn_success"
              onClick={e =>
                this._addToCart(customizationConfiguration, this.props.item, e)
              }
              variant="contained"
              size="large"
              style={{
                border: "1px solid #417505",
                backgroundColor: "#417505",
                ...custStyles.cartActionButtons
              }}
            >
              Add To Cart
            </Button>
          </div>
        </div>
      </div>
    );
  }
  checkCategory = value => {
    console.log("Check category ---->", value);
    let ndisplay = true;
    if (
      !value ||
      (value.node.maxSelectable === value.node.minSelectable &&
        value.node.children.edges.length === value.node.maxSelectable)
    ) {
      ndisplay = false;
    }
    console.log("checkCategory *********", ndisplay);
    return ndisplay;
  };
}

Customization.propTypes = {
  classes: PropTypes.object.isRequired
};

const custStyles = {
  custListItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    padding: 4
  },
  selected: {
    background: "linear-gradient(90deg, #2F5C31 0%, #55C246 100%)",
    borderRadius: "10px",
    display: "flex",
    flex: 7.5,
    padding: 8,
    boxSizing: "border-box",
    alignItems: "left",
    transition: "all 0.1s ease"
  },
  selectedCheckBox: {
    background: "linear-gradient(90deg, #2F5C31 0%, #55C246 100%)",
    borderRadius: "10px",
    display: "flex",
    flex: 7.5,
    padding: 8,
    boxSizing: "border-box",
    alignItems: "left",
    transition: "all 0.1s ease"
  },
  notSelected: {
    boxShadow: "inset 0 1px 10px 0 rgba(0,0,0,0.2)",
    borderRadius: "10px",
    boxSizing: "border-box",
    padding: 8,
    display: "flex",
    flex: 7.5,
    alignItems: "left",
    transition: "all 0.1s ease"
  },
  custName: {
    color: "#333333",
    fontFamily: "Open Sans",
    fontSize: "24px",
    transition: "all 0.1s ease"
  },
  selectedCustName: {
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontSize: "24px",
    transition: "all 0.1s ease"
  },
  priceDiv: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    padding: 8,
    marginLeft: "10px",
    flex: 1.5,
    boxShadow: "inset 0 1px 10px 0 rgba(0,0,0,0.2)",
    borderRadius: "10px",
    boxSizing: "border-box",
    justifyContent: "center",
    transition: "all 0.1s ease"
  },
  selctedPriceDiv: {
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: 8,
    marginLeft: "10px",
    flex: 1.5,
    background: "linear-gradient(90deg, #2F5C31 0%, #55C246 100%)",
    boxSizing: "border-box",
    transition: "all 0.1s ease"
  },
  selectedPriceDivCheck: {
    background: "linear-gradient(90deg, #2F5C31 0%, #55C246 100%)",
    display: "flex",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px",
    padding: 8,
    marginLeft: "10px",
    flex: 1.5,
    transition: "all 0.1s ease",
    boxSizing: "border-box"
  },
  priceText: {
    color: "#333333",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "400",
    transition: "all 0.1s ease"
  },
  selectedPriceText: {
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontSize: "24px",
    fontWeight: "400",
    transition: "all 0.1s ease"
  },
  selectionBoxDiv: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: 8
  },
  customizationItemImage: {
    height: 260,
    width: 286,
    backgroundColor: "transparent",
    boxShadow: "0 1px 15px 0 rgba(0,0,0,0.2)",
    objectFit: "contain"
  },
  customizationItemDetailDiv: {
    flex: 2,
    height: "100%",
    textAlign: "left",
    paddingLeft: "24px"
  },
  cartActionButtonsContainer: {
    flex: 1,
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
  },
  cartActionButtons: {
    margin: 8,
    height: "60px",
    fontSize: 20,
    color: "#FFFFFF",
    width: "200px",
    borderRadius: "10px",
    fontWeight: "bold",
    fontFamily: "Open Sans"
  },
  customizationItemName: {
    color: "#34495E",
    fontSize: 40,
    fontWeight: "600",
    fontFamily: "Open Sans"
  },
  customizationItemSku: {
    color: "#34495E",
    fontSize: 24,
    fontWeight: "600",
    fontFamily: "Open Sans"
  },
  customizationItemDesc: {
    color: "#34495E",
    fontSize: 18,
    fontWeight: "400",
    fontFamily: "Open Sans"
  },
  customizationOptionsSelectDiv: {
    padding: "24px"
  },
  blurBackgroundDiv: {
    filter: "blur(20px)",
    WebkitFilter: "blur(20px)",
    backgroundColor: "	#DCDCDC	",
    opacity: 0.6,
    position: "fixed",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vh",
    zIndex: 0
  }
};

// We need an intermediary variable for handling the recursive nesting.
Customization = withStyles(styles)(Customization);

export default Customization;

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import Modal from "@material-ui/core/Modal";
import IdleTimer from "react-idle-timer";
import HomeScreen from "../screens/HomeScreen/HomeScreen";
import BottomPanel from "../components/BottomPanel/BottomPanel";
import StoreProductsList from "../components/StoreProductsList/StoreProductsList";
import { getCheckOutFromServer } from "../utils/helpers";
import CartManager from "../singletons/CartManager";
import PaymentScreen from "../screens/PaymentScreen/PaymentScreen";
import TokenScreen from "../screens/TokenScreen/TokenScreen";
import StartScreen from "../screens/StartScreen/StartScreen";
import OrderModeModal from "../components/OrderModeModal/OrderModeModal";
import "./MainRouter.css";
import UserDetailScreen from "../screens/UserDetailScreen/UserDetailScreen";
import { Spring } from "react-spring";
import { orderMode, REGION_CONFIG_SETTING } from "../utils/config";
import GrabbnGoLogo from "../components/GrabbnGoLogo/GrabbnGoLogo";
import OrderReviewScreen from "../screens/OrderReviewScreen/OrderReviewScreen";
import client from "../api/client";

const ImageAnimator = ({
  open = false,
  image,
  startPosition = { x: 0, y: 0 },
  onAnimationEnd
}) => {
  return (
    <Modal hideBackdrop open={open}>
      <div
        style={{
          position: "absolute",
          backgroundColor: "transparent",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh"
        }}
      >
        <Spring
          from={{
            top: startPosition.y,
            left: startPosition.x,
            height: 480,
            width: 360
          }}
          onRest={onAnimationEnd}
          to={{ top: 1600, left: 400, height: 80, width: 60 }}
          config={{ duration: 1000 }}
        >
          {state => (
            <img
              style={{
                objectFit: "contain",
                position: "absolute",
                ...state
              }}
              src={image}
              alt=""
            />
          )}
        </Spring>
      </div>
    </Modal>
  );
};

const FirstRouter = () => (
  <ApolloProvider client={client}>
    <Router>
      <div
        onContextMenu={e => {
          e.preventDefault();
        }}
        className="RouterDiv"
      >
        <Switch>
          <Route path="/home" component={MainRouter} />
          <Route exact path="/paymentScreen" component={PaymentScreen} />
          <Route exact path="/userDetailScreen" component={UserDetailScreen} />
          <Route
            exact
            path="/orderReviewScreen"
            component={OrderReviewScreen}
          />
          <Route exact path="/" component={StartScreen} />
          <Route exact path="/tokenScreen" component={TokenScreen} />
        </Switch>
      </div>
    </Router>
  </ApolloProvider>
);

class MainRouter extends Component {
  constructor() {
    super();
    const cartManager = new CartManager();
    const userDetail = cartManager.getUserDetail();
    this.state = {
      checkout: null,
      screenIdle: false,
      addingItem: null,
      itemCoordsX: 0,
      itemCoordsY: 0,
      animatingItemToCart: false,
      cartItemsNo: 0,
      showOrderPickModal: false,
      emptyStores: false,
      currentMode: userDetail.mode,
      shouldRenderBottomPanel: false,
      kioskShouldShowHeader: true
    };
    this.idleTimer = null;
  }
  _onStoreCardClick = storeId => {
    this.props.history.push(`/home/${storeId}`);
  };

  _goToPaymentScreen = () => {
    this.props.history.push("/userDetailScreen");
  };

  componentDidMount() {
    const region = JSON.parse(localStorage.getItem(REGION_CONFIG_SETTING));
    let kioskShouldShowHeader = true;
    console.log(
      "Region ---->",
      region,
      region.hasOwnProperty("kioskShouldShowHeader")
    );
    if (region.hasOwnProperty("kioskShouldShowHeader")) {
      kioskShouldShowHeader = region.kioskShouldShowHeader;
      this.setState({
        kioskShouldShowHeader: kioskShouldShowHeader
      });
    }
    this._getUpdatedCheckout();
    const cartManager = new CartManager();
    const userDetail = cartManager.getUserDetail();
    if (
      userDetail.mode === orderMode.dineIn ||
      userDetail.mode === orderMode.takeAway ||
      userDetail.mode === orderMode.delivery
    ) {
      this.setState({
        showOrderPickModal: false,
        currentMode: userDetail.mode,
        shouldRenderBottomPanel: userDetail.mode ? true : false,
        kioskShouldShowHeader: kioskShouldShowHeader
      });
    } else {
      this.setState({ showOrderPickModal: true });
    }
  }

  componentDidUpdate() {
    if (
      this.state.screenIdle &&
      this.props.location.pathname.includes("/home")
    ) {
      const cartManager = new CartManager();
      cartManager.clearAllCarts();
      this.props.history.replace("/");
    }
  }

  componentDidCatch(err, info) {
    if (err) {
      this.props.history.replace("/home");
      window.location.reload();
    }
  }

  _dineInMode = () => {
    const cartManager = new CartManager();
    cartManager.updateOrderMode(orderMode.dineIn);
    this.setState({
      showOrderPickModal: false,
      currentMode: orderMode.dineIn,
      shouldRenderBottomPanel: true
    });
  };

  _takeAwayMode = () => {
    const cartManager = new CartManager();
    cartManager.updateOrderMode(orderMode.takeAway);
    this.setState({
      showOrderPickModal: false,
      currentMode: orderMode.takeAway,
      shouldRenderBottomPanel: true
    });
  };

  _deliveryMode = () => {
    const cartManager = new CartManager();
    cartManager.updateOrderMode(orderMode.delivery);
    this.setState({
      showOrderPickModal: false,
      currentMode: orderMode.delivery,
      shouldRenderBottomPanel: true
    });
  };

  componentWillUnmount() {
    this.timer = null;
    clearInterval(this.timer);
    console.log("<---- MainRouter is Unmounting ---->");
  }

  _getUpdatedCheckout = (item = null, coords = null) => {
    if (item && coords) {
      this.setState({
        addingItem: item,
        itemCoordsX: coords.x,
        itemCoordsY: coords.y,
        animatingItemToCart: true
      });
      this.transitionStyles = {
        entering: { top: coords.y, left: coords.x },
        exiting: { top: "70vh", left: "40vh" }
      };
    }
    let cartManager = new CartManager();
    const carts = cartManager.getCarts();
    const orderMode = cartManager.getUserDetail().mode;
    console.log("Order mode", orderMode);
    // console.log("Carts ", JSON.stringify(carts));
    getCheckOutFromServer(carts, orderMode)
      .then(res => {
        this.setState({
          checkout: res.updateCheckout.checkout
        });
        // This Part is calculating the no of cart items
        let items = 0;
        if (
          res.updateCheckout.checkout.carts &&
          res.updateCheckout.checkout.carts.edges
        ) {
          for (let cart of res.updateCheckout.checkout.carts.edges) {
            for (let item of cart.node.cartItems.edges) {
              items = items + item.node.quantity;
            }
          }
          // TO-DO: Convert above part into a stand alone function
          this.setState({ cartItemsNo: items });
        }
        cartManager.updateCheckOutFromServer(res.updateCheckout.checkout);
      })
      .catch(err => {
        this.setState({
          addingItem: null,
          coords: null,
          animatingItemToCart: false
        });
      });
  };

  onAnimationEnd = () => {
    this.setState({
      addingItem: null,
      coords: null,
      animatingItemToCart: false
    });
  };

  onAction = () => {
    this.setState({ screenIdle: false });
  };

  onIdle = () => {
    console.log("Screen has been idle ----> For more than 2 minutes");
    this.setState({
      screenIdle: true
    });
  };

  _onEmptyStoreList = () => {
    this.setState({
      emptyStores: true
    });
  };

  onRestartClick = () => {
    this.setState({
      checkout: null,
      cartItemsNo: 0,
      currentMode: null,
      showOrderPickModal: true
    });
  };

  render() {
    const {
      cartItemsNo,
      emptyStores,
      shouldRenderBottomPanel,
      kioskShouldShowHeader
    } = this.state;
    const positionImage = {
      x: this.state.itemCoordsX,
      y: this.state.itemCoordsY
    };
    console.log("Show sjow kiosk headr", kioskShouldShowHeader);
    if (!emptyStores) {
      return (
        <Router>
          <div
            ref={ref => {
              this.routerComponent = ref;
            }}
            className="MainRouterDiv"
          >
            <IdleTimer
              ref={ref => {
                this.idleTimer = ref;
              }}
              element={this.routerComponent}
              onIdle={this.onIdle}
              stopOnIdle
              onAction={this.onAction}
              timeout={1000 * 2 * 60}
            />
            <ImageAnimator
              open={this.state.animatingItemToCart}
              image={this.state.addingItem}
              startPosition={positionImage}
              onAnimationEnd={this.onAnimationEnd}
            />
            {this.state.currentMode && shouldRenderBottomPanel && (
              <div className="ScreenRenderDiv">
                <GrabbnGoLogo showLogo={kioskShouldShowHeader} />
                <Switch>
                  <Route
                    path="/home/:store"
                    exact
                    render={props => (
                      <StoreProductsList
                        updateCheckOut={this._getUpdatedCheckout}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path="/home"
                    exact
                    render={props => (
                      <HomeScreen
                        updateCheckOut={this._getUpdatedCheckout}
                        orderMode={this.state.currentMode}
                        {...props}
                      />
                    )}
                  />
                </Switch>
              </div>
            )}
            <div className="BlurBackDiv" />
            <div
              className="footer_ph"
              style={{
                height: "580px",
                zIndex: 99,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0
              }}
            >
              {shouldRenderBottomPanel && (
                <BottomPanel
                  checkout={this.state.checkout}
                  updateCheckOut={this._getUpdatedCheckout}
                  goToPaymentScreen={this._goToPaymentScreen}
                  itemsIncart={cartItemsNo}
                  showStores={true}
                  onRestart={this.onRestartClick}
                  onEmptyStoreList={this._onEmptyStoreList}
                  currentMode={this.state.currentMode}
                />
              )}
            </div>
            <Modal open={this.state.showOrderPickModal} disableAutoFocus>
              <OrderModeModal
                onTakeAwayClick={this._takeAwayMode}
                onDineInClick={this._dineInMode}
                onDeliveryClick={this._deliveryMode}
                kioskShouldShowHeader={kioskShouldShowHeader}
              />
            </Modal>
          </div>
        </Router>
      );
    }
    return (
      <img
        src={require("../assets/Closed.png")}
        height={"1920px"}
        width={"1080px"}
        alt=""
        style={{ zIndex: 999, objectFit: "contain" }}
      />
    );
  }
}

export default FirstRouter;

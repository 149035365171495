import _ from "lodash";
let instance = null;

export default class CartManager {
  constructor() {
    if (!instance) {
      instance = this;
      this.carts = [];
      this.storePickupPointAssociation = {};
      this.numberItemsPerPickupPoint = {};
      this.specialInstructions = {}; //cartID and their corresponding special instructions
      this.estimatedTraficTime = {};
      this.checkOut = {};
      this.paymentToken = null;
      this.checkoutComment = "";
      this.userDetail = {
        phone: null,
        mode: null,
        code: "",
        checkedAep: false
      };
    }
    this.checkApi = true;
    return instance;
  }
  setCheckoutComment = data => {
    const newData = data;
    localStorage.setItem("CHECKOUT_COMMENT_KIOSK", newData);
  };
  getCheckoutComment = () => {
    return localStorage.getItem("CHECKOUT_COMMENT_KIOSK");
  };
  getCheckAPI() {
    return this.checkApi;
  }
  setCheckAPI(Val) {
    this.checkApi = Val;
  }
  getCarts = () => {
    return this.carts;
  };

  updateCheckOutFromServer = checkOut => {
    this.checkOut = checkOut;
  };

  // Update Order Mode for the use
  updateOrderMode = mode => {
    this.userDetail = { ...this.userDetail, mode };
  };

  getPaymentToken = () => {
    return this.paymentToken;
  };

  updatePaymentToken = token => {
    this.paymentToken = token;
  };

  updateUserDetail = phone => {
    this.userDetail = { ...this.userDetail, phone };
  };

  updateAepDetails = (aepChecked = false, code = "") => {
    this.userDetail = {
      ...this.userDetail,
      checkedAep: aepChecked,
      code: code
    };
  };

  getUserDetail = () => {
    return this.userDetail;
  };

  getCheckOut = () => {
    return this.checkOut;
  };

  addStockToCartForPickupPoint = (
    stock,
    pickupPoint,
    maxCartItems = 999,
    customizations = []
  ) => {
    //Check for cart in all cart for a pickup points
    let storeId = pickupPoint.id;
    console.log("Store Id", pickupPoint);
    if (storeId in this.storePickupPointAssociation) {
      if (pickupPoint.id !== this.storePickupPointAssociation[storeId].id) {
        console.log("Different pickup point exists for store");
        return {
          error: "Different pickup point exists for store"
        };
      }
    }

    let cartForPickupPoint = this.carts.find(
      cart => cart.pickupPointId === pickupPoint.id
    );

    if (cartForPickupPoint == null) {
      //Cart didn't exist. Need to create new one.
      this.createCartForPickupPoint(stock, pickupPoint, customizations);
    } else {
      //Check for item inside the received cart.
      let cartItemFromCart = this.getExistingItemIfAny(
        stock,
        pickupPoint,
        cartForPickupPoint,
        customizations
      );
      if (cartItemFromCart == null) {
        //Item was not found. Need to create new item in cart
        this.createNewItemForExistingCart(
          stock,
          pickupPoint,
          cartForPickupPoint,
          customizations
        );
      } else {
        cartItemFromCart.quantity = cartItemFromCart.quantity + 1;
        this.numberItemsPerPickupPoint[pickupPoint.id] =
          this.numberItemsPerPickupPoint[pickupPoint.id] + 1;
      }
    }

    this.storePickupPointAssociation[storeId] = pickupPoint;
  };

  removeStockFromCartForPickupPoint = (
    stock,
    pickupPoint,
    customizations = []
  ) => {
    let cartForPickupPoint = this.carts.find(
      cart => cart.pickupPointId === pickupPoint.id
    );

    if (cartForPickupPoint == null) {
      return;
    }

    let cartItemFromCart;

    if (
      this.canRemoveStockFromCartForPickupPointWithoutCustomizations(
        stock,
        pickupPoint
      )
    ) {
      cartItemFromCart = cartForPickupPoint.cartItems.find(
        cartItem => cartItem.stockId === stock.id
      );
    } else {
      cartItemFromCart = this.getExistingItemIfAny(
        stock,
        pickupPoint,
        cartForPickupPoint,
        customizations
      );
    }

    if (cartItemFromCart == null) {
      return;
    }

    if (cartItemFromCart.quantity > 1) {
      cartItemFromCart.quantity = cartItemFromCart.quantity - 1;
      this.numberItemsPerPickupPoint[pickupPoint.id] =
        this.numberItemsPerPickupPoint[pickupPoint.id] - 1;
    } else {
      cartForPickupPoint.cartItems.splice(
        cartForPickupPoint.cartItems.indexOf(cartItemFromCart),
        1
      );
      this.numberItemsPerPickupPoint[pickupPoint.id] =
        this.numberItemsPerPickupPoint[pickupPoint.id] - 1;
      if (cartForPickupPoint.cartItems.length === 0) {
        this.carts.splice(this.carts.indexOf(cartForPickupPoint), 1);
        console.log("Removing the last item from the cart", cartForPickupPoint);
        this.deleteSpecialInstructionsForCart(cartForPickupPoint);
        delete this.storePickupPointAssociation[pickupPoint.store.id];
        delete this.numberItemsPerPickupPoint[pickupPoint.id];
      }
    }
    // window.EventBus.trigger('CartUpdated',this.carts)
  };

  canRemoveStockFromCartForPickupPointWithoutCustomizations = (
    stock,
    pickupPoint
  ) => {
    try {
      let cartForPickupPoint = this.carts.find(
        cart => cart.pickupPointId === pickupPoint.id
      );
      let cartItems = cartForPickupPoint.cartItems;

      let elegibleRemovals = cartItems.filter(cartItem => {
        if (cartItem.stockId === stock.id) {
          return true;
        } else {
          return false;
        }
      });

      if (elegibleRemovals.length > 1) {
        return true;
      } else {
        return true;
      }
    } catch (error) {
      console.log("error in removing item", error);
    }
  };

  removePickupPointFromCart = (pickupPointId, storeId) => {
    let cartForPickupPoint = this.carts.find(
      cart => cart.pickupPointId === pickupPointId
    );
    this.carts.splice(this.carts.indexOf(cartForPickupPoint), 1);

    delete this.storePickupPointAssociation[storeId];
    delete this.numberItemsPerPickupPoint[pickupPointId];
    this.deleteSpecialInstructionsForCart(pickupPointId);
    // window.EventBus.trigger('CartUpdated',this.carts)
  };

  getTotalNumberOfItemsInCheckout = () => {
    let quantity = 0;
    for (let index = 0; index < this.carts.length; index++) {
      const cart = this.carts[index];
      for (let cartIndex = 0; cartIndex < cart.cartItems.length; cartIndex++) {
        const item = cart.cartItems[cartIndex];
        quantity = quantity + item.quantity;
      }
    }
    return quantity;
  };

  getTotalNumberOfItemsInCheckoutForPickupPoint = pickupPoint => {
    return this.numberItemsPerPickupPoint[pickupPoint.id];
  };

  getPickupPointInCartForStore = storeId => {
    return this.storePickupPointAssociation[storeId];
  };

  // eslint-disable-next-line
  moveItemsFromPickupPointToNewPickupPoint = (
    oldPickupPoint,
    newPickupPoint
  ) => {
    // eslint-disable-next-line
    this.carts.map(cart => {
      if (cart.pickupPointId === oldPickupPoint.id) {
        cart.pickupPointId = newPickupPoint.id;
      }
    });

    this.numberItemsPerPickupPoint[
      newPickupPoint.id
    ] = this.numberItemsPerPickupPoint[oldPickupPoint.id];
    this.numberItemsPerPickupPoint[oldPickupPoint.id] = 0;

    this.storePickupPointAssociation[oldPickupPoint.store.id] = newPickupPoint;
  };

  clearAllCarts = () => {
    this.carts = [];
    this.storePickupPointAssociation = {};
    this.numberItemsPerPickupPoint = {};
    this.specialInstructions = {}; //cartID and their corresponding special instructions
    this.estimatedTraficTime = {};
    this.checkOut = {};
    this.userDetail = {
      phone: null,
      mode: null
    };
    this.paymentToken = null;
    // window.EventBus.trigger('CartUpdated',this.carts)
  };

  getTotalPriceForItemsInCart = () => {};

  getNumberOfPickupPointsInCart = () => {
    return this.carts.length;
  };

  getCartForPickupPoint = pickupPointId => {
    return this.carts.find(cart => cart.pickupPointId === pickupPointId);
  };

  // Special Instruction related operations
  /**
   * add special instruction for a particular cart or update the exsisting ones
   * @argument {String} cartId - cartId to add the special instructions for,
   * @argument {String} specialInstructions - special instruction for the cart
   */
  addUpdateSpecialInstructions = (cartId, specialInstructions) => {
    this.specialInstructions[cartId] = specialInstructions;
  };

  /**
   * @argument {String} cartId - cartId to get special instructions for.
   * @returns {String} special instructions for the cart and if it doesn't exist then an empty string.
   */
  getSpecialInstructionsForCart = cartId => {
    if (
      this.specialInstructions[cartId] &&
      this.specialInstructions[cartId] !== undefined
    ) {
      return this.specialInstructions[cartId];
    }
    return "";
  };

  /**
   * @description removes the special instructions for a particular cart
   */
  deleteSpecialInstructionsForCart = cartId => {
    delete this.specialInstructions[cartId];
  };

  getSpecialInstructions = () => {
    return this.specialInstructions;
  };

  // Manage the estimated time to reach the traffic
  /**
   * @argument {String} pickupPointId   -  pickupPoint
   * @argument {Number} time            -  time to reach the pickup Point
   */
  updateEstimatedReachTime = (pickupPointId, time) => {
    this.estimatedTraficTime[pickupPointId] = time;
  };

  clearEstimatedTrafficTime = () => {
    this.estimatedTraficTime = {};
  };

  /**
   * @argument {String} pickupPointId - pickup point id to get the estimated traffic data for
   */
  getEstimatedTrafficTime = pickupPointId => {
    return this.estimatedTraficTime[pickupPointId];
  };

  //
  //
  // HELPER FUNCTIONS
  //
  //

  getExistingItemIfAny = (stock, pickupPoint, cart, customizations) => {
    // console.log("Customizations", customizations)
    let cartItemsFromCart = cart.cartItems.filter(
      cartItem => cartItem.stockId === stock.id
    );
    let matchingCartItem = cartItemsFromCart.find(cartItem => {
      // console.log("Customizations IDs --->", cartItem)
      return _.isEqual(cartItem.customizationIds, customizations);
      // return cartItem.customizationIds._equals(customizations)
    });
    if (matchingCartItem) {
      return matchingCartItem;
    } else {
      return null;
    }
  };

  createCartForPickupPoint = (stock, pickupPoint, customizations) => {
    let cartForPickupPoint = {
      pickupPointId: pickupPoint.id,
      cartItems: [
        {
          stockId: stock.id,
          quantity: 1,
          customizationIds: customizations
        }
      ]
    };
    console.log("Creating new cart for the store", cartForPickupPoint);
    this.carts.push(cartForPickupPoint);
    console.log("Updated cart", this.getCarts());
    this.numberItemsPerPickupPoint[pickupPoint.id] = 1;
  };

  createNewItemForExistingCart = (stock, pickupPoint, cart, customizations) => {
    let productStock = {
      stockId: stock.id,
      quantity: 1,
      customizationIds: customizations
    };
    this.numberItemsPerPickupPoint[pickupPoint.id] =
      this.numberItemsPerPickupPoint[pickupPoint.id] + 1;
    cart.cartItems.push(productStock);
  };
}

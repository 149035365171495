import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import classes from "./CheckoutCart.module.css";
import Paper from "@material-ui/core/Paper";
import TimerIcon from "@material-ui/icons/Timer";
import QunatityPicker from "../QuantityPicker/QuantityPicker";

class CheckoutCart extends PureComponent {
  onAdd = (item, store) => {
    const { onAddItem } = this.props;
    onAddItem(item, store);
  };

  onRemove = (item, store) => {
    const { onRemoveItem } = this.props;
    onRemoveItem(item, store);
  };

  render() {
    const { cart, showQtyPicker } = this.props;
    const cartSla = Math.floor(cart.cartSla / 60);
    return (
      <Paper elevation={0} className={classes.main_div}>
        <div className={classes.cart_store_div}>
          <p>{cart.pickupPoint.store.name}</p>
          <div className={classes.cart_sla_div}>
            <TimerIcon className={classes.timer_icon} />
            <p>
              {cartSla} {cartSla > 1 ? "Mins" : "Min"}{" "}
            </p>
          </div>
        </div>
        {cart.cartItems.edges.map(item => (
          <div key={item.node.id} className={classes.cart_item_div}>
            {!showQtyPicker && (
              <div className={classes.qty_indicator}>
                <p>{item.node.quantity} X</p>
              </div>
            )}
            <p className={classes.item_name_text}>
              {item.node.stock.sku.product.name}{" "}
              {item.node.stock.sku.quantity ? item.node.stock.sku.quantity : ""}
            </p>
            {showQtyPicker && (
              <div className={classes.qty_picker}>
                <QunatityPicker
                  item={item}
                  store={cart.pickupPoint}
                  onAdd={this.onAdd}
                  onRemove={this.onRemove}
                />
              </div>
            )}
            <p className={classes.item_name_qty}>
              <span>₹</span>
              {item.node.total.toFixed(2)}
            </p>
          </div>
        ))}
      </Paper>
    );
  }
}

CheckoutCart.propTypes = {
  onAddItem: PropTypes.func,
  onRemoveItem: PropTypes.func,
  cart: PropTypes.any.isRequired,
  showQtyPicker: PropTypes.bool.isRequired
};

CheckoutCart.defaultProps = {
  onAddItem: () => {},
  onRemoveItem: () => {}
};

export default CheckoutCart;

import ApolloClient, { InMemoryCache } from "apollo-boost";
import { serverUrl, authTokenKey } from "../utils/config";
import {generateHeaders, generateHeaderswithBasicAuthToken, generateEpochTimestamp, generateSignature} from '../utils/helpers';

const cache = new InMemoryCache();
const client = new ApolloClient({
  uri: serverUrl,
  request: operation => {
    const token = localStorage.getItem(authTokenKey);
    var vtimestamp = generateEpochTimestamp();
    operation.setContext({
      headers: {
        Authorization: `Token ${token}`,
        Timestamp: vtimestamp,
        Signature: generateSignature(vtimestamp, token)
      }
    });
  },
  cache
});

export default client;

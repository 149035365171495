import React, { Component } from "react";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./CouponInput.css";
import { Typography } from "@material-ui/core";

const layout = {
  default: [
    "1 2 3 4 5 6 7 8 9 0",
    "Q W E R T Y U I O P",
    "A S D F G H J K L ",
    "Z X C V B N M {backspace}"
  ]
};

class CouponInput extends Component {
  state = {
    layout: "default",
    input: ""
  };
  onKeyPress = button => {
    console.log("Button pressed", button);

    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{shift}" || button === "{lock}") this.handleShift();
  };
  handleShift = () => {
    let layout = this.state.layout;

    this.setState({
      layout: layout === "default" ? "shift" : "default"
    });
  };
  onChange = input => {
    this.props.onChange(input);
  };
  render() {
    const commonKeyboardOptions = {
      onChange: input => this.onChange(input),
      onKeyPress: button => this.onKeyPress(button),
      physicalKeyboardHighlight: true,
      syncInstanceInputs: true,
      mergeDisplay: true,
      debug: true,
      layout: layout
    };
    const { placeholder, title } = this.props;
    return (
      <div className="CouponInputMainDiv">
        <Typography
          style={{
            color: "#FFFFFF",
            fontSize: "30px",
            marginBottom: "20px",
            letterSpacing: "5px",
            textAlign: "center"
          }}
        >
          {title || ""}
        </Typography>
        <div style={{ width: "600px" }}>
          <input
            placeholder={placeholder || ""}
            className="InputBox"
            readOnly
            value={this.props.input}
          />
        </div>
        <div className="CouponInputKeyboardDiv">
          <Keyboard
            theme={"hg-theme-default myTheme1"}
            buttonTheme={"myTheme2b"}
            {...commonKeyboardOptions}
            layoutName={this.state.layout}
            display={{
              "{backspace}": "⌫"
            }}
          />
        </div>
      </div>
    );
  }
}

export default CouponInput;

import React, { PureComponent } from "react";
import "../../index.css";
import classes from "./Recommendation.module.css";
import RecommendedCard from "../RecommendedCard/RecommendedCard";
import client from "../../api/client";
import { GET_RECOMMENDATIONS_FOR_CHECKOUT } from "../../api/queries";

class Recommendation extends PureComponent {
  mounted = false;
  state = {
    isLoading: false,
    items: []
  };
  componentDidMount() {
    this.mounted = true;
    this.mounted && this.getRecommendations();
  }
  componentWillMount() {
    this.mounted = false;
  }
  componentDidUpdate(prevProps) {
    if (prevProps.checkout !== this.props.checkout) {
      this.getRecommendations();
    }
  }
  getRecommendations = () => {
    const { checkout, orderMode } = this.props;
    checkout &&
      client
        .query({
          query: GET_RECOMMENDATIONS_FOR_CHECKOUT(),
          //   variables: { userId: checkout.user.id },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          let data = res.data.checkoutRecommendation;
          data = data.filter(item => {
            if (orderMode !== "TABLE_SERVICE") {
              return orderMode && item.stock[orderMode.toLowerCase()];
            } else {
              return item.stock["dinein"];
            }
          });
          this.mounted &&
            this.setState({
              items: data
            });
          console.log("Data for the recommendations", data);
        });
  };
  render() {
    const { checkout, onAddItem } = this.props;
    const { items } = this.state;
    console.log("Recommendation data", checkout);
    if (items.length > 0) {
      return (
        <div className={classes.main_div}>
          <div className={classes.header_div}>
            <p>You may also like:</p>
          </div>
          <div className={classes.content_div}>
            {items.map(item => (
              <RecommendedCard
                key={item.id}
                item={item.stock}
                onAdd={onAddItem}
              />
            ))}
          </div>
        </div>
      );
    }
    return null;
  }
}

export default Recommendation;

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Modal from "@material-ui/core/Modal";
import CropDin from "@material-ui/icons/CropDin";
import Brightness1 from "@material-ui/icons/Brightness1";
import CartManager from "../../singletons/CartManager";
import Customization from "../Customization/Customization";
import Icon from "@material-ui/core/Icon";
import LocationOn from "@material-ui/icons/LocationOn";
import { Button } from "@material-ui/core";
import { Image } from "cloudinary-react";
import { Transformation } from "cloudinary-core";

let boxShadow = "box-shadow: 0 2px 20px 0 rgba(0,0,0,0.9)";
let widthscreem = window.screen.width;
const styles = {
  card: {
    width: widthscreem > 1000 ? 240 : 220,
    position: "relative",
    boxShadow: boxShadow,
    borderRadius: "10px",
    border: "1px solid rgba(0,0,0,0.2)"
  },
  cardPressed: {
    boxShadow: "none",
    width: widthscreem > 1000 ? 240 : 220,
    position: "relative",
    borderRadius: "10px",
    border: "1px solid rgba(0,0,0,0.2)"
  },
  media: {
    objectFit: "contain",
    width: "100%",
    minHeight: "120px",
    marginBottom: 0,
    position: "relative",
    backgroundColor: "white"
  },
  actions: {
    background: "rgba(255,255,255,0.4)",
    padding: "4px"
  },
  itemPrice: {
    color: "#333333",
    fontSize: 18,
    fontFamily: "Segoe UI",
    fontWeight: "600",
    flex: 3,
    textAlign: "right"
  },
  dropImage: {
    display: "none",
    top: 0,
    left: 0,
    height: "200px",
    width: "200px",
    objectFit: "contain"
  },
  productDetailDiv: {
    padding: "4px",
    backgroundColor: "#D8D8D8;",
    position: "absolute",
    bottom: "0",
    left: "0",
    flex: 1,
    width: "100%",
    boxSizing: "border-box",
    minHeight: "20px",
    opacity: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  logoImage: {
    position: "absolute",
    top: 8,
    zIndex: 16,
    right: 8,
    height: 40,
    width: 40,
    opacity: 1,
    objectFit: "contain",
    backgroundColor: "transparent"
  }
};

class TrendingProductCard extends React.PureComponent {
  constructor(props) {
    super(props);
    let customizationConfiguration = this.generateInitialCustomizationConfiguration(
      props.item
    );
    this.state = {
      expanded: false,
      cart: null,
      showCustomization: false,
      customizationConfiguration: customizationConfiguration,
      addingToCart: false,
      xCoord: 0,
      yCoord: 0
    };
  }

  generateInitialCustomizationConfiguration = stock => {
    let customizationConfiguration = {};
    if (stock.customizations.edges.length > 0) {
      stock.customizations.edges.forEach(customization => {
        customizationConfiguration[
          customization.node.id
        ] = customization.node.children.edges
          .filter(cust => {
            if (cust.node.defaultSelected === 1) {
              return true;
            }
            return false;
          })
          .map(defaultValue => defaultValue.node.id);
      });
    }
    return customizationConfiguration;
  };

  _addCustomizedProduct = (customizations, stock, e = null) => {
    e && e.stopPropagation();
    let cartManager = new CartManager();
    // const product = stock.sku.product;
    const pickupPoint = stock.store.pickupPoints.edges[0].node;
    let customizationValues = [];
    Object.keys(customizations).forEach(customizationId => {
      customizationValues = customizationValues.concat(
        customizations[customizationId]
      );
    });
    cartManager.addStockToCartForPickupPoint(
      stock,
      pickupPoint,
      pickupPoint.store.maxCartItems,
      customizationValues
    );
    this._closeCustomization();
    const { left, top } = this.image.getBoundingClientRect();
    this.props.updateCheckOut(this.props.item.sku.product.image, {
      x: left,
      y: top
    });
  };
  _addStockToCart = async () => {
    console.log("Clicked on the item");
    let cartManager = new CartManager();
    const stock = this.props.item;
    const pickupPoint = stock.store.pickupPoints.edges[0].node;
    if (
      cartManager.getTotalNumberOfItemsInCheckoutForPickupPoint(pickupPoint) >=
      pickupPoint.store.maxCartItems
    ) {
      alert(
        "Max items reached",
        "only" +
          pickupPoint.store.maxCartItems +
          " item(s) can be added to your cart for this store."
      );
    } else {
      if (stock.customizations.edges.length > 0) {
        this.setState({
          expanded: true
        });
      } else {
        cartManager.addStockToCartForPickupPoint(
          stock,
          pickupPoint,
          pickupPoint.store.maxCartItems
        );
        const { left, top } = this.image.getBoundingClientRect();
        this.props.updateCheckOut(stock.sku.product.image, { x: left, y: top });
      }
    }
  };

  _closeCustomization = (e = null) => {
    this.setState({ expanded: false }, () => {
      console.log("Calling close customization", this.state.expanded);
    });
    e && e.stopPropagation();
  };

  render() {
    const { classes, item } = this.props;
    const publicId = item.store.image
      ? item.store.image.replace(
          "https://res.cloudinary.com/grabbngo/image/upload/",
          ""
        )
      : "";
    const productId = item.sku.product.image
      ? item.sku.product.image.replace(
          "https://res.cloudinary.com/grabbngo/image/upload/",
          ""
        )
      : "";
    if (item.store.isOpen) {
      return (
        <div ref={img => (this.image = img)}>
          <Button
            onClick={this._addStockToCart}
            style={{ textAlign: "left" }}
            className="trend_card_ph"
          >
            <Card
              className="trend_card"
              style={this.state.addingToCart ? styles.cardPressed : styles.card}
              raised={!this.state.addingToCart}
            >
              <Image
                cloudName="grabbngo"
                quality={"auto"}
                fetchFormat={"auto"}
                dpr={"auto"}
                publicId={publicId}
                src={item.store.image}
                alt={`${item.name}`}
                className={classes.logoImage}
              >
                <Transformation
                  width="40"
                  height="40"
                  gravity="face"
                  radius="max"
                  crop="crop"
                />
              </Image>
              {/* <img
                src={item.store.image}
  
  
              /> */}
              <div style={{ position: "absolute", top: 4, left: 4, zIndex: 4 }}>
                <div style={{ position: "relative" }}>
                  <CropDin
                    style={{
                      position: "relative",
                      fontSize: "24",
                      color:
                        item.sku.product.contains === "NONVEG"
                          ? "red"
                          : item.sku.product.contains === "VEG"
                          ? "green"
                          : "#FFEB3B"
                    }}
                  />
                  <Brightness1
                    style={{
                      position: "absolute",
                      top: 6,
                      left: 6,
                      fontSize: 12,
                      color:
                        item.sku.product.contains === "NONVEG"
                          ? "red"
                          : item.sku.product.contains === "VEG"
                          ? "green"
                          : "#FFEB3B "
                    }}
                  />
                </div>
              </div>
              <div style={{ position: "relative" }} className="card_price">
                <Image
                  cloudName="grabbngo"
                  quality={"auto"}
                  fetchFormat={"auto"}
                  crop={"fill"}
                  dpr={"auto"}
                  publicId={productId}
                  src={item.sku.product.image}
                  alt={`${item.sku.name}`}
                  className={classes.media}
                >
                  <Transformation effect="sharpen" flags={"progressive"} />
                </Image>
                <div className={classes.productDetailDiv}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center"
                    }}
                  >
                    <Icon style={{ color: "grey", fontSize: 16 }}>
                      <LocationOn style={{ fontSize: 16 }} />
                    </Icon>
                    <p
                      className="card_location"
                      style={{
                        textAlign: "left",
                        color: "#555555",
                        fontSize: 14
                      }}
                    >
                      {item.store.pickupPoints.edges[0].node.name}
                    </p>
                  </div>
                  <p
                    className={classes.itemPrice + " itemPrice"}
                  >{`₹ ${item.salePrice}`}</p>
                </div>
              </div>
              <div className={classes.actions}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center"
                  }}
                >
                  <p
                    className="card_title"
                    style={{
                      fontSize: 18,
                      fontWeight: "600",
                      fontFamily: "Open Sans",
                      color: "#333333",
                      width: "80%"
                    }}
                  >
                    {`${item.sku.name} \n ${
                      item.sku.quantity ? item.sku.quantity.toLowerCase() : ""
                    }`}
                  </p>
                  <p
                    style={{
                      fontSize: 12,
                      fontWeight: "400",
                      fontFamily: "Open Sans",
                      color: "#333333",
                      width: "20%"
                    }}
                  >
                    {Math.round(item.stockSla / 60) > 0
                      ? `${Math.round(item.stockSla / 60)} Min`
                      : ""}
                  </p>
                </div>
                {item.sku.description && (
                  <p
                    variant="caption"
                    className="card_ingredient"
                    style={{ fontSize: 14, letterSpacing: 0 }}
                  >
                    {item.sku.description}
                  </p>
                )}
              </div>

              {this.state.expanded && (
                <Modal
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                  open={this.state.expanded}
                  onClose={this._closeCustomization}
                  disableAutoFocus
                >
                  <Customization
                    item={item}
                    onAddToCart={this._addCustomizedProduct}
                    handleClose={this._closeCustomization}
                    customizationConfiguration={
                      this.state.customizationConfiguration
                    }
                  />
                </Modal>
              )}
            </Card>
          </Button>
        </div>
      );
    }
    return "";
  }
}

TrendingProductCard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TrendingProductCard);

import base64 from "base-64";
import crypto from 'crypto';
import {
  loginUrl,
  authTokenKey,
  loginModeKey,
  paymentStatus,
  // paymentMode,
  lastDataFetchedKey,
  storesDataKey,
  popularFoodKey,
  pollingInterval,
  userProfileKey,
  regionsKey,
  AEP_SETTING_USER_KEY,
  orderMode,
  REGION_ID_LOCAL,
  ORDER_MODES,
  REGION_CONFIG_SETTING
} from "./config";
import GraphQLRequest from "../singletons/GraphQLRequests";
import CartManager from "../singletons/CartManager";

/**
 * Login to the server
 * @param {String} username
 * @param {String} password
 */
export const submitCredentials = async (username, password) => {
  var vtimestamp = generateEpochTimestamp();
  let authToken = base64.encode(username + ":" + password);
  let headers = new Headers({
    Authorization: `Basic ` + base64.encode(username + ":" + password),
    Timestamp: vtimestamp,
    Signature: generateSignature(vtimestamp, authToken)                

  });
  try {
    let response = await fetch(loginUrl, {
      method: "POST",
      headers: headers
    });
    if (response.status === 200) {
      let json = await response.json();
      localStorage.setItem(authTokenKey, json.token);
      localStorage.setItem("storeInformation", JSON.stringify(json));
      await getUserProfile()
        .then(res => {
          if (res && res.userProfile) {
            localStorage.setItem(userProfileKey, JSON.stringify(res));
            localStorage.setItem(
              regionsKey,
              JSON.stringify(res.userProfile.user.regions.edges[0].node.name)
            );
            localStorage.setItem(
              REGION_ID_LOCAL,
              JSON.stringify(res.userProfile.user.regions.edges[0].node.id)
            );
            getUserRegion(res.userProfile.user.regions.edges[0].node.name).then(
              res => {
                console.log("USer region", res.regions.edges[0].node.aep);
                localStorage.setItem(
                  AEP_SETTING_USER_KEY,
                  res.regions.edges[0].node.aep
                );
              }
            );
          }
        })
        .catch(err => {
          throw err;
        });
      if ("store" in json) {
        localStorage.setItem(loginModeKey, "store");
      } else {
        localStorage.setItem(loginModeKey, "merchant");
      }
      await localStorage.getItem(loginModeKey);
      return response;
    } else {
      console.log(response);
      throw new Error("Incorrect username password");
    }
  } catch (error) {
    throw error;
  }
};

// getUserProfile
export const getUserProfile = async () => {
  try {
    let graphQLRequest = new GraphQLRequest();
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "getUserProfile"
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getUserRegion = async name => {
  try {
    let graphQLRequest = new GraphQLRequest();
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "getUserRegion",
      { name }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @return gets the stores available for the kiosk
 */
export const getStoresAround = async () => {
  let graphQLRequest = new GraphQLRequest();

  const regionId = localStorage.getItem(REGION_ID_LOCAL);
  // const latitude = 12.919853;
  // const businessDomain =
  //   "QnVzaW5lc3NEb21haW46N2M3NDgxMmQtZDFlNS00YzYzLWEyYTEtY2QzZDY3OGVhZmE2";
  // const longitude = 77.592178;
  try {
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "pickupPointsForRegion",
      { regionId }
    );
    console.log("response for stores", response);
    if (response.error) {
      throw new Error("Unable to fetch the stores");
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

export const getPopularFood = async mode => {
  let graphQLRequest = new GraphQLRequest();
  let ordermode = mode;
  console.log("getting Trending for the OrderMode", ordermode);
  let usedQuery =
    ordermode === ORDER_MODES.dineIn
      ? "getPopularDineInFood"
      : ordermode === "DELIVERY"
      ? "getPopularDeliveryFood"
      : "getPopularTakeawayFood";
  console.log("USed QUERy", ordermode, usedQuery);
  try {
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      ordermode === ORDER_MODES.dineIn
        ? "getPopularDineInFood"
        : ordermode === ORDER_MODES.delivery
        ? "getPopularDeliveryFood"
        : "getPopularTakeawayFood"
    );
    console.log("Popular Food", response);
    if (response.error) {
      throw new Error("Unable to fetch the popular food items");
    } else {
      return response;
    }
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {String} storeId - Get menus for the selected store.
 * @returns Three level categories for the selected store
 */
export const getMenuForStore = async storeId => {
  let graphQLRequest = new GraphQLRequest();
  try {
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "threeLeveledCategoriesForStores",
      {
        storeId: storeId
      }
    );
    if (response.error === null) {
      return response.data;
    } else {
      throw new Error(response.error);
    }
  } catch (error) {
    throw error;
  }
};

export const getSubCatForCategory = async categoryId => {
  let graphQLRequest = new GraphQLRequest();
  try {
    let childrenCategoryResponse = await graphQLRequest.getResponseFromPredefinedQuery(
      "fetchChildrenForCategory",
      {
        categoryId: categoryId
      }
    );
    if (childrenCategoryResponse.error === null) {
      return childrenCategoryResponse.data;
    } else {
      throw new Error("Can not fetch the data for the Category");
    }
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {String} categoryId - category
 * @param {String} storeId - Store id
 * @return the product's sku for the selected category
 */
export const getProductSKUForCategory = async (categoryId, storeId) => {
  let graphQLRequest = new GraphQLRequest();
  const body = {
    categoryId: categoryId,
    storeId: storeId,
    after: ""
  };
  console.log("getProductSKUForCategory -- body", body);
  let cartManager = new CartManager();
  let ordermode = cartManager.getUserDetail().mode;
  try {
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      ordermode === orderMode.dineIn
        ? "getProductSKUsStocksForCategoryDineIn"
        : "getProductSKUsStocksForCategoryTakeAway",
      body
    );
    console.log("Sku for the category", response);
    if (response.error === null) {
      return response.data;
    } else {
      throw new Error("Can not fetch the data for the category");
    }
  } catch (error) {
    throw error;
  }
};

/**
 *
 * @param {Object} carts -  Carts to get the checkout for
 * @return the updated chckout from the server
 */
export const getCheckOutFromServer = async (
  carts,
  orderMode,
  phone = "",
  availAEP = false,
  code,
) => {
  let graphQLRequest = new GraphQLRequest();
  let codeType = availAEP ? "AEP" : "";
  const regionConfig = JSON.parse(localStorage.getItem(REGION_CONFIG_SETTING));
  console.log("region config", regionConfig);
  console.log("CODE--->", code)
  let parameters = {
    input: {
      sourceLat: 12.111111,
      sourceLng: 12.111111,
      destLat: 12.111111,
      destLng: 12.111111,
      carts: carts,
      code: code,
      phone: phone,
      codeType: codeType,
      orderMode: orderMode,
      region: regionConfig && regionConfig.id
    }
  };
  console.log("Input Object", JSON.stringify(parameters));
  try {
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "updateCheckout",
      parameters
    );
    if (response.error === null) {
      console.log("Ccheck out from the server", response.data);
      return response.data;
    } else {
      throw new Error("Unable to get data from server");
    }
  } catch (error) {
    throw error;
  }
};
//
// export const checkForAuthToken = () => {
// }

/**
 *
 * @param {String} token - Checkout Token
 * @param {Number} amount -  checkout amount
 * @param {String} mode  - mode of payment
 */

export const placeOrder = async (
  token,
  amount,
  mode,
  phone,
  orderMode,
  comments
) => {
  let graphQLRequest = new GraphQLRequest();
  const userProfileData = JSON.parse(localStorage.getItem(userProfileKey));
  const user = userProfileData.userProfile;
  const regionConfig = JSON.parse(localStorage.getItem(REGION_CONFIG_SETTING));
  const comment =
    regionConfig.city === "Bengaluru"
      ? comments
      : `${user.firstName} ${user.lastName}`;
  console.log("user location ------>", comment, user);
  const input = {
    status: paymentStatus.init,
    token: token,
    amount: amount,
    mode: mode,
    phone: phone,
    orderMode: orderMode,
    comments: comment
  };
  try {
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "initializePayment",
      { input }
    );
    console.log("Payment init", input);
    if (response.error !== null) {
      throw new Error("Failed to initialize the payment. Please try again.");
    } else {
      return response.data;
    }
  } catch (error) {
    throw error;
  }
};

export const checkPaymentStatus = async (mode, token, amount) => {
  let graphQLRequest = new GraphQLRequest();
  const userProfileData = JSON.parse(localStorage.getItem(userProfileKey));
  const user = userProfileData.userProfile.user;
  const comment = `${user.firstName} ${user.lastName}`;
  const input = {
    status: paymentStatus.success,
    mode: mode,
    comments: comment,
    amount,
    token
  };
  try {
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "updatePaymentSucces",
      { input }
    );
    if (response.error !== null) {
      throw new Error(response.error);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const cancelPayment = async (mode, token, amount) => {
  let graphQLRequest = new GraphQLRequest();
  const input = {
    status: paymentStatus.cancel,
    mode: mode,
    amount,
    token
  };
  try {
    console.log("Payemnt status ----> ", input);
    let response = await graphQLRequest.getResponseFromPredefinedQuery(
      "updatePaymentSucces",
      { input }
    );
    if (response.error !== null) {
      throw new Error(response.error);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

// export const checkPaymentStatus = (mode, token) => {
//   let graphQLRequest = new GraphQLRequest();
//   const input = {
//     status: paymentStatus.success,
//     mode: paymentMode.pineLabs
//   };
// };

/**
 * check for data sanity
 * @returns {Boolean} data is sane or not
 */
export const checkForDataSanity = () => {
  const timeNow = Date.now();
  const lastDataFetched = JSON.parse(localStorage.getItem(lastDataFetchedKey));
  const timeDiffSec = Math.floor((timeNow - lastDataFetched) / 1000);
  const storesData = localStorage.getItem(storesDataKey);
  const trendingData = localStorage.getItem(popularFoodKey);
  if (!storesData || !trendingData || timeDiffSec >= pollingInterval) {
    return false;
  }
  return true;
};

/**
 *@description Calculate the checkout's extra data such as CGST,SGST, discounts and charges
 */
export const calculateCheckoutExtra = checkout => {
  let checkoutExtras = {};
  checkoutExtras["Taxes and Charges"] = 0;
  let extras = JSON.parse(checkout.extra);
  Object.entries(extras).forEach(([key, value]) => {
    value.forEach(item => {
      checkoutExtras[item.label] = item.amount;
    });
  });
  extras = checkout.carts.edges;
  extras.forEach(cart => {
    let cartExtra =
      cart && cart.node && cart.node.extra ? cart.node.extra : "{}";
    cartExtra = JSON.parse(cartExtra);
    Object.entries(cartExtra).forEach(([key, value]) => {
      value.forEach(val => {
        let amount = checkoutExtras[val.label] || 0;
        if (key === "charge") {
          checkoutExtras[["Taxes and Charges"]] =
            checkoutExtras[["Taxes and Charges"]] + val.amount;
        }
        checkoutExtras[val.label] = amount + val.amount;
      });
    });
  });
  return checkoutExtras;
};
/**
 * Calculate MD5 hash for the given data.
 * @param {object} data - The data to be hashed.
 * @returns {string} - The MD5 hash.
 */
export const calculateMD5 = (data) => {
  const base64Data = Buffer.from(JSON.stringify(data), 'utf-8').toString('base64');
  const hash = crypto.createHash('md5');
  hash.update(base64Data);
  return hash.digest('hex');
};

export const generateEpochTimestamp = () => {
  var currentTimestamp = Math.floor(Date.now() / 1000);
  return currentTimestamp;
};

export  const generateSignature = (timestamp, token) => {
  var sampleString = String(timestamp);
  var sampleStringBytes = new TextEncoder().encode(sampleString);
  var base64Bytes = btoa(String.fromCharCode.apply(null, sampleStringBytes));
  var tokenNew = token + "-" + base64Bytes;

  var sampleTokenString = String(tokenNew);
  var sampleTokenStringBytes = new TextEncoder().encode(sampleTokenString);
  var base64TokenBytes = btoa(String.fromCharCode.apply(null, sampleTokenStringBytes));
  return base64TokenBytes;
};

export const generateHeaders = () =>{
  let storeInformation = JSON.parse(localStorage.getItem("storeInformation"));
  let authToken = storeInformation.token;
  
  
  // const checksum = calculateMD5(payload);
  // console.log("MD5 Checksum:", checksum);

  const authtimestamp = generateEpochTimestamp();
  console.log("Epoch Timestamp:", authtimestamp);

  const authsignature = generateSignature(authtimestamp,authToken);
  console.log("Signature:", authsignature);
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Token " + authToken);
  myHeaders.append("Content-Type",  'application/json');
  // myHeaders.append("Checksum", checksum);
  myHeaders.append("Timestamp",authtimestamp);
  myHeaders.append("Signature",authsignature);
  console.log("HEADER",myHeaders);
  return myHeaders;
};

export const generateHeaderswithBasicAuthToken = (ntype, token) =>{
  // let storeInformation = JSON.parse(localStorage.getItem("storeInformation"));
  let authToken = token;
  
  
  // const checksum = calculateMD5(payload);
  // console.log("MD5 Checksum:", checksum);

  const authtimestamp = generateEpochTimestamp();
  console.log("Epoch Timestamp:", authtimestamp);

  const authsignature = generateSignature(authtimestamp,authToken);
  console.log("Signature:", authsignature);
  var myHeaders = new Headers();
  if (ntype == 'Basic'){
      myHeaders.append("Authorization", "Basic " + authToken);
      }
  if (ntype == 'Token'){
      myHeaders.append("Authorization", "Token " + authToken);
      }
  myHeaders.append("Content-Type",  'application/json');
  // myHeaders.append("Checksum", checksum);
  myHeaders.append("Timestamp",authtimestamp);
  myHeaders.append("Signature",authsignature);
  console.log("HEADER",myHeaders);
  return myHeaders;
};
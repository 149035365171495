import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardContent,
  InputBase,
  Button,
  Typography,
} from "@material-ui/core";
import classes from "./ApplyCode.module.css";
import CouponInput from "../CouponInput/CouponInput.js";
import {
  ExpansionPanelSummary,
  ExpansionPanel,
  ExpansionPanelDetails
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ApplyCode = ({
  code = "",
  codeText = "",
  onApplyCouponCode = () => {},
  ...props
}) => {
  const [couponCode, setCouponCode] = useState(code);
  const [showCodeInput, setShowCodeInput] = useState(false);

  const onCouponCodeChange = input => {
    //console.log('onCouponCodeChange', e.currentTarget.value)
    //this.setState({
     // couponCode: input
    //});
    setCouponCode(input)

    //let text = e.currentTarget.value;
    //text = text.toUpperCase();
    //setCouponCode(text);
  };

  const onApplyCode = () => {
    onApplyCouponCode(couponCode);
  };

  const onClearCode = () => {
    setCouponCode("");
    onApplyCouponCode("");
  };

  const couponncodeClick = () =>{
    setCouponCode(true)
  }

  console.log("Code for aoupin", code);

  return (
    <Card className={classes.main}>
      <CardHeader subheader="Apply coupon"></CardHeader>
      <CardContent>
        <div className={classes.container_div} >
        <ExpansionPanel>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className={classes.charges_div}>
                  <p className={classes.charge_amount}>
                   APPLY COUPON CODE
                  </p>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
              
              
         {/*  <Button
           variant="contained"
           color="primary"
           type="submit"
           disableElevation
           className={classes.apply_button}
           aria-label="search"
           
            //value={couponCode}
            onClick={couponncodeClick}
            //onChange={onCouponCodeChange}
            //className={classes.input}
            //placeholder="enter coupon code..."
            //inputProps={{ "aria-label": "search google maps" }}
          >
            
            </Button>
         */}
          {//(showCodeInput == true) && (
          <CouponInput
                  title={"Coupon Code"}
                  placeholder="Enter Coupon Code"
                  input={couponCode}
                  onChange={onCouponCodeChange}
          />
          //)
          }
          {(code.length === 0 || code !== couponCode) && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              className={classes.apply_button}
              aria-label="search"
              onClick={onApplyCode}
            >
              Apply
            </Button>
          )}
          {code.length > 0 && code === couponCode && (
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disableElevation
              className={classes.apply_button}
              aria-label="search"
              onClick={onClearCode}
            >
              Clear
            </Button>
          )}
          </ExpansionPanelDetails>
            </ExpansionPanel> 
        </div>
        {codeText && codeText.length > 0 && <Typography>{codeText}</Typography>}
      </CardContent>
    </Card>
  );
};

export default ApplyCode;

import { GraphQLClient } from "graphql-request";
import queries from "./queries";
import { authTokenKey, serverUrl } from "../../utils/config";
import {generateHeaders, generateHeaderswithBasicAuthToken, generateEpochTimestamp, generateSignature} from '../../utils/helpers';

let instance = null;
export default class GraphQLRequests {
  constructor() {
    if (!instance) {
      instance = this;
      this.client = null;
    }

    return instance;
  }

  getResponseFromPredefinedQuery = async (
    queryName,
    parameters = {},
    token = null
  ) => {
    let url = serverUrl;
    if (token !== null) {
      try {
        var vtimestamp = generateEpochTimestamp();
        let tempClient = new GraphQLClient(url, {
          headers: {
            Authorization: `Token ${token}`,
            Timestamp: vtimestamp,
            Signature: generateSignature(vtimestamp, token)            
          }
        });
        let response = await tempClient.request(queries[queryName], parameters);
        return { data: response, error: null };
      } catch (error) {
        return { data: null, error: error };
      }
    } else {
      try {
        if (this.client == null) {
          let authToken = await localStorage.getItem(authTokenKey);
          this.authToken = authToken;
          var vtimestamp = generateEpochTimestamp();
          this.client = new GraphQLClient(url, {
            headers: {
              Authorization: `Token ${authToken}`,
              Timestamp: vtimestamp,
              Signature: generateSignature(vtimestamp, authToken)                
            }
          });
        }
        let response = await this.client.request(
          queries[queryName],
          parameters
        );
        return { data: response, error: null };
      } catch (error) {
        return { data: null, error: error };
      }
    }
  };

  getResponseFromCustomQuery = async (query, parameters = {}, token = null) => {
    let url = serverUrl;
    if (token !== null) {
      try {
        var vtimestamp = generateEpochTimestamp();
        let tempClient = new GraphQLClient(url, {
          headers: {
            Authorization: `Token ${token}`,
            Timestamp: vtimestamp,
            Signature: generateSignature(vtimestamp, token)              
          }
        });
        let response = await tempClient.request(query, parameters);
        return { data: response, error: null };
      } catch (error) {
        return { data: null, error: error };
      }
    } else {
      try {
        if (this.client == null) {
          let authToken = await localStorage.getItem(authTokenKey);
          this.authToken = authToken;
          var vtimestamp = generateEpochTimestamp();
          this.client = new GraphQLClient(url, {
            headers: {
              Authorization: `Token ${authToken}`,
              Timestamp: vtimestamp,
              Signature: generateSignature(vtimestamp, authToken)                
            }
          });
        }
        let response = await this.client.request(query, parameters);
        return { data: response, error: null };
      } catch (error) {
        return { data: null, error: error };
      }
    }
  };

  getAuthToken = () => {
    if (this.authToken) {
      return this.authToken;
    } else {
      return null;
    }
  };

  clearAuthToken = async () => {
    this.authToken = null;
    this.client = null;
    await localStorage.removeItem(authTokenKey);
  };
}

import { gql } from "apollo-boost";

export const CANCEL_PINELABS_TRANSACTION = () => {
  return gql`
    mutation cancelPaymentPinelabs($input: CancelPaymentInput!) {
      cancelPaymentPinelabs(input: $input) {
        success
      }
    }
  `;
};

export const UPDATE_CHECKOUT_FEEDBACK = () => {
  return gql`
    mutation updateFeedback($input: FeedbackInput!) {
      updateFeedback(input: $input) {
        success
      }
    }
  `;
};

import React, { Component } from "react";
import Typography from "@material-ui/core/Typography";
import countryCodes from "../../utils/countrycodes.json";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
import "./UserDetailScreen.css";
import CartManager from "../../singletons/CartManager";
import BottomPanel from "../../components/BottomPanel/BottomPanel";
import { Select, withStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import CouponInput from "../../components/CouponInput/CouponInput.js";
import Modal from "@material-ui/core/Modal";
import Lottie from "react-lottie";
import LoadingFood from "../../animationData/rotate.json";
import GrabbnGoLogo from "../../components/GrabbnGoLogo/GrabbnGoLogo";
import { getCheckOutFromServer } from "../../utils/helpers.js";
import {
  codeType,
  codeTexts,
  AEP_SETTING_USER_KEY,
  REGION_CONFIG_SETTING
} from "../../utils/config.js";

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingFood,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const layout = {
  default: ["1 2 3", "4 5 6", "7 8 9", " 0 {bksp}"]
};

const StylesSelect = withStyles({
  select: {
    fontSize: "22px"
  }
})(Select);

class UserDetailScreen extends Component {
  state = {
    layoutName: "default",
    input: "",
    value: "+91",
    checkout: null,
    itemNo: 0,
    couponCode: "",
    checkedAep: false,
    optedForAep: false,
    verifiedAep: false,
    showLoading: false,
    aepEnabled: false,
    ShowAlertDialog: false,
    kioskShouldShowHeader: true
  };

  onCouponCodeChange = input => {
    this.setState({
      couponCode: input
    });
  };

  componentDidMount() {
    const cartManager = new CartManager();
    const { phone } = cartManager.getUserDetail();
    const region = JSON.parse(localStorage.getItem(REGION_CONFIG_SETTING));
    let kioskShouldShowHeader = true;
    console.log("Region ---->", region);
    if (region.hasOwnProperty("kioskShouldShowHeader")) {
      kioskShouldShowHeader = region.kioskShouldShowHeader;
    }
    // alert(mode);
    const aepSetting = localStorage.getItem(AEP_SETTING_USER_KEY);
    this.setState({
      aepEnabled: aepSetting,
      input: phone ? phone.substring(phone.length - 10) : "",
      kioskShouldShowHeader: kioskShouldShowHeader
    });
    const checkout = cartManager.getCheckOut();
    let items = 0;
    if (checkout.carts && checkout.carts.edges) {
      for (let cart of checkout.carts.edges) {
        for (let item of cart.node.cartItems.edges) {
          items = items + item.node.quantity;
        }
      }
      // TO-DO: Convert above part into a stand alone function
      this.setState({ itemNo: items });
    }
    this.setState({ checkout });
  }

  onChange = input => {
    this.setState({
      input: input
    });
  };

  /**
   * Handles the react-screen-keyboard
   */
  onKeyPress = button => {
    /**
     * If you want to handle the shift and caps lock buttons
     */
    if (button === "{enter}") this.onHandleSubmit();
  };

  /**
   * @description Submits the user details to server w/ or w/o AEP
   */
  onHandleSubmit = () => {
    if (this.state.value === "+91" && this.state.input.length < 10) {
      this.setState({
        ShowAlertDialog: true,
        alertTitle: "Error",
        alertMsg: "Please enter a valid Phone number."
      });
    } else {
      this.setState({ showLoading: true }, () => {
        const cartManager = new CartManager();
        const carts = cartManager.getCarts();
        const phone = `${this.state.value}${this.state.input}`;
        cartManager.updateUserDetail(phone);
        const { mode: orderMode } = cartManager.getUserDetail();
        cartManager.updateAepDetails(
          this.state.checkedAep,
          this.state.couponCode
        );
        getCheckOutFromServer(
          carts,
          orderMode,
          phone,
          this.state.checkedAep,
          this.state.couponCode
        )
          .then(res => {
            const checkout = res.updateCheckout.checkout;
            if (
              (checkout.codeType =
                codeType.aep && checkout.codeText === codeTexts.showAep)
            ) {
              this.setState({
                checkedAep: true,
                optedForAep: true,
                verifiedAep: false,
                showLoading: false
              });
            } else {
              cartManager.updateCheckOutFromServer(checkout);
              this.setState({
                showLoading: false,
                checkedAep: true,
                optedForAep: false,
                verifiedAep: true
              });
              this.props.history.push("/orderReviewScreen");
            }
          })
          .catch(err => {
            this.setState({
              ShowAlertDialog: true,
              alertTitle: "Error",
              alertMsg:
                "AEP verification failed. Please use the valid AEP number"
            });
          });
      });
    }
  };

  /**
   * @description Country code formatting
   */
  _formatTheValue = value => {
    return value;
  };

  /**
   * @description Handle the country code selection
   */
  handleCountrySelection = e => {
    console.log("selected country", e.target.value);
    this.setState({ value: e.target.value });
  };

  /**
   * @description handles the phone number input
   */
  onChangeInput = event => {
    let input = event.target.value;
    this.setState({
      input: input
    });
  };

  /**
   * Selects the country code
   */
  selectCountry = val => {
    this.setState({ value: val });
  };

  /**
   * Handles the AEP discount checkbox
   */
  _onAepCheck = () => {
    this.setState(prevState => ({
      checkedAep: !prevState.checkedAep
    }));
  };

  /**
   * Handle submit press in AEP modal
   */
  _onAepSubmit = () => {
    if (this.state.couponCode.length !== 10) {
      this.setState({
        ShowAlertDialog: true,
        alertTitle: "Error",
        alertMsg: "Please enter a valid 10 digit AEP number."
      });
    } else {
      this.setState({
        checkedAep: true,
        ShowAlertDialog: false,
        optedForAep: false
      });
    }
  };

  _onAepCancel = () => {
    this.setState({
      couponCode: "",
      checkedAep: false,
      optedForAep: false
    });
  };
  handleAlertClose = () => {
    this.setState({
      ShowAlertDialog: false,
      alertMsg: "",
      alertTitle: ""
    });
  };
  render() {
    const { kioskShouldShowHeader } = this.state;
    if (!this.state.showLoading) {
      return (
        <div className="userDetailBackground">
          <div style={{ position: "absolute", top: 0, left: 0, width: "100%" }}>
            <GrabbnGoLogo showLogo={kioskShouldShowHeader} />
          </div>
          <Typography
            style={{
              textAlign: "center",
              color: "#FFFFFF",
              fontFamily: "Open Sans",
              fontSize: "50px",
              lineHeight: "40px",
              marginBottom: "40px"
            }}
          >
            Your mobile number is your token
          </Typography>
          <Typography
            style={{
              textAlign: "center",
              color: "#FFFFFF",
              fontFamily: "Open Sans",
              fontSize: "50px",
              lineHeight: "40px",
              marginBottom: "80px"
            }}
          >
            Your phone is the buzzer
          </Typography>

          <div className="userDetailScreen">
            <Typography
              style={{
                color: "#FFFFFF",
                fontFamily: "Open Sans",
                fontSize: "30px",
                lineHeight: "40px",
                marginBottom: "40px"
              }}
            >
              Enter valid phone number
              {/* Please provide your contact info */}
            </Typography>
            <div
              style={{
                maxWidth: "500px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                margin: "auto"
              }}
            >
              <StylesSelect
                style={{
                  backgroundColor: "white",
                  width: "120px",
                  padding: 2,
                  margin: 0,
                  fontSize: "28px"
                }}
                className="StyledSelect"
                value={this.state.value}
                autoWidth
                renderValue={this._formatTheValue}
                variant="outlined"
                onChange={e => this.handleCountrySelection(e)}
              >
                {countryCodes.map(country => (
                  <option key={country.name} value={country.code}>
                    {`${country.code}${country.name}`}
                  </option>
                ))}
              </StylesSelect>
              <input
                value={this.state.input}
                readOnly
                className="PhoneInputDiv"
                placeholder={"Enter Your Phone no"}
                onChange={e => this.onChangeInput(e)}
              />
            </div>
            <Keyboard
              ref={r => (this.keyboard = r)}
              theme={"hg-theme-default myTheme1"}
              layout={layout}
              display={{
                "{bksp}": "⌫",
                "{enter}": "submit"
              }}
              baseClass={"keyboard1"}
              buttonTheme={"myTheme2b"}
              maxLength={10}
              inputName={"input"}
              onChange={input => this.onChange(input)}
              onKeyPress={button => {
                this.onKeyPress(button);
              }}
            />
            {this.state.aepEnabled && (
              <div
                style={{
                  display: "none",
                  flexDirection: "row",
                  width: "500px",
                  margin: "auto",
                  marginTop: "16px"
                }}
              >
                <input
                  checked={this.state.checkedAep}
                  onChange={this._onAepCheck}
                  type="checkbox"
                  className="AEPcheckBox"
                />
                <Typography
                  style={{
                    height: "53px",
                    color: "#FFFFFF",
                    fontFamily: "Open Sans",
                    fontSize: "40px",
                    lineHeight: "53px"
                  }}
                >
                  AEP member ?
                </Typography>
              </div>
            )}
            <div
              onClick={() => this.onHandleSubmit(false)}
              className="NextButton"
            >
              <Typography style={{ color: "#FFFFFF", fontSize: "40px" }}>
                Next
              </Typography>
            </div>
          </div>
          {this.state.checkout && (
            <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
              <BottomPanel
                itemsIncart={this.state.itemNo}
                checkout={this.state.checkout}
                showStores={false}
              />
            </div>
          )}
          {!this.state.verifiedAep && this.state.optedForAep && (
            <Modal
              disableAutoFocus
              style={{ zIndex: 99 }}
              open={!this.state.verifiedAep && this.state.optedForAep}
            >
              <div
                style={{
                  position: "absolute",
                  top: "30%",
                  right: "10%",
                  height: "50%",
                  left: "10%",
                  backgroundColor: "black",
                  zIndex: 99,
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "column",
                  alignItems: "center"
                }}
              >
                <CouponInput
                  title={"Register with your AEP no."}
                  placeholder="Enter your 10 digit AEP"
                  input={this.state.couponCode}
                  onChange={this.onCouponCodeChange}
                />
                <Button
                  onClick={this._onAepSubmit}
                  variant="outlined"
                  size="large"
                  color="secondary"
                >
                  <Typography style={{ color: "#FFFFFF", fontSize: "24px" }}>
                    Submit
                  </Typography>
                </Button>
                <Button
                  onClick={this._onAepCancel}
                  variant="outlined"
                  size="large"
                  color="secondary"
                >
                  <Typography style={{ color: "#FFFFFF", fontSize: "24px" }}>
                    Cancel
                  </Typography>
                </Button>
              </div>
            </Modal>
          )}
          <Dialog
            open={this.state.ShowAlertDialog}
            onClose={this.handleAlertClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            style={{ zIndex: 999 }}
            maxWidth={"lg"}
          >
            <DialogTitle id="alert-dialog-title">
              {this.state.alertTitle}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.alertMsg}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleAlertClose} color="primary">
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    return (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          height: "100vh",
          width: "100vw",
          background: "silver"
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "30%",
            width: "50%",
            left: "25%",
            textAlign: "center"
          }}
        >
          <Lottie
            options={defaultOptions}
            height={400}
            width={400}
            isStopped={this.state.loadingStoreData}
          />
          <Typography
            style={{
              fontFamily: "Open Sans",
              fontSize: "24px",
              fontWeight: "400"
            }}
          >
            loading...
          </Typography>
        </div>
      </div>
    );
  }
}

export default UserDetailScreen;

// <div
// style={{
//   marginTop: "40px",
//   width: "100%",
//   display: "flex",
//   justifyContent: "center"
// }}
// >

// </div>

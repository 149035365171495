import React from "react";
import "./GrabbnGoLogo.css";
import { REGION_CONFIG_SETTING } from "../../utils/config";
const gngLogo = require("../../assets/logodetail.png");
const fstrLogo = require("../../assets/fstr.png");

const region = JSON.parse(localStorage.getItem(REGION_CONFIG_SETTING));

const GrabbnGoLogo = ({ showLogo = false }) => {
  if (showLogo) {
    return (
      <div className="GrabbnGoLogoMainDiv">
        <img className="GrabbnGoLogoImage" src={gngLogo} alt="" />
        {region && region.city === "Bengaluru" && (
          <img className="GrabbnGoLogoImage" src={fstrLogo} alt="" />
        )}
      </div>
    );
  }
  return null;
};

export default GrabbnGoLogo;

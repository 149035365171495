import React, { PureComponent } from "react";
import Typography from "@material-ui/core/Typography";
import "./Error.css";
import { Button } from "@material-ui/core";
const logo = require("../../assets/logo.png");
class ErrorScreen extends PureComponent {
  render() {
    return (
      <div className="container">
        <div className="centered">
          <div>
            <img
              src={logo}
              alt="Error"
              style={{
                backgroundColor: "#000000",
                width: 100,
                height: 100,
                marginBottom: 20
              }}
            />
            <Typography
              style={{
                color: "#000000",
                fontSize: "30px",
                fontFamily: "Open Sans",
                fontWeight: "400"
              }}
            >
              {"Oops, Something went wrong"}
            </Typography>
          </div>
          <Button
            variant="contained"
            size="large"
            onClick={this.props.onHomeButtonClick}
            style={{
              marginTop: 50,
              backgroundColor: "#4A4A4A",
              color: "white",
              padding: 8
            }}
          >
            Home
          </Button>
        </div>
      </div>
    );
  }
}

export default ErrorScreen;

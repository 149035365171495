import React from "react";
import PropTypes from "prop-types";
import client from "../../api/client";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { submitCredentials, getUserProfile } from "../../utils/helpers";
import {
  authTokenKey,
  REGION_ID_LOCAL,
  REGION_CONFIG_SETTING,
  userProfileKey,
  regionsKey,
} from "../../utils/config";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./StartScreen.css";
import { GET_REGION_CONFIG } from "../../api/queries";

const image1 = require("../../assets/Start Screeen-01.webp");
const image2 = require("../../assets/Start Screeen-02.webp");
const image3 = require("../../assets/Start Screeen-03.webp");
const image5 = require("../../assets/Start Screeen-05.webp");
const image7 = require("../../assets/Start Screeen-07.webp");
const image8 = require("../../assets/Start Screeen-08.webp");
const image9 = require("../../assets/Start Screeen-09.webp");

const BLRimg1 = require("../../assets/blrss1.png");
const BLRimg2 = require("../../assets/blrss2.png");
const BLRimg3 = require("../../assets/blrss3.png");
const BLRimg4 = require("../../assets/blrss4.png");
const BLRimg5 = require("../../assets/blrss5.png");
const BLRimg6 = require("../../assets/blrss6.png");
const BLRimg7 = require("../../assets/blrss7.png");

const TFSImage1 = require("../../assets/Kiosk-Screen2.png");
const TFSImage2 = require("../../assets/Kiosk-Screen3.png");
const TFSImage3 = require("../../assets/Kiosk-Screen6.png");
const TFSImage4 = require("../../assets/Kiosk-Screen7.png");

const classes = (theme) => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "20%",
  },
  textField: {
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    width: 200,
  },
  dense: {
    marginTop: 19,
  },
  menu: {
    width: 200,
  },
});

class StartScreen extends React.Component {
  state = {
    name: "",
    password: "",
    loggedIn: false,
    region: null,
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentDidMount() {
    // console.log("User is logged in", localStorage.getItem(authTokenKey));
    if (localStorage.getItem(authTokenKey)) {
      const regionId = localStorage.getItem(REGION_ID_LOCAL);
      const localRegion = JSON.parse(
        localStorage.getItem(REGION_CONFIG_SETTING)
      );
      console.log("region id for the kiosk", localRegion);
      this.setState({ loggedIn: true, region: localRegion });
      client
        .query({
          query: GET_REGION_CONFIG(),
          variables: { regionId: regionId },
        })
        .then((reg) => {
          console.log(" response ------->", reg.data.region);
          const localData = JSON.stringify(reg.data.region);
          localStorage.setItem(REGION_CONFIG_SETTING, localData);
          getUserProfile()
            .then((res) => {
              if (res && res.userProfile) {
                localStorage.setItem(userProfileKey, JSON.stringify(res));
                localStorage.setItem(
                  regionsKey,
                  JSON.stringify(
                    res.userProfile.user.regions.edges[0].node.name
                  )
                );
                localStorage.setItem(
                  REGION_ID_LOCAL,
                  JSON.stringify(res.userProfile.user.regions.edges[0].node.id)
                );
              }
              if (reg.data.region && reg.data.region.hideKioskScreenSaver) {
                this.props.history.push("/home");
              }
            })
            .catch((err) => {
              console.log("Error in user profile", err);
            });
        })
        .catch((err) => {
          console.log("Error in query ", err);
        });
    }
    this.imageArray = [image1, image2, image3, image5, image7, image8, image9];
    this.blrImageArr = [
      BLRimg1,
      BLRimg2,
      BLRimg3,
      BLRimg4,
      BLRimg5,
      BLRimg6,
      BLRimg7,
    ];
    this.tfsImage = [TFSImage1, TFSImage2, TFSImage3, TFSImage4];
  }

  _onSubmit = (e) => {
    e.preventDefault();
    const { name, password } = this.state;
    submitCredentials(name, password)
      .then((res) => {
        if (res.status === 200) {
          this.props.history.push("/");
          window.location.reload();
        } else {
          alert("Invalid Login Details");
        }
      })
      .catch((err) => {
        console.log("Error in login", err);
        alert(err.message);
      });
  };

  _goToStoresScreen = () => {
    this.props.history.push("/home");
  };

  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      pauseOnHover: false,
      customPaging: () => <span style={{ backgroundColor: "transparent" }} />,
    };
    const { classes } = this.props;
    const { region } = this.state;
    console.log("Image Array", this.imageArray);
    if (!this.state.loggedIn) {
      return (
        <form
          onSubmit={this._onSubmit}
          className={classes.container}
          noValidate
          autoComplete="off"
        >
          <input
            // id="standard-name"
            label="username"
            className={classes.textField}
            value={this.state.name}
            onChange={this.handleChange("name")}
            // margin="normal"
          />
          <input
            // id="standard-password"
            type="password"
            label="password"
            className={classes.textField}
            value={this.state.password}
            onChange={this.handleChange("password")}
            // margin="normal"
          />
          <Button onClick={this._onSubmit} type="submit">
            Login
          </Button>
        </form>
      );
    }
    if (region && region.city === "Bengaluru") {
      return (
        <>
          <Slider {...settings} dotsClass={"ControlDots"}>
            {this.blrImageArr.map((image, index) => (
              <div key={index}>
                <img
                  onClick={this._goToStoresScreen}
                  style={{
                    objectFit: "contain",
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: "white",
                  }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Slider>
        </>
      );
    }
    return (
      <>
        {region && region.kioskShouldShowHeader && (
          <Slider {...settings} dotsClass={"ControlDots"}>
            {this.imageArray.map((image, index) => (
              <div key={index}>
                <img
                  onClick={this._goToStoresScreen}
                  style={{
                    objectFit: "contain",
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: "white",
                  }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Slider>
        )}
        {!(region && region.kioskShouldShowHeader) && (
          <Slider {...settings} dotsClass={"ControlDots"}>
            {this.tfsImage.map((image, index) => (
              <div key={index}>
                <img
                  onClick={this._goToStoresScreen}
                  style={{
                    objectFit: "contain",
                    height: "100vh",
                    width: "100vw",
                    backgroundColor: "white",
                  }}
                  src={image}
                  alt=""
                />
              </div>
            ))}
          </Slider>
        )}
      </>
    );
  }
}

StartScreen.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(classes)(StartScreen);

import React from "react";
import { render } from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import FirstRouter from "./Router/MainRouter";

Sentry.init({
  dsn: "https://915dd7207e7a4993a044f11a6c6f8f9c@sentry.io/1401881"
});
const rootElement = document.getElementById("root");

if (process.env.NODE_ENV === "production") {
   console.log = () => {};
}
render(<FirstRouter />, rootElement);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

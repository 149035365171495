const queries = {
  getBusinessDomains: `
          query
          {
              businessDomains
              {
                  edges
                  {
                      node
                      {
                          id
                          name
                          image
                      }
                  }
              }
          }
      `,

  getPickupPointsAlongRoute: `
          query PickupPointsAlongRoute($along:String!, $businessDomain:[ID]!, $polyline:String!)
          {
              pickupPointsAlongRoute(along:$along, forBusinessDomain:$businessDomain, polyline:$polyline)
              {
                  name
                  id
                  durationInTrafficText
                  durationInTrafficValue
                  distanceValue
                  latitude
                  longitude
                  store
                  {
                      id
                      maxCartItems
                      isOpen
                      availabilityText
                      name
                      area
                      address
                      maxDepth
                      merchant
                      {
                          image
                      }
                  }
              }
          }
      `,
  pickupPointsAround: `
      query pickupPointsNearby($latitude:Float!, $businessDomain:[ID]!, $longitude:Float!)
          {
              pickupPointsNearby(forBusinessDomain:$businessDomain, latitude:$latitude, longitude:$longitude)
              {
                  name
                  id
                  durationInTrafficText
                  durationInTrafficValue
                  latitude
                  longitude
                  store
                  {
                      id
                      maxCartItems
                      isOpen
                      image
                      availabilityText
                      name
                      area
                      address
                      maxDepth
                      merchant
                      {
                          image
                      }
                  }
              }
          }
      `,
  pickupPointsForRegion: `
  query pickupPointsForRegion($regionId: ID!) {
    pickupPointsForRegion(forRegion: $regionId) {
      pickuppoint {
        id
        name
        store {
          id
          maxCartItems
          isOpen
          image
          cardImage
          availabilityText
          name
          area
          address
          maxDepth
          merchant {
            image
          }
        }
      }
    }
  }
  `,
  threeLeveledCategoriesForStores: `
      query threeLeveledCategoriesForStore($storeId:ID!)
      {
          categories(forStore:$storeId, level:0)
          {
              totalCount
              edges{
                  node{
                      name
                      id
                      level
                      image
                      depth
                      children
                      {
                          totalCount
                          edges
                          {
                              node
                              {
                                  name
                                  id
                                  level
                                  image
                                  depth
                                  children
                                  {
                                    totalCount
                                    edges
                                    {
                                        node
                                        {
                                            name
                                            id
                                            image
                                        }
                                    }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  fetchChildrenForCategory: `
      query fetchChildrenForCategory($categoryId:ID!){
          category(id:$categoryId){
              name
              id
              level
              image
              depth
              children 
              {
                  edges 
                  {
                      node 
                      {
                          id
                          name
                      }
                  }
              }
          }
      }
      `,
  getProductSKUsStocksForCategoryTakeAway: `
      query getProductSKUsStocksForCategory($categoryId:ID!, $storeId:ID!, $after:String!)
      {
          products(forCategory:$categoryId, after:$after, forStore:$storeId){
              pageInfo{
                  endCursor
                  hasNextPage
              }
              edges{
                  node{
                      name
                      id
                      image
                      description
                      category{
                        id
                      }
                      contains
                      skus(sortBy:"maximum_retail_price"){
                          edges{
                              node{
                                  name
                                  quantity
                                  id
                                  image
                                  maximumRetailPrice
                                  maximumRetailPriceCurrency
                                  stocks(forStore:$storeId,takeaway: true, inStock: true){
                                      edges{
                                          node
                                          {   
                                            referenceName
                                            trending
                                            id
                                            inStock
                                            stockSla
                                            stockText
                                            salePrice
                                            customizations(level: 0,sortBy:"display_order") {
                                              edges {
                                                node {
                                                  description
                                                  id
                                                  defaultSelected
                                                  name
                                                  maxSelectable
                                                  minSelectable
                                                  displayOrder
                                                  salePrice
                                                  depth
                                                  children {
                                                    edges {
                                                      node {
                                                        id
                                                        defaultSelected
                                                        maxSelectable
                                                        salePrice
                                                        depth
                                                        name
                                                        contains
                                                        minSelectable
                                                      }
                                                    }
                                                  }
                                                  contains	
                                                }
                                              }
                                            }
                                            sku {
                                              id
                                              name
                                              image
                                              product {
                                                id
                                                contains
                                                name
                                                image
                                              }
                                              quantity
                                              maximumRetailPrice
                                              maximumRetailPriceCurrency
                                              description
                                            }
                                            
                                            store {
                                              id
                                              name
                                              image
                                              pickupPoints {
                                                edges {
                                                  node {
                                                    id
                                                    name
                                                    store{
                                                      maxCartItems
                                                      maxDepth
                                                    }
                                                    sla {
                                                      id
                                                      preparationTime
                                                    }
                                                  }
                                                }
                                              }
                                              merchant{
                                                image
                                              }
                                            }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  getProductSKUsStocksForCategoryDineIn: `
      query getProductSKUsStocksForCategory($categoryId:ID!, $storeId:ID!, $after:String!)
      {
          products(forCategory:$categoryId, after:$after, forStore:$storeId){
              pageInfo{
                  endCursor
                  hasNextPage
              }
              edges{
                  node{
                      name
                      id
                      image
                      description
                      category{
                        id
                      }
                      contains
                      skus(sortBy:"maximum_retail_price"){
                          edges{
                              node{
                                  name
                                  quantity
                                  id
                                  image
                                  maximumRetailPrice
                                  maximumRetailPriceCurrency
                                  stocks(forStore:$storeId,dinein: true, inStock: true){
                                      edges{
                                          node
                                          {   
                                            referenceName
                                            trending
                                            id
                                            inStock
                                            stockSla
                                            stockText
                                            salePrice
                                            customizations(level: 0,sortBy:"display_order") {
                                              edges {
                                                node {
                                                  description
                                                  id
                                                  defaultSelected
                                                  name
                                                  maxSelectable
                                                  minSelectable
                                                  displayOrder
                                                  salePrice
                                                  depth
                                                  children {
                                                    edges {
                                                      node {
                                                        id
                                                        defaultSelected
                                                        maxSelectable
                                                        salePrice
                                                        depth
                                                        name
                                                        contains
                                                        minSelectable
                                                      }
                                                    }
                                                  }
                                                  contains	
                                                }
                                              }
                                            }
                                            sku {
                                              id
                                              name
                                              image
                                              product {
                                                id
                                                contains
                                                name
                                              }
                                              quantity
                                              maximumRetailPrice
                                              maximumRetailPriceCurrency
                                              description
                                            }
                                            
                                            store {
                                              id
                                              name
                                              pickupPoints {
                                                edges {
                                                  node {
                                                    id
                                                    name
                                                  }
                                                }
                                              }
                                            }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  getProductSKUsStocksForSearch: `
      query getProductSKUsStocksForSearch($storeId:ID!, $after:String!, $searchString:String!)
      {
          products(first:10, after:$after, name_Icontains:$searchString, forStore:$storeId){
              pageInfo{
                  endCursor
                  hasNextPage
              }
              edges{
                  node{
                      name
                      id
                      image
                      description
                      contains
                      skus{
                          edges{
                              node{
                                  name
                                  quantity
                                  id
                                  maximumRetailPrice
                                  maximumRetailPriceCurrency
                                  stocks(forStore:$storeId, inStock: true){
                                      edges{
                                          node
                                          {
                                              id
                                              inStock
                                              stockSla
                                              stockText
                                              salePrice
                                              salePriceCurrency
                                              customizations(level:0){
                                                  edges{
                                                      node{
                                                          id
                                                          name
                                                          level
                                                          maxSelectable
                                                          minSelectable
                                                          children{
                                                              edges{
                                                                  node{
                                                                      id
                                                                      name
                                                                      defaultSelected
                                                                      price
                                                                      priceCurrency
                                                                  }
                                                              }
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  updateCheckout: `
      mutation UpdateCheckout($input: CheckoutInput!){
          updateCheckout(input:$input){
              checkout{
                user{
                    phone
                    id
                  }
                  id
                  token
                  status
                  total
                  totalCurrency 
                  subtotal
                  subtotalCurrency
                  extra
                  code
                  codeText
                  codeType
                  carts
                  {
                      edges{
                          node{
                              id
                              total
                              totalCurrency
                              extra
                              subtotal
                              cartSla
                              subtotalCurrency
                              extra
                              specialInstructions
                              pickupPoint
                              {
                                  name
                                  id
                                  comments
                                  sla
                                  {
                                      pushType
                                      preparationTime
                                  }
                                  store
                                  {
                                      name
                                      maxCartItems
                                      id
                                  }
                              }
                              cartItems
                              {
                                  edges
                                  {
                                      node
                                      {
                                          id
                                          quantity
                                          unitPrice
                                          unitPriceCurrency
                                          total
                                          totalCurrency
                                          customizations
                                          {
                                              edges
                                              {
                                                  node
                                                  {
                                                      id
                                                      name
                                                      price
                                                  }
                                              }
                                          }
                                          stock
                                          {
                                              id
                                              stockSla
                                              sku
                                              {
                                                  quantity
                                                  product
                                                  {
                                                      name
                                                      image
                                                      description
                                                  }
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  getUserProfile: `
      query
      {
          userProfile
          {
              id
              firstName
              lastName
              image
              canAddReferral
              paytm
              creditcards
              user
              {
                  id
                  phone
                  email
                  regions{
                      edges{
                          node{
                              delivery
                              takeaway 
                              dinein
                              id
                              name
                              hideKioskScreenSaver
                          }
                      }
                  }
              }
          }
      }
      `,
  getUserRegion: `
      query regions($name: String ){
        regions(name: $name, first: 1){
          edges{
            node{
              id
              aep
              name
              hideKioskScreenSaver
            }
          }
        }
      }
      `,
  updateUserProfile: `
      mutation updateUser($input:UserProfileInput!)
      {
          updateUserProfile(input:$input)
          {
              userProfile
              {
                  user
                  {
                      email
                      phone
                      id
                      userProfile
                      {
                          image
                          lastName
                          firstName
                          dob
                      }
                  }
              }
          }
      }
      `,
  getUserVehicles: `
      query
      {
          userVehicles(type_In:"CAR, BIKE")
          {
              edges
              {
                  node
                  {
                      id
                      type
                      model
                      registration
                  }
              }
          }
      }
      `,
  updateVehicleDetails: `
      mutation updateUserVehicles($input:UserVehicleInput!)
      {
          updateUserVehicle(input:$input)
          {
              userVehicle
              {
                  id
                  model
                  registration
                  type
              }
          }
      }
      `,
  updateSpecialInstructions: `
      mutation updateSpecialInstructions($input:SpecialInstructionsInput!){
          updateSpecialInstructions(input: $input)
          {
              success
          }
      }
      `,

  updateCheckoutVehicle: `
      mutation updateCheckoutVehicle($token:String!, $vehicleId:ID!){
          updateCheckoutVehicle(token:$token, userVehicleId:$vehicleId){
              checkout
              {
                  id
              }
          }
      }
      `,
  initializePayment: `
      mutation initializePayment($input:PaymentInput!){
          updatePayment(input:$input){
            payment {
                id
                token
                paytmQr {
                  id
                  details
                }
                code
                mode
                amount
                status
              }
            paymentLink
          }
      }
      `,
  updatePaymentSucces: `
      mutation paymentSuccessful($input:PaymentInput!){
          updatePayment(input:$input){
              payment{
                  status
                  transactionId
                  mode
                  amount
                  checkout{
                      id
                      extra
                      subtotal
                      total
                  }
              }
              orders
              {
                  id
                  orderId
                  createdAt
                  storeToken
                  trackingUrl
                  trackingToken
                  pushedAt
                  status
                  customerStatus
                  sla
                  cart
                  {
                      extra
                      pickupPoint{
                          sla{
                              preparationTime
                          }
                          latitude
                          longitude
                          name
                          store
                          {
                              name
                              phones
                              merchant
                              {
                                  image
                              }
                          }
                      }
                      cartItems
                      {
                          edges
                          {
                              node
                              {
                                  quantity
                                  subtotal
                                  total
                                  stock
                                  {
                                      sku
                                      {
                                          product
                                          {
                                              name
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  updateOrderStatus: `
      mutation updateOrderStatus($input:OrderInput!)
      {
          updateOrder(input:$input)
          {
              order
              {
                  id
                  orderId
                  storeToken
                  trackingToken
                  status
                  pushedAt
                  customerStatus
                  cart
                  {
                      pickupPoint
                      {
                          sla{
                              preparationTime
                          }
                          latitude
                          longitude
                          name
                          store
                          {
                              name
                              phones
                          }
                      }
                      cartItems
                      {
                          edges{
                              node
                              {
                                  quantity
                                  stock
                                  {
                                      sku
                                      {
                                          product
                                          {
                                              name
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  currentBalance: `
      query
      {
          wallets(status:"OPEN")
          {
              edges
              {
                  node
                  {
                      balance
                      balanceCurrency
                  }
              }
          }
      }
      `,
  getActiveSubscriptions: `
      query{
          subscriptions{
              edges
              {
                  node
                  {
                      id
                      offer
                      {
                          name
                      }
                      used
                      total
                  }
              }
          }
      }
      `,
  getAvailableSubscriptions: `
      query
      {
          offers(isSubscription:true){
              edges
              {
                  
                  node
                  {
                      id
                      name
                      minPaymentAmount
                      minPaymentAmountCurrency
                      code
                      users
                      {
                          edges
                          {
                              node
                              {
                                  email
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  getPromotions: `
      query
      {
          promotions
          {
              edges
              {
                  node
                  {
                      id
                      image
                      url
                      isPopup
                      popupImage
                  }
              }
          }
      }
      `,
  getFeedbackTags: `
      query
      {
          one:feedbacks(levels_Contains:"1")
          {
              edges
              {
                  node
                  {
                      id
                      label:name
                  }
              }
          }
          two:feedbacks(levels_Contains:"2")
          {
              edges
              {
                  node
                  {
                      id
                      label:name
                  }
              }
          }
          three:feedbacks(levels_Contains:"3")
          {
              edges
              {
                  node
                  {
                      id
                      label:name
                  }
              }
          }
          four:feedbacks(levels_Contains:"4")
          {
              edges
              {
                  node
                  {
                      id
                      label:name
                  }
              }
          }
          five:feedbacks(levels_Contains:"5")
          {
              edges
              {
                  node
                  {
                      id
                      label:name
                  }
              }
          }
      }
      `,
  sendFeedback: `
      mutation sendFeedback($input:FeedbackInput!){
          updateFeedback(input:$input){
              success
          }
      }
      `,
  getOrderHistory: `
      query{
          orders{
              edges{
                  node{
                      orderId
                      createdAt
                      status
                      cart{
                          id
                          total
                          totalCurrency
                          extra
                          subtotal
                          subtotalCurrency
                          extra
                          pickupPoint
                          {
                              name
                              store
                              {
                                  name
                              }
                          }
                          cartItems
                          {
                              edges
                              {
                                  node
                                  {
                                      id
                                      quantity
                                      unitPrice
                                      unitPriceCurrency
                                      total
                                      totalCurrency
                                      stock
                                      {
                                          sku
                                          {
                                              quantity
                                              product
                                              {
                                                  name
                                              }
                                          }
                                      }
                                      customizations
                                      {
                                          edges
                                          {
                                              node
                                              {
                                                  id
                                                  name
                                                  price
                                              }
                                          }
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  getOrderDetails: `
      query OrderDetails($id:ID!){
          order(id:$id){
              id
              cart{
                  cartItems{
                      edges{
                          node{
                              id
                              quantity
                              status
                              stock{
                                  sku{
                                      product{
                                          name
                                      }
                                  }
                              }
                          }
                      }
                  }
              }
          }
      }
      `,
  getTimeSaved: `
      query{
          userProfile{
              timeSaved
          }
      }
      `,
  updateOrderSOS: `
      mutation updateOrderStatus($input:OrderInput!)
      {
          updateOrder(input:$input)
          {
              order
              {
                  id
              }
          }
      }
      `,
  getReferralInformation: `
      query{
          userProfile{
              firstName
              lastName
              referralCode
              user{
                  referralCount
                  referralOrderedCount
              }
          }
      }
      `,
  getWalletHistory: `
      query{
          wallets {
            edges {
              node {
                id,
                amount,
                amountCurrency,
                action,
                comments
              }
            }
          }
        }
      `,
  getPopularTakeawayFood: `
      query stocks {
        stocks(first:24, sortBy:"trending", inStock: true, takeaway: true){
          edges{
            node{   
              referenceName
              trending
              stockSla
              referenceCategory
              categoryDisplayOrder
              id
              salePrice
              takeaway
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains	
                  }
                }
              }
              sku {
                id
                name
                image
                quantity    
                product {
                  id
                  contains
                  name
                  image
                }
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
              }
              
              store {
                id
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                      store{
                        maxCartItems
                        maxDepth
                      }
                      sla {
                        id
                        preparationTime
                      }
                    }
                  }
                }
                merchant{
                  image
                }
              }
            }
          }
        }
      }`,
  getPopularDineInFood: `
      query stocks {
        stocks(first:24, sortBy:"trending", inStock: true, dinein: true){
          edges{
            node{   
              referenceName
              trending
              stockSla
              referenceCategory
              categoryDisplayOrder
              id
              salePrice
              dinein
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains	
                  }
                }
              }
              sku {
                id
                name
                image
                quantity    
                product {
                  id
                  contains
                  name
                  image
                }
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
              }
              
              store {
                id
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                      store{
                        maxCartItems
                        maxDepth
                      }
                      sla {
                        id
                        preparationTime
                      }
                    }
                  }
                }
                merchant{
                  image
                }
              }
            }
          }
        }
      }`,
  getPopularDeliveryFood: `
      query stocks {
        stocks(first:24, sortBy:"trending", inStock: true, delivery: true){
          edges{
            node{   
              referenceName
              trending
              stockSla
              referenceCategory
              categoryDisplayOrder
              id
              delivery
              salePrice
              customizations(level: 0) {
                edges {
                  node {
                    description
                    id
                    defaultSelected
                    name
                    maxSelectable
                    minSelectable
                    salePrice
                    depth
                    children {
                      edges {
                        node {
                          id
                          defaultSelected
                          maxSelectable
                          salePrice
                          depth
                          name
                          contains
                          minSelectable
                        }
                      }
                    }
                    contains	
                  }
                }
              }
              sku {
                id
                name
                image
                quantity    
                product {
                  id
                  contains
                  name
                  image
                }
                maximumRetailPrice
                maximumRetailPriceCurrency
                description
              }
              
              store {
                id
                name
                image
                isOpen
                pickupPoints {
                  edges {
                    node {
                      id
                      name
                      store{
                        maxCartItems
                        maxDepth
                      }
                      sla {
                        id
                        preparationTime
                      }
                    }
                  }
                }
                merchant{
                  image
                }
              }
            }
          }
        }
      }`,
};

export default queries;

export const serverUrl =
  process.env.NODE_ENV === "development"
    ? "https://app.grabbngo.com/graph"
    : "https://app.grabbngo.com/graph";
export const loginUrl =
  process.env.NODE_ENV === "development"
    ? "https://app.grabbngo.com/api/account/kiosk/login/"
    : "https://app.grabbngo.com/api/account/kiosk/login/";
export const authTokenKey = "gng-authToken";
export const loginModeKey = "gng-loginMode";
export const storesDataKey = "gng-storesData";
export const popularFoodKey = "gng-popularFood";
export const userProfileKey = "gng-userProfile";
export const regionsKey = "gng-regionUserProfile";
export const REGION_ID_LOCAL = "gng-kiosk-region-id";
export const AEP_SETTING_USER_KEY = "gng-AEP_SETTING_USER_KEY";
export const lastDataFetchedKey = "gng-lastDataFetched";

export const REGION_CONFIG_SETTING = "GNG_KIOSK_REGION_CONFIG";
export const paymentStatus = {
  init: "INIT",
  success: "SUCCESS",
  failed: "FAILURE",
  cancel: "CANCEL"
};
export const paymentMode = {
  kiosk: "KIOSK",
  paytm: "PAYTM QR",
  pineLabs: "PINE LABS"
};

export const orderMode = {
  dineIn: "DINEIN",
  takeAway: "TAKEAWAY",
  delivery: "DELIVERY"
};

export const ORDER_MODES = {
  dineIn: "DINEIN",
  takeAway: "TAKEAWAY",
  delivery: "DELIVERY"
};

export const codeType = {
  aep: "AEP",
  default: ""
};

export const codeTexts = {
  showAep: "PROMPT AEP"
};

export const emptyCartMessage = ["This is message 1", "This is message 2"];

export const pollingInterval = 300; //in seconds //polling interval is 5 mins

import React, { Component } from "react";
import "./PaymentScreen.css";
import Typography from "@material-ui/core/Typography";
import QRCode from "qrcode.react";
import { paymentMode, paymentStatus, userProfileKey } from "../../utils/config";
import CartManager from "../../singletons/CartManager";
import {
  placeOrder,
  checkPaymentStatus,
  cancelPayment,
} from "../../utils/helpers";
import Lottie from "react-lottie";
import LoadingQR from "../../animationData/rotate.json";
import CardAnimation from "../../animationData/credit_card.json";
import ReactCountdownClock from "react-countdown-clock";
import BottomPanel from "../../components/BottomPanel/BottomPanel";

const paytmLogo = require("../../assets/Paytm_logo.png");

class PaymentScreen extends Component {
  _isMounted = false;
  constructor() {
    super();
    this.state = {
      checkout: null,
      paymentMode: null,
      isLoading: false,
      qrCode: "",
      paymentInitDone: false,
      paymentSuccess: false,
      paymentFailed: false,
      paymentToken: null,
      itemNo: 0,
      showPaytm: true,
      showCreditCard: true,
      paymentLink: null,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const userProfile = JSON.parse(localStorage.getItem(userProfileKey));
    console.log("USER PROFILE ``-`-`-`", userProfile);
    this.setState({
      showPaytm: userProfile.userProfile.paytm,
      showCreditCard: userProfile.userProfile.creditcards,
    });
    const cartManager = new CartManager();
    this.setState(
      {
        checkout: cartManager.getCheckOut(),
      },
      () => {
        this._paymentInit(paymentMode.kiosk);
        const checkout = cartManager.getCheckOut();
        let items = 0;
        if (checkout.carts && checkout.carts.edges) {
          for (let cart of checkout.carts.edges) {
            console.log("Cart Cart  ---> ", cart);
            for (let item of cart.node.cartItems.edges) {
              items = items + item.node.quantity;
            }
          }
          // TO-DO: Convert above part into a stand alone function
          this.setState({ itemNo: items });
        }
      }
    );
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.timer = null;
    clearTimeout(this.timer);
    console.log("<<< --- Payment screen is unmounting --- >>>", this.timer);
  }

  _checkStatus = (that) => {
    const { checkout, paymentToken } = that.state;
    const amount = checkout.total;
    checkPaymentStatus(paymentMode.kiosk, paymentToken, amount)
      .then((res) => {
        console.log("PayMent status", res.updatePayment);
        const response = res.updatePayment;
        if (
          response.payment &&
          response.payment.status === paymentStatus.success &&
          response.orders &&
          response.orders.length > 0
        ) {
          that.setState({ paymentSuccess: true });
          that.timer = null;
          clearTimeout(that.timer);
          console.log("Payment successful and pushing the token screen");
          that.props.history.push({
            pathname: "/tokenScreen",
            data: response,
          });
        } else if (
          response.payment &&
          response.payment.status === paymentStatus.failed
        ) {
          that.setState({ paymentSuccess: false, paymentFailed: true });
          that.timer = null;
          clearTimeout(that.timer);
          console.log("Payment failed and pushing the token screen");
          that.props.history.push({
            pathname: "/tokenScreen",
            data: response,
          });
        } else {
          that.timer = setTimeout(() => {
            that.timer !== null && this._isMounted && this._checkStatus(that);
            if (that.timer === null) {
              that.timer = null;
              clearTimeout(that.timer);
            }
          }, 2500);
        }
      })
      .catch((err) => {
        that.timer = null;
        console.log("Payment Status ----> Failed", err);
        clearTimeout(that.timer);
      });
  };
  _paymentInit = (mode) => {
    const { checkout } = this.state;
    const cartManager = new CartManager();
    const phone = cartManager.getUserDetail().phone;
    const orderMode = cartManager.getUserDetail().mode;
    const comments = cartManager.getCheckoutComment();
    const token = checkout.token;
    const amount = checkout.total;
    placeOrder(token, amount, mode, phone, orderMode, comments)
      .then((res) => {
        console.log("Peri Checkout Response", res.updatePayment.payment);
        const paymentRespose = res.updatePayment.payment;
        const paymentLink = res.updatePayment.paymentLink;
        const token = paymentRespose.token;
        let details, qrCode;
        if (paymentRespose.paytmQr) {
          details = JSON.parse(paymentRespose.paytmQr.details);
          // changed to detail.image for paytm all in one qr
          // qrCode = details.response.path;
          qrCode = details.image;
          // console.log("UPI image", details.image)
        }
        this.setState({
          qrCode: qrCode,
          paymentInitDone: true,
          paymentToken: token,
          paymentLink,
        });
        this._checkStatus(this);
      })
      .catch((err) => {
        this.setState({ paymentInitDone: false });
      });
  };

  _cancelPayment = () => {
    const { checkout } = this.state;
    const cartManager = new CartManager();
    const phone = cartManager.getUserDetail().phone;
    const token = checkout.token;
    const amount = checkout.total;
    cancelPayment(paymentMode.kiosk, token, amount, phone)
      .then((res) => {
        if (!res.error) return true;
        return false;
      })
      .catch((err) => {
        console.log("Cancel Payment ----> Error", err);
        return false;
      });
  };

  _showLoading = () => {
    this.setState({
      isLoading: true,
      paymentInitDone: false,
    });
  };

  _onBack = () => {
    let that = this;
    that.timer = null;
    clearTimeout(that.timer);
  };

  render() {
    const {
      paymentInitDone,
      qrCode,
      paymentLink,
      showCreditCard,
      showPaytm,
    } = this.state;
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingQR,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    const cardOptions = {
      loop: true,
      autoplay: true,
      animationData: CardAnimation,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };
    return (
      <div style={{ textAlign: "center" }} className="mainDivCheckoutScreen">
        {!paymentInitDone && (
          <div
            style={{
              margin: "auto",
              height: 500,
              width: 500,
              textAlign: "center",
            }}
          >
            <Lottie
              options={defaultOptions}
              height={500}
              width={500}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}
            />
            <h3>Loading...</h3>
          </div>
        )}

        {paymentInitDone && this.state.showCreditCard && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFFFFF",
              boxShadow: "0 1px 40px 0 rgba(0,0,0,0.1)",
              borderRadius: "10px",
              alignItems: "center",
              paddingTop: "36px",
              paddingBottom: "36px",
            }}
          >
            <Typography
              style={{
                fontSize: 40,
                fontWeight: "500",
              }}
            >
              Debit/Credit Card
            </Typography>
            <div
              className="cc_ph"
              style={{
                height: "275px",
                width: "600px",
                backgroundColor: "#F5F5F5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Lottie options={cardOptions} height={250} width={250} />
            </div>
            <img
              className="cc_img"
              src={require("../../assets/credit_card_all.png")}
              alt=""
              style={{ marginLeft: 30, marginTop: 20, marginRight: 30 }}
            />
          </div>
        )}
        {paymentInitDone && showCreditCard && (
          <div
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: 16,
            }}
          >
            <div
              style={{
                height: 60,
                width: 60,
                borderRadius: 60,
                backgroundColor: "#FFFFFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: 30,
                  fontWeight: "bold",
                  fontFamily: "Open Sans",
                }}
              >
                OR
              </Typography>
            </div>
          </div>
        )}
        {paymentInitDone && this.state.showPaytm && qrCode && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFFFFF",
              boxShadow: "0 1px 40px 0 rgba(0,0,0,0.1)",
              borderRadius: "10px",
              alignItems: "center",
              paddingTop: "24px",
              paddingBottom: "24px",
            }}
          >
            <div
              className="panel-ph"
              style={{
                display: "flex",
                flexDirection: "row",
                width: "60%",
                margin: "auto",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 12,
              }}
            >
              <Typography
                style={{
                  fontSize: 32,
                  fontWeight: "500",
                  marginRight: 12,
                  fontFamily: "Open Sans",
                }}
              >
                {showPaytm ? "UPI All-in-One QR " : "Scan with any QR Reader to pay"}
              </Typography>
              {showPaytm && (
                <img
                  src={paytmLogo}
                  height={"50px"}
                  width={"100px"}
                  alt={"PAytm Logo"}
                  style={{ objectFit: "contain", margin: 0 }}
                />
              )}
            </div>
            {!showPaytm && paymentLink && (
              <QRCode value={paymentLink} renderAs="svg" size={200} />
            )}
            {showPaytm && (
              <img
                className="paytm_img"
                src={`data:image/png;base64,${qrCode}`}
                id="ItemPreview"
                alt="Paytm code"
                style={{
                  height: 250,
                  width: 250,
                }}
              />
            )}
          </div>
        )}

        {paymentInitDone && this.state.showCreditCard && (
          <div
            className="cc_info"
            style={{
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#FFFFFF",
              boxShadow: "0 1px 40px 0 rgba(0,0,0,0.1)",
              borderRadius: "10px",
              alignItems: "center",
              paddingTop: "24px",
              marginTop: "24px",
              marginBottom: "24px",
              paddingBottom: "24px",
            }}
          >
            <div
              style={{
                height: "350px",
                width: "600px",
                backgroundColor: "#F5F5F5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={require("../../assets/cc-guide.png")}
                alt=""
                height={"100%"}
                width={"100%"}
              />
            </div>
          </div>
        )}

        {paymentInitDone && (
          <div>
            <ReactCountdownClock
              seconds={235}
              color="#34495E"
              alpha={0.9}
              className="ReactClock"
              size={200}
              weight={4}
              timeFormat={"hms"}
              onComplete={this._showLoading}
            />
          </div>
        )}
        {this.state.checkout && (
          <div style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}>
            <BottomPanel
              itemsIncart={this.state.itemNo}
              checkout={this.state.checkout}
              showStores={false}
              onPressBack={this._onBack}
              paymentCancel={this._cancelPayment}
            />
          </div>
        )}
      </div>
    );
  }
}
export default PaymentScreen;

import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ThumbDownAltTwoToneIcon from "@material-ui/icons/ThumbDownAltTwoTone";
import ThumbUpAltTwoToneIcon from "@material-ui/icons/ThumbUpAltTwoTone";
import classes from "./FeedBack.module.css";
import client from "../../api/client";
import { UPDATE_CHECKOUT_FEEDBACK } from "../../api/mutations";
import { Box, Button, Modal } from "@material-ui/core";

class FeedBack extends PureComponent {
  state = {
    open: false,
    value: null
  };
  onUpdate = value => {
    const { checkout } = this.props;
    this.setState(
      {
        open: true,
        value: value
      },
      () => {
        checkout &&
          client.mutate({
            mutation: UPDATE_CHECKOUT_FEEDBACK(),
            variables: {
              input: {
                checkoutId: checkout.id,
                rating: value,
                comments: "",
                feedbackIds: []
              }
            }
          });
      }
    );
  };
  onSubmit = () => {
    this.setState({
      open: false
    });
  };
  render() {
    const { open, value } = this.state;
    return (
      <Box className={classes.main_div}>
        <p>How was your experience?</p>
        <div className={classes.rating_div}>
          <Button onClick={() => this.onUpdate(2)}>
            <ThumbDownAltTwoToneIcon
              style={{
                height: 80,
                width: 80,
                color: value === 2 ? "tomato" : "",
                transition: "all 0.5s"
              }}
            />
          </Button>
          <Button onClick={() => this.onUpdate(5)}>
            <ThumbUpAltTwoToneIcon
              style={{
                height: 80,
                width: 80,
                color: value === 5 ? "tomato" : "",
                transition: "all 0.5s"
              }}
            />
          </Button>
          <Modal open={open}>
            <div className={classes.modal_body}>
              <p>
                Thank you for ordering experience. <br /> We wish you a good day
                ahead.
              </p>
              <Button
                variant="contained"
                color="primary"
                size="large"
                className={classes.submit_button}
                onClick={this.onSubmit}
                hideBackdrop
              >
                Okay
              </Button>
            </div>
          </Modal>
        </div>
      </Box>
    );
  }
}

FeedBack.propTypes = {
  checkout: PropTypes.any
};

FeedBack.defaultProps = {};

export default FeedBack;

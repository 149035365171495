import React, { PureComponent } from "react";
import CartItemCard from "../CartItemCard/CartItemCard";
import ShoppingBasketSharp from "@material-ui/icons/ShoppingBasketSharp";
import ArrowBackIcon from "@material-ui/icons/ArrowBackSharp";
import Badge from "@material-ui/core/Badge";
import { orderMode } from "../../utils/config";
import PropTypes from "prop-types";
import classes from "./LiveCart.module.css";

const restartButton = require("../../assets/restart.png");

class LiveCart extends PureComponent {
  _showCartInfoModal = () => {
    const { itemsIncart, onShowCartInfoModal } = this.props;
    if (itemsIncart > 0) {
      onShowCartInfoModal();
    }
  };
  __goToPayMentScreen = () => {
    const { itemsIncart, onPayButtonClick } = this.props;
    if (itemsIncart > 0) {
      onPayButtonClick();
    }
  };
  _onRestartClick = () => {
    const { onRestartClick } = this.props;
    onRestartClick && onRestartClick();
  };
  onUpdateCheckout = () => {
    const { updateCheckOut } = this.props;
    updateCheckOut && updateCheckOut();
  };
  _onBackButtonClick = () => {
    const { onBackButtonClick } = this.props;
    onBackButtonClick && onBackButtonClick();
  };
  render() {
    const { itemsIncart, checkout, showStores, location } = this.props;
    return (
      <div className={classes.live_cart_main_div}>
        <div className={classes.pay_button_div}>
          <div
            className={classes.cart_header}
            onClick={this._showCartInfoModal}
          >
            <img
              height={35}
              width={35}
              src={require("../../assets/info.png")}
              alt=""
            />
            <p className={classes.your_cart_text}>Your Cart</p>
            <Badge
              className={classes.count_badge}
              color="secondary"
              badgeContent={itemsIncart}
            >
              <ShoppingBasketSharp className={classes.cart_icon} />
            </Badge>
          </div>
          <div className={classes.live_cart_pay_button}>
            <div className={classes.pay_div_amount_text}>
              <p className={classes.cart_amount_text}>
                <span className={classes.cart_amount_heading}>
                  Total (inc taxes) :
                </span>

                {`₹ ${checkout && checkout.total ? checkout.total : 0}`}
              </p>
            </div>
            {showStores && (
              <div
                onClick={this.__goToPayMentScreen}
                className={classes.live_cart_pay_button_div}
              >
                <p className={classes.pay_button}>PAY</p>
              </div>
            )}
          </div>
        </div>
        {showStores && (
          <div className={classes.bottom_panel_card_list}>
            {checkout &&
              checkout.carts &&
              checkout.carts.edges.map(cart => {
                return cart.node.cartItems.edges.map(item => (
                  <CartItemCard
                    storeName={cart.node.pickupPoint.store.name}
                    pickupPoint={cart.node.pickupPoint}
                    key={item.node.id}
                    item={item}
                    updateCheckOut={this.onUpdateCheckout}
                    location={cart.node.pickupPoint.name}
                  />
                ));
              })}
          </div>
        )}
        <div className={classes.live_cart_button_div}>
          {!location.pathname.includes("/home") && (
            <div
              className={classes.back_button_div}
              onClick={this._onBackButtonClick}
            >
              <ArrowBackIcon style={{ fontSize: "32px" }} />
              <p
                // className="back_btn"
                className={classes.back_button}
              >
                Back
              </p>
            </div>
          )}
          {location.pathname.includes("/home") && (
            <div className={classes.order_mode_switch_div}>
              {this.props.currentMode === orderMode.dineIn && (
                <div
                  className={
                    this.props.currentMode === orderMode.dineIn
                      ? classes.active_div
                      : classes.inactive_div
                  }
                >
                  <p
                    className={
                      this.props.currentMode === orderMode.dineIn
                        ? classes.active_text
                        : classes.inactive_text
                    }
                  >
                    Dine In
                  </p>
                </div>
              )}
              {this.props.currentMode === orderMode.takeAway && (
                <div
                  className={
                    this.props.currentMode === orderMode.takeAway
                      ? classes.active_div
                      : classes.inactive_div
                  }
                >
                  <p
                    className={
                      this.props.currentMode === orderMode.takeAway
                        ? classes.active_text
                        : classes.inactive_text
                    }
                  >
                    Take Away
                  </p>
                </div>
              )}
              {this.props.currentMode === orderMode.delivery && (
                <div
                  className={
                    this.props.currentMode === orderMode.delivery
                      ? classes.active_div
                      : classes.inactive_div
                  }
                >
                  <p
                    className={
                      this.props.currentMode === orderMode.delivery
                        ? classes.active_text
                        : classes.inactive_text
                    }
                  >
                    Delivery
                  </p>
                </div>
              )}
            </div>
          )}
          {location.pathname.includes("/home") && (
            <p style={{ color: "whitesmoke" }}>
              * Images are for illustration purpose only. Actual product may
              vary.
            </p>
          )}
          <div
            onClick={this._onRestartClick}
            className={classes.restart_button}
          >
            <img
              src={restartButton}
              alt=""
              width={"120px"}
              style={{ objectFit: "contain" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

LiveCart.propTypes = {
  itemsIncart: PropTypes.number,
  checkout: PropTypes.any,
  showStores: PropTypes.bool,
  location: PropTypes.any,
  onShowCartInfoModal: PropTypes.func,
  onPayButtonClick: PropTypes.func,
  onRestartClick: PropTypes.func,
  updateCheckOut: PropTypes.func
};

export default LiveCart;

import React, { PureComponent } from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Typography from "@material-ui/core/Typography";
import "./CartDetailCard.css";
import { Button } from "@material-ui/core";

class CartDetailCard extends PureComponent {
  render() {
    const { Cart, onClose } = this.props;
    console.log("CART CART CART", Cart);
    return (
      <div className="CartDetailCardMainDiv">
        <div style={{ height: "80%", overflow: "auto" }}>
          {Cart.carts.edges.map(order => {
            return (
              <ExpansionPanel
                style={{
                  margin: 0,
                  backgroundColor: "transparent",
                  width: "100%"
                }}
                disabled
                key={order.node.id}
                expanded={true}
              >
                <ExpansionPanelSummary>
                  <Typography
                    style={{
                      fontSize: "24px",
                      fontWeight: "bold",
                      float: "left",
                      width: "60%"
                    }}
                  >
                    {order.node.pickupPoint.store.name}
                  </Typography>
                  {/* <Typography
                    style={{
                      fontSize: "20px",
                      float: "right",
                      width: "40%",
                      textAlign: "right"
                    }}
                  >
                    {`${Math.round(
                      order.node.pickupPoint.sla.preparationTime / 60
                    )} Min`}
                  </Typography> */}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  {order.node.cartItems.edges.map((item, index) => (
                    <div key={index} className="TokenCardOrderItem">
                      <div className="Qty">
                        <Typography
                          style={{
                            color: "#000000",

                            fontSize: "30px"
                          }}
                        >
                          {`${item.node.quantity} x`}
                        </Typography>
                      </div>
                      <div className="NameDetails">
                        <Typography
                          style={{
                            color: "#000000",
                            fontSize: "20px",
                            fontFamily: "Open Sans"
                          }}
                        >
                          {item.node.stock.sku.product.name}
                        </Typography>
                      </div>
                      <div className="PriceInfo">
                        <Typography
                          style={{
                            color: "#000000",
                            fontSize: "24px",
                            fontFamily: "Open Sans",
                            float: "right"
                          }}
                        >
                          {`₹ ${item.node.total}`}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            );
          })}
        </div>
        <div>
          <ExpansionPanel
            style={{
              margin: 0,
              backgroundColor: "transparent",
              alignSelf: "flex-end",
              width: "100%",
              overflow: "auto"
            }}
            expanded
            disabled
          >
            <ExpansionPanelSummary>
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: "bold",
                  float: "left",
                  width: "60%"
                }}
              >
                Taxes & Charges:
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              {Cart.carts.edges.map((order, index) => {
                const extras = JSON.parse(order.node.extra);
                return (
                  <div>
                    {extras.offer &&
                      extras.offer.map(offer => (
                        <div
                          key={index}
                          style={{
                            width: "100%",
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center"
                          }}
                        >
                          <Typography
                            style={{
                              color: "#000000",
                              fontSize: "20px",
                              fontFamily: "Open Sans"
                            }}
                          >
                            {`${offer.label} (${
                              order.node.pickupPoint.store.name
                            })`}
                          </Typography>
                          <Typography
                            style={{
                              color: "#000000",
                              fontSize: "20px",
                              fontFamily: "Open Sans"
                            }}
                          >
                            {`₹ ${offer.amount}`}
                          </Typography>
                        </div>
                      ))}
                    {extras.charge &&
                      extras.charge.map(charge =>
                        charge.type !== "TAXES" ? (
                          <div
                            key={index}
                            style={{
                              width: "100%",
                              flexDirection: "row",
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center"
                            }}
                          >
                            <Typography
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                                fontFamily: "Open Sans"
                              }}
                            >
                              {`${charge.label} (${
                                order.node.pickupPoint.store.name
                              })`}
                            </Typography>
                            <Typography
                              style={{
                                color: "#000000",
                                fontSize: "20px",
                                fontFamily: "Open Sans"
                              }}
                            >
                              {`₹ ${charge.amount}`}
                            </Typography>
                          </div>
                        ) : null
                      )}
                  </div>
                );
              })}
              <div
                style={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "20px",
                    fontFamily: "Open Sans"
                  }}
                >
                  {"SGST (2.5%)"}
                </Typography>
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "20px",
                    fontFamily: "Open Sans"
                  }}
                >
                  {`₹ ${this.getSGST(Cart)}`}
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "20px",
                    fontFamily: "Open Sans"
                  }}
                >
                  {"CGST (2.5%)"}
                </Typography>
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "20px",
                    fontFamily: "Open Sans"
                  }}
                >
                  {`₹ ${this.getCGST(Cart)}`}
                </Typography>
              </div>
              <div
                style={{
                  width: "100%",
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "24px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans"
                  }}
                >
                  {`Total`}
                </Typography>
                <Typography
                  style={{
                    color: "#000000",
                    fontSize: "24px",
                    fontWeight: "bold",
                    fontFamily: "Open Sans"
                  }}
                >
                  {`₹ ${Cart.total}`}
                </Typography>
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <div
            style={{
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Button
              style={{
                height: "60px",
                fontSize: 20,
                backgroundColor: "green",
                color: "#FFFFFF",
                width: "200px",
                borderRadius: "10px",
                fontWeight: "bold",
                fontFamily: "Open Sans"
              }}
              variant="contained"
              size="large"
              onClick={onClose}
            >
              Okay
            </Button>
          </div>
        </div>
      </div>
    );
  }
  getCGST = data => {
    let CGST = 0;
    console.log("getCGST", data);
    for (let i = 0; i < data.carts.edges.length; i++) {
      const extras = JSON.parse(data.carts.edges[i].node.extra);
      for (let j = 0; j < extras.charge.length; j++) {
        if (extras.charge[j].type === "TAXES") {
          if (extras.charge[j].label.includes("CGST")) {
            CGST = CGST + extras.charge[j].amount;
          }
        }
      }
    }
    return CGST.toFixed(2);
  };
  getSGST = data => {
    let SGST = 0;
    for (let i = 0; i < data.carts.edges.length; i++) {
      const extras = JSON.parse(data.carts.edges[i].node.extra);
      for (let j = 0; j < extras.charge.length; j++) {
        if (extras.charge[j].type === "TAXES") {
          if (extras.charge[j].label.includes("SGST")) {
            SGST = SGST + extras.charge[j].amount;
          }
        }
      }
    }

    return SGST.toFixed(2);
  };
}

export default CartDetailCard;

import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import "./CategoryBar.css";

const styles = theme => ({
  root: {
    width: 200,
    display: "flex",
    textAlign: "center",
    flexDirection: "column",
    alignItems: "center",
    padding: 8,
    backgroundColor: "transparent"
  },
  expandedDiv: {
    height: "60px",
    width: 200,
    padding: 4,
    border: "1px solid #F57023",
    borderRadius: "6px",
    backgroundColor: "#F5A623",
    boxShadow: "inset 0 1px 19px 0 rgba(0,0,0,0.5)",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
    transition: "height 0.5s"
  },
  collapsedDiv: {
    height: "40px",
    width: 200,
    padding: 4,
    border: "1px solid rgba(153,153,153,0.5)",
    opacity: 0.8,
    borderRadius: "6px",
    backgroundColor: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 16,
    transition: "height 0.5s"
  },
  catImageCollapsed: {
    height: 24,
    width: 24,
    objectFit: "contain",
    backgroundColor: "transparent",
    filter: "grayscale('100%')",
    marginRight: 4
  },
  catImageExpanded: {
    height: 48,
    width: 48,
    backgroundColor: "transparent",
    objectFit: "contain",
    marginRight: 4
  },
  button: {
    margin: theme.spacing.unit,
    width: "90%"
  },
  nested: {
    paddingLeft: theme.spacing.unit * 2,
    marginTop: 2,
    marginBottom: 2
  },
  nestedChild: {
    paddingLeft: theme.spacing.unit * 8
  },
  catName: {
    color: "#777777",
    fontFamily: "Open Sans",
    fontSize: "20px"
  },
  catNameExpanded: {
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontSize: "20px"
  }
});

class CategoryBar extends React.PureComponent {
  state = {
    open: true,
    selectedProduct: null,
    active: this.props.categories[0].id,
    currentCategory: null
  };

  handleClick = () => {
    this.setState(state => ({ open: !state.open }));
  };

  _getProducts = cat => {
    if (!cat.children) {
      this.props.getProducts(cat.id);
    }
  };

  componentDidMount() {
    this._onCategorySelect(this.props.categories[0]);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.categories !== this.props.categories) {
      console.log("Category Changed");
      this._onCategorySelect(this.props.categories[0]);
      this.setState({ active: this.props.categories[0].id });
    }
  }

  _onCategorySelect = (category, e = null) => {
    e !== null && e.stopPropagation();
    if (!category.children) {
      this.props.getProducts(category.id);
      this.setState({ currentCategory: category.id });
    } else {
      if (category.children.edges[0].children.totalCount > 0) {
        this.props.getProducts(category.children.edges[0].children.edges[0].id);
        this.setState({
          currentCategory: category.children.edges[0].children.edges[0].id
        });
      } else {
        this.props.getProducts(category.children.edges[0].id);
        this.setState({
          currentCategory: category.children.edges[0].id
        });
      }
    }
  };

  _handleExpansion = cat => {
    this.setState({ active: cat.id });
    this._onCategorySelect(cat);
  };

  render() {
    const { classes, categories } = this.props;

    return (
      <div component="nav" className={classes.root}>
        {categories.map(cat => {
          console.log("category", cat);
          let image =
            cat.image === null
              ? ""
              : cat.image.replace("upload", "upload/e_grayscale");
          console.log("cat_image", image);
          return (
            <Card
              className={
                this.state.active === cat.id
                  ? classes.expandedDiv
                  : classes.collapsedDiv
              }
              key={cat.id}
              onClick={() => this._handleExpansion(cat)}
            >
              { /* commented cat images for future - Santosh*/
              /*<img
                src={image}
                alt={""}
                className={
                  this.state.active === cat.id
                    ? "catImageExpanded"
                    : "catImageCollapsed"
                }
              />*/}
              <Typography
                style={{ fontSize: 16, marginLeft: 6 }}
                className={
                  this.state.active === cat.id
                    ? classes.catNameExpanded
                    : classes.catName
                }
              >
                {cat.name}
              </Typography>
              {this.state.active === cat.id && (
                <div className={classes.nested}></div>
              )}
            </Card>
          );
        })}
      </div>
    );
  }
}

CategoryBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CategoryBar);

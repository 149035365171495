import React, { Component } from "react";
import "./HomeScreen.css";
import { getPopularFood } from "../../utils/helpers";
import Lottie from "react-lottie";
import Typography from "@material-ui/core/Typography";
import TrendingProductCard from "../../components/TrendingProductCard/TrendingProductCard";
import LoadingFood from "../../animationData/rotate.json";
import ErrorScreen from "../../components/ErrorHandling/Error";
import Masonry from "react-masonry-css";

class HomeScreen extends Component {
  constructor() {
    super();
    this.state = {
      popularFoodData: [],
      loadingTrendingData: false,
      hasError: false,
      showOrderPickModal: false
    };
  }

  componentDidMount() {
    this.setState({ loadingTrendingData: true });
    getPopularFood(this.props.orderMode)
      .then(res => {
        // alert(`order mode is ${this.props.orderMode}`);
        let data = res.data.stocks.edges;
        let first = [];
        let second = [];
        let third = [];
        let fourth = [];
        data.filter(item => {
          return item.node.store.isOpen;
        });
        data.forEach(element => {
          if (element.node.categoryDisplayOrder === 1) {
            first.push(element);
          } else if (element.node.categoryDisplayOrder === 2) {
            second.push(element);
          } else if (element.node.categoryDisplayOrder === 3) {
            third.push(element);
          } else {
            fourth.push(element);
          }
        });
        first.sort((a, b) => {
          return a.node.store.name.localeCompare(b.node.store.name);
        });
        second.sort((a, b) => {
          return a.node.store.name.localeCompare(b.node.store.name);
        });
        third.sort((a, b) => {
          return a.node.store.name.localeCompare(b.node.store.name);
        });
        fourth.sort((a, b) => {
          return a.node.store.name.localeCompare(b.node.store.name);
        });
        data = first.concat(second.concat(third.concat(fourth)));
        console.log("Data for the trending products", data);
        this.setState({
          popularFoodData: data,
          loadingTrendingData: false
        });
      })
      .catch(err => {
        console.log("Popular Food -- Error", err);
        this.setState({ loadingTrendingData: false, hasError: true });
      });
  }

  componentDidCatch(err, info) {
    if (err) {
      this.setState({ hasError: true });
    }
  }

  // Navigates to the home screen
  _takeMeHome = () => {
    this.props.history.replace("/");
    window.location.reload();
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingFood,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const breakpointColumnsObj = {
      default: 3,
      1080: 4,
      700: 2,
      500: 1
    };
    const { updateCheckOut } = this.props;
    if (this.state.hasError) {
      return <ErrorScreen onHomeButtonClick={this._takeMeHome} />;
    }
    return (
      <div className="HomeScreenMainDiv">
        <div>
          {!this.state.loadingTrendingData &&
            this.state.popularFoodData.length > 0 && (
              <Masonry
                breakpointCols={breakpointColumnsObj}
                className="my-masonry-grid"
                columnClassName="my-masonry-grid_column"
              >
                {this.state.popularFoodData &&
                  this.state.popularFoodData.map(item => {
                    if (item.node.store.isOpen) {
                      return (
                        <div key={item.node.id}>
                          <TrendingProductCard
                            item={item.node}
                            updateCheckOut={updateCheckOut}
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
              </Masonry>
            )}
          {this.state.loadingTrendingData && (
            <div
              style={{
                marginTop: "20%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Lottie
                options={defaultOptions}
                height={400}
                width={400}
                isStopped={this.state.loadingStoreData}
              />
              <Typography
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "24px",
                  fontWeight: "400"
                }}
              >
                Loading...
              </Typography>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default HomeScreen;

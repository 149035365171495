import React, { PureComponent } from "react";
import "./OrderModeModal.css";
import GrabbnGoLogo from "../GrabbnGoLogo/GrabbnGoLogo";
import { userProfileKey, REGION_CONFIG_SETTING } from "../../utils/config";
import blrbgImage from "../../assets/ordermodeblr.png";
import bgimage from "../../assets/Dine-in-1-bg.png";
import pizzahutbgImage from "../../assets/pizzahutbg.png";
//import bgimage from "../../assets/plainbg.png";
import TextInput from "../TextInput/TextInput";
import CartManager from "../../singletons/CartManager";
const takeAwayImage = require("../../assets/takeaway.png");
const dineInImage = require("../../assets/dine.png");
const deliveryImage = require("../../assets/delivery.png");
// const blrbgImage = require("");
// const bgimage = require();

class OrderModeModal extends PureComponent {
  state = {
    delivery: false,
    takeaway: true,
    dinein: false,
    offerImage: null,
    deliveryCaption: "",
    regionConfig: {},
    showDeliveryLocationModal: false
  };
  componentDidMount() {
    const userProfileData = JSON.parse(localStorage.getItem(userProfileKey));
    console.log("User profile data", userProfileData);
    const deliveryLocation = `${userProfileData.userProfile.firstName} ${userProfileData.userProfile.lastName}`;
    const cartManager = new CartManager();
    cartManager.setCheckoutComment(deliveryLocation);
    const regionsData = userProfileData.userProfile.user.regions.edges[0].node;
    const regionConfig = JSON.parse(
      localStorage.getItem(REGION_CONFIG_SETTING)
    );
    // const user = JSON.parse(localStorage.getItem("gng-userProfile"));
    // console.log("User", user);
    // console.log("Region config", regionConfig);
    this.setState({
      delivery:
        regionConfig && regionConfig.delivery
          ? regionConfig.delivery
          : regionsData.delivery,
      takeaway:
        regionConfig && regionConfig.takeaway
          ? regionConfig.takeaway
          : regionsData.takeaway,
      dinein:
        regionConfig && regionConfig.dinein
          ? regionConfig.dinein
          : regionsData.dinein,
      offerImage:
        regionConfig && regionConfig.kioskOfferImage
          ? regionConfig.kioskOfferImage
          : null,
      deliveryCaption:
        regionConfig && regionConfig.deliveryCaption
          ? regionConfig.deliveryCaption
          : "",
      regionConfig: regionConfig
    });
  }
  onDineInClick = () => {
    this.props.onDineInClick();
  };
  onTakeAwayClick = () => {
    this.props.onTakeAwayClick();
  };
  onDeliveryClick = () => {
    const { regionConfig } = this.state;
    if (regionConfig.city === "Bengaluru") {
      this.setState({
        showDeliveryLocationModal: true
      });
    } else {
      this.props.onDeliveryClick();
    }
  };

  hideDeliveryModal = () => {
    this.setState({
      showDeliveryLocationModal: false
    });
  };
  showDeliveryModal = () => {
    this.setState({
      showDeliveryLocationModal: true
    });
  };

  onSubmit = text => {
    const cartManager = new CartManager();
    cartManager.setCheckoutComment(`Boarding Gate ${text}`);
    this.props.onDeliveryClick();
  };

  render() {
    const {
      dinein,
      takeaway,
      delivery,
      offerImage,
      deliveryCaption,
      regionConfig,
      showDeliveryLocationModal
    } = this.state;
    const { kioskShouldShowHeader } = this.props;
    return (
      <div
        style={{
          backgroundImage:
            regionConfig.city === "Bengaluru"
              ? `url(${blrbgImage})`
              : regionConfig.city === "PIZZAHUT"
              ? `url(${pizzahutbgImage})`  
              : `url(${bgimage})`
        }}
        className="OrderModeModalMianDiv"
      >

        {regionConfig.city !== "Bengaluru" && (
          <div className="OrderModeModalHeader">
            <GrabbnGoLogo showLogo={kioskShouldShowHeader} />
          </div>
        )}
        {kioskShouldShowHeader === false && (
           <div className="orderHereCaptiontext">
           ORDER HERE
         </div>
        )}        
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {dinein && (
            <img
              src={dineInImage}
              alt=""
              height={260}
              style={{ objectFit: "contain", flex: 1, display: "flex" }}
              onClick={this.onDineInClick}
            />
          )}
          {takeaway && (
            <img
              src={takeAwayImage}
              alt=""
              height={260}
              style={{ objectFit: "contain", flex: 1, display: "flex" }}
              onClick={this.onTakeAwayClick}
            />
          )}
          {delivery && (
            <img
              src={deliveryImage}
              alt=""
              height={260}
              style={{ objectFit: "contain", flex: 1, display: "flex" }}
              onClick={this.onDeliveryClick}
            />
          )}
        </div>
        {delivery && (
          <p className="deliveryCaptiontext">{`${deliveryCaption}`}</p>
        )}
        {offerImage && (
          <img src={offerImage} alt="offer" className="offerImageKiosk" />
        )}
        {showDeliveryLocationModal && (
          <TextInput
            open={showDeliveryLocationModal}
            onClose={this.hideDeliveryModal}
            onSubmit={this.onSubmit}
          />
        )}
      </div>
    );
  }
}

export default OrderModeModal;

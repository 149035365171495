import React, { Component } from "react";
import "./StoreProductsList.css";
import CategoryBar from "../CategoryBar/CategoryBar";

import Lottie from "react-lottie";
import LoadingFood from "../../animationData/rotate.json";
import ProductCard from "../ProductCard/ProductCard";
import { Typography } from "@material-ui/core";
import Masonry from "react-masonry-css";
import CartManager from "../../singletons/CartManager";
import client from "../../api/client";
import { GET_PRODUCT_SKU_FOR_CATEGORY } from "../../api/queries";

class StoreProductsList extends Component {
  constructor() {
    super();
    this.state = {
      categories: [],
      products: [],
      loadingProducts: false,
      categoriesList: [],
      loadingStoreData: false,
      activeCat: null,
      noProductFound: false
    };
  }
  componentDidMount() {
    this.setState(
      {
        loadingStoreData: true,
        categories: [],
        products: [],
        loadingProducts: true
      },
      () => this._getDataForStore()
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.location.data.store.id !== this.props.location.data.store.id ||
      this.state.activeCat !== nextState.activeCat ||
      nextState.loadingStoreData !== this.state.loadingStoreData
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.location.data.store.id !== this.props.location.data.store.id
    ) {
      this.setState(
        {
          loadingStoreData: true,
          categories: [],
          products: [],
          loadingProducts: true
        },
        () => this._getDataForStore()
      );
      this.forceUpdate();
    }
  }

  _getDataForStore = () => {
    console.log("Hello ----->", this.props.location.data.store.id);
    const cartManager = new CartManager();
    const userDetail = cartManager.getUserDetail();
    const mode = userDetail.mode;
    client
      .query({
        query: GET_PRODUCT_SKU_FOR_CATEGORY(mode),
        variables: { storeId: this.props.location.data.store.id },
        fetchPolicy: "network-only"
      })
      .then(res => {
        console.log("Response For the STORE ---->", res.data);
        this._handleProductData(res.data.productCategorySkuStock.edges);
      })
      .catch(err => {
        this.setState({
          loadingStoreData: false,
          loadingProducts: false,
          categories: []
        });
      });
  };

  // this function does a lot pf work just like me. And cool part is, it, too, doesn't know what it does
  _handleProductData = data => {
    let prodArr = {};
    let categories = {};
    console.log("Data for the store", data);
    data.forEach(element => {
      let ele = element.node;
      if (prodArr[ele.product] && prodArr[ele.product].stocks && ele.inStock) {
        let item = prodArr[ele.product];
        let stocks = item.stocks;
        stocks.push({
          id: ele.stock,
          salePrice: ele.stockSalePrice,
          inStock: ele.inStock,
          name: ele.skuName,
          image: ele.skuImage,
          quantity: ele.quantity
        });
        item.stocks = stocks;
        prodArr[ele.product] = item;
      } else if (ele.inStock) {
        let item = {
          name: ele.productName,
          image: ele.productImage,
          id: ele.product,
          stocks: [],
          store: ele.store,
          displayOrder: ele.productDisplayOrder,
          description: ele.productDescription,
          contains: ele.contains,
          category: ele.category,
          stockSla: ele.stockSla,
          categoryName: ele.categoryName,
          categoryDisplayOrder: ele.categoryDisplayOrder,
          isCustomizable: ele.isCustomizable
        };
        if (!categories[ele.category]) {
          categories[ele.category] = {
            name: ele.categoryName,
            id: ele.category,
            displayOrder: ele.categoryDisplayOrder,
            image: ele.categoryImage
            
          };
        }
        item.stocks.push({
          id: ele.stock,
          salePrice: ele.stockSalePrice,
          inStock: ele.inStock,
          name: ele.skuName,
          image: ele.skuImage,
          quantity: ele.quantity
        });
        prodArr[ele.product] = item;
      }
    });
    let prods = Object.values(prodArr);
    if (prods && prods.length > 0) {
      prods = prods.map(prod => {
        prod.stocks.sort((a, b) => a.salePrice - b.salePrice);
        return prod;
      });
      const activeCategory = sessionStorage.getItem(
        `${this.props.match.params.storeid}`
      );
      prods.sort((a, b) => a.displayOrder - b.displayOrder);
      console.log("Products sorted", prods);
      const categoriesList = Object.values(categories).sort(
        (a, b) => a.displayOrder - b.displayOrder
      );
      if (prods[0].store === this.props.location.data.store.id) {
        this.setState({
          products: prods,
          loadingProducts: false,
          loadingStoreData: false,
          categories: categoriesList,
          activeCat: activeCategory || categoriesList[0].id
        });
      }
    } else {
      this.setState({
        products: [],
        loadingProducts: false,
        loadingStoreData: false
      });
    }
  };

  _getProductsForTheCategory = categoryID => {
    //Filter data for the category
    this.setState({
      activeCat: categoryID
    });
  };
  render() {
    console.log("Store Product List");
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingFood,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };
    const breakpointColumnsObj = {
      default: 2,
      1080: 3,
      1000: 2,
      500: 1
    };
    const { updateCheckOut } = this.props;
    const {
      categories,
      products,
      activeCat,
      loadingProducts,
      loadingStoreData
    } = this.state;
    console.log("Categories------>", products);
    return (
      <div className="StoreProductListMainDiv">
        <div
          className="store_list_panel"
          style={{
            width: "20%",
            float: "left",
            height: "100%",
            overflowY: "auto",
            paddingTop: "15%",
            paddingLeft: 12
          }}
        >
          <img
            src={this.props.location.data.store.image}
            alt=""
            height="190px"
            width="200px"
            style={{ objectFit: "cover", marginBottom: 12 }}
          />
          {categories.length > 0 && (
            <CategoryBar
              categories={categories}
              store={this.props.location.data}
              getProducts={this._getProductsForTheCategory}
            />
          )}
        </div>
        <div
          className="store_list_content StoreProductList"
          style={{
            width: "80%",
            float: "right",
            height: "100%",
            padding: "16px",
            paddingLeft: "40px",
            paddingRight: "40px",
            boxSizing: "border-box",
            overflowY: "auto"
          }}
        >
          {loadingStoreData && (
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <Lottie
                options={defaultOptions}
                height={400}
                width={400}
                isStopped={loadingStoreData}
              />
              <Typography
                style={{
                  fontFamily: "Open Sans",
                  fontSize: "24px",
                  fontWeight: "400"
                }}
              >
                Fetching data...
              </Typography>
            </div>
          )}
          {this.state.noProductFound && (
            <div className="norows">
              <Typography
                style={{
                  color: "#000000",
                  fontSize: "30px",
                  fontFamily: "Open Sans",
                  fontWeight: "400"
                }}
              >
                {"Oops, No products found"}
              </Typography>
            </div>
          )}
          {!loadingProducts && this.state.products.length > 0 && (
            <Masonry
              breakpointCols={breakpointColumnsObj}
              className="my-masonry-grid"
              columnClassName="my-masonry-grid_column"
            >
              {this.state.products &&
                this.state.products
                  .filter(it => it.category === activeCat)
                  .map(item => (
                    <div key={item.id}>
                      <ProductCard
                        item={item}
                        updateCheckOut={updateCheckOut}
                        store={this.props.location.data}
                      />
                    </div>
                  ))}
            </Masonry>
          )}
        </div>
      </div>
    );
  }
}

export default StoreProductsList;
